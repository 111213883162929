 <template>
  <v-dialog persistent no-click-animation v-model="show" max-width="600px">
    <v-card>
      <v-card-title class="text-bold-24">{{ $t("exportMigrationData.title") }}</v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
            <v-row id='umt-actions'>
              <md-dialog-confirm
                :md-active.sync="statusMessage.dialog"
                :md-title="statusMessage.title"
                :md-content="statusMessage.dialogText"
                @md-confirm="onConfirmErrorMessage"/>
                
              <div v-if='showError' class='md-subheading error-message'>{{statusMessage.text}}</div>
     
              <div v-if='showStatus' class='md-layout md-subheading status-message'>
                <div class="md-layout-item">
                  <span id="statusMessage">{{statusMessage.text}} </span>
                  <span v-if="migrationProgress.totalTransactions && showProgress">{{migrationProgessText}}</span>
                </div>
                <span class="space-10">
                  <md-progress-spinner v-if="showProgress && !migrationProgress.totalTransactions" :md-diameter=25 :md-stroke="3" 
                    md-mode="indeterminate"></md-progress-spinner>
                  <md-progress-spinner v-if="showProgress && migrationProgress.totalTransactions" :md-diameter=25 :md-stroke="3" 
                    md-mode="determinate" :md-value="migrationProgress.currentTransaction * 100 / migrationProgress.totalTransactions"></md-progress-spinner>
                </span>
              </div>
            </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-container>
          <v-row>
            <v-col>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="download('UO', 'TransferNumber')">{{ $t("exportMigrationData.exportTransferredNumbers") }}</v-btn> 
              <v-spacer></v-spacer>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="download('UO', 'TransferredDevicesMAC')">{{ $t("exportMigrationData.exportTransferredDevicesMac") }}</v-btn> 
              <v-spacer></v-spacer>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="download('UO', 'TransferredDevicesIP')">{{ $t("exportMigrationData.exportTransferredDevicesIp") }}</v-btn> 
              <v-spacer></v-spacer>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="download('UO', 'TransferredDevicesUDIS')">{{ $t("exportMigrationData.exportTransferredDevicesUDIS") }}</v-btn> 
              <v-spacer></v-spacer>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-spacer></v-spacer>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-spacer></v-spacer>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="closeDialog">{{ $t("common.close") }}</v-btn>  
              <v-spacer></v-spacer>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import FileDownloader from '../common/fileDownloader';
  import store from '../store';
  import * as RestApiHandler from '../common/restApiHandler';
  import router from '../router';
  import { logger } from 'vue-logger';

  export default {
    name: 'ActionMenu',
    props: {
      value: Boolean,
      customerId: String
    },
    data: () => ({
      pbxType: '',
      statusMessage: {
        dialog: false,
        error: false,
        text: ''
      }
    }),
    computed: {
      showError() {
        return this.statusMessage && this.statusMessage.text && this.statusMessage.error && !this.statusMessage.dialog;
      },
      showStatus() {
        return this.statusMessage && this.statusMessage.text && !this.statusMessage.error;
      },
      showProgress() {
        return this.statusMessage && this.statusMessage.showProgress;
      },
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        }
      }
    },
    methods: {
      closeDialog() {
        this.dialogStatusText = '';
        this.showStatusMessage = false;
        this.showSuccess = false;
        this.statusMessage.text = '';
        this.fileUploadVisible = false;
        this.$emit('close-dialog');
        store.commit('setFileUploadFormStatus', 'INITIAL');
      },
      onConfirmErrorMessage() {
        if (this.statusMessage.unauthorized) {
          RestApiHandler.logout();
          RestApiHandler.clearRefreshToken();
          router.push('/logon');
        }
      },
      async download(pbxType, contentType) {
        try {
          logger.debug(`[ActionMenu] download for ${pbxType}, customer ${this.customerId}`)
          this.statusMessage = {text: `Downloading ${pbxType} ${contentType ? contentType : 'spreadsheet'}`, showProgress: true};
          const fileDownloader = new FileDownloader();
          const result = await fileDownloader.download(pbxType, this.customerId, contentType);
          if (result.status === 200) {
            this.statusMessage = {text: this.$t('common.downloadFinished')}
          } else {
            this.statusMessage = {text: result.statusText, error: true};
          }
        } catch(err) {
          logger.error(`[ActionMenu] download for ${pbxType}, customer ${this.customerId} error:`, err);
        }
      },  
    }
  }
</script>

<style scoped>
  #umt-actions > .md-subheading {
    text-align: right;
    margin-left: 1.5rem;
    margin-right: 1.5rem;

  }
  #umt-actions > .error-message {
    color:red;
  }
  #umt-actions > .status-message {
    color: #257CAE;
  }
  .space-10 {
    margin-left: 10px !important;
  }

</style>
