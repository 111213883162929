 <template>
 <v-dialog persistent no-click-animation v-model="show" max-width="400px">
    <v-card>
      <v-card-title class="text-bold-24">{{ dialogTitle }}</v-card-title>
      <div v-if='!errorMessage'>
        <v-card-text>
        <v-container>
        <div class='md-body-2'>{{ $t('configureMigrate.confirmMigrateText')}}</div>
        <v-checkbox class='md-body-2'
            v-model="sendMail"
            :label="$t('configureMigrate.sendWelcomeEmail')"
        ></v-checkbox>
        </v-container>
      </v-card-text>
       <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn id="confirmMigrateButton" text color="primary" @click="confirmDialog(sendMail)">{{ $t('common.yes')}}</v-btn>
        <v-spacer></v-spacer>
        <v-btn id="closeMigrateButton" text color="primary" @click="closeDialog">{{ $t('common.no')}}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
      </div>
      <div v-if='errorMessage'>
        <v-card-text>
        <v-container>
        <div class='md-body-2'>{{ errorMessage }}</div>
        </v-container>
      </v-card-text>
       <v-card-actions>
        <v-btn text color="primary" @click="closeDialog">OK</v-btn>  
       </v-card-actions>
      </div>
    </v-card>
  </v-dialog>

</template>
<script>
  import store from '../store';

  export default {
    name: 'MigrateDialog',
    props: ['value', 'errorMessage','dialogTitle'],
    data () {
      return {
        sendMail: false,
      }
    },
    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('migrate', value)
        }
      },
    },
    methods: {
      closeDialog() {
        this.$emit('close-dialog');
      },
      confirmDialog(sendMail) {
        store.commit('setSendWelcomeEmail', sendMail);
        this.$emit('confirm-dialog');
      }
    }
  }
</script>
