<template>
  <v-col :style="cssVariables">
    <v-row>
      <v-data-table
        item-key="uuid"
        v-model="migrationItems"
        show-select
        mobile-breakpoint=0
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalElements"
        :loading="loading"
        :no-data-text = "$t('common.noDataAvailable')"
        :footer-props="{
            disablePagination: loading,
            itemsPerPageOptions: [10, 20, 50, 100],
            showFirstLastPage: true,
            firstIcon: 'first_page',
            prevIcon: 'chevron_left',
            nextIcon: 'chevron_right',
            lastIcon: 'last_page',
            itemsPerPageText: $t('common.rowsPerPage')
        }"
        class="full-width data-table">
        <template v-slot:[`header.data-table-select`]>
            <v-simple-checkbox
              color="primary" :ripple="false"
              :value="selectedAll"
              @input="selectAll($event)"
            ></v-simple-checkbox>
        </template>
        <template v-slot:[`header.featureName`]="{ header }">
          <v-combobox
              v-model="featureName"
              :items="!featureName ? filterTypes : undefined"
              item-text="filterLabel"
              item-value="filterValue"
              :label="$t(header.text)"
              hide-no-data hide-details hide-selected
              append-icon="search"
              clear-icon="close"
              solo single-line flat clearable 
              @change="$event => featureName = $event"
              @click:clear="$event => featureName = ''"
              :placeholder="$t(header.text)"
              :disabled="true"
          ></v-combobox>
        </template>
        <template v-slot:[`header.groupName`]="{ header }">
          <v-combobox
              v-model="groupName"
              :items="!groupName ? filterTypes : undefined"
              item-text="filterLabel"
              item-value="filterValue"
              :label="$t(header.text)"
              hide-no-data hide-details hide-selected
              append-icon="search"
              clear-icon="close"
              solo single-line flat clearable 
              @change="$event => groupName = $event"
              @click:clear="$event => groupName = ''"
              :placeholder="$t(header.text)"
          ></v-combobox>
        </template>
        <template v-slot:[`header.user`]="{ header }">
          <v-combobox
              v-model="user"
              :items="!user ? filterTypes : undefined"
              item-text="filterLabel"
              item-value="filterValue"
              :label="$t(header.text)"
              hide-no-data hide-details hide-selected
              append-icon="search"
              clear-icon="close"
              solo single-line flat clearable 
              @change="$event => user = $event"
              @click:clear="$event => user = ''"
              :placeholder="$t(header.text)"
          ></v-combobox>
        </template>
        <template v-slot:[`header.pilotText`]="{ header }">
          <v-combobox
              v-model="pilot"
              :items="!pilot ? filterTypes : undefined"
              item-text="filterLabel"
              item-value="filterValue"
              :label="$t(header.text)"
              hide-no-data hide-details hide-selected
              append-icon="search"
              clear-icon="close"
              solo single-line flat clearable 
              @change="$event => pilot = $event"
              @click:clear="$event => pilot = ''"
              :placeholder="$t(header.text)"
          ></v-combobox>
        </template>
        <template v-slot:[`header.huntGroupManagerEmail`]="{ header }">
          <span>{{$t(header.text)}}</span>
        </template>
        <template v-slot:[`header.huntGroupExtension`]="{ header }">
          {{$t(header.text)}}
        </template>
        <template v-slot:[`header.membersTotal`]="{ header }">
            <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                {{$t(header.text)}}
                <v-icon>info</v-icon>
              </span>
            </template>
            <span>{{ $t("stepper.callQueueMembersTooltip") }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.data-table-select`]="{ item, select }">
            <v-simple-checkbox
            color="primary"
            :value="item.isSelected"
             @input="$event => {select($event); editRow=''; validateRow($event, item);}"
            :ripple="false"
            :disabled="selectedAll"
            ></v-simple-checkbox>
        </template>
        <template v-slot:[`item.edit`]="{ item }">
            <v-btn
              icon small
              :color="getColor(item)"
              v-bind:class="item.isSelected ? 'visible' : 'invisible'"
              @click="toggleEdit(item)"
            >
              <v-icon>edit</v-icon>
            </v-btn>
        </template>
        <template v-slot:[`item.groupName`]="{ item }" >
            <span v-if="isInEditMode(item) && validateNow('groupName')">
              <v-tooltip :disabled="!getFieldErrorText(item, 'GROUP_NAME')" top>
                <template v-slot:activator="{ on }">
                  <v-form ref="groupName">
                    <v-text-field
                      :value="item.groupName"
                      :label="item.groupName"
                      @change="$event => {item.groupName = $event; editCell(item, item.uuid, 'GROUP_NAME');}"
                      :rules="getValidatorRules(item.uuid, 'GROUP_NAME')"
                      hide-details
                      outlined solo flat dense
                      v-on="on"
                    ></v-text-field>
                  </v-form>
                </template>
                <span>{{getFieldErrorText(item, 'GROUP_NAME')}}</span>               
              </v-tooltip>
            </span>
            <span v-else>{{item.groupName}}</span>
        </template>
        <template v-slot:[`item.huntGroupManagerEmail`]="{ item }" >
            <span v-if="isInEditMode(item) && validateNow('managerEmail')">
              <v-tooltip :disabled="!getFieldErrorText(item, 'MANAGER')" top>
                <template v-slot:activator="{ on }">
                  <v-form ref="managerEmail">
                    <v-text-field
                      :value="item.huntGroupManagerEmail"
                      :label="item.huntGroupManagerEmail"
                      @change="$event => {item.huntGroupManagerEmail = $event; editCell(item, item.uuid, 'MANAGER');}"
                      :rules="getValidatorRules(item.uuid, 'MANAGER')"
                      hide-details
                      outlined solo flat dense
                      v-on="on"
                    ></v-text-field>
                  </v-form>
                </template>
                <span>{{getFieldErrorText(item, 'MANAGER')}}</span>
              </v-tooltip>
            </span>
            <span v-else>{{item.huntGroupManagerEmail}}</span>
        </template>
        <template v-slot:[`item.huntGroupExtension`]="{ item }" >
            <span v-if="isInEditMode(item) && hasNotBeenMigrated(item)">
              <v-tooltip :disabled="!getFieldErrorText(item, 'EXTENSION')" top>
                <template v-slot:activator="{ on }">
                  <v-text-field
                      :value="item.huntGroupExtension"
                      :label="item.huntGroupExtension"
                      @change="$event => {item.huntGroupExtension = $event; editCell(item, item.uuid, 'EXTENSION');}"
                      :rules="getValidatorRules(item.uuid, 'EXTENSION')"
                      hide-details
                      outlined solo flat dense
                      v-on="on"
                  ></v-text-field>
                </template>
                <span>{{getFieldErrorText(item, 'EXTENSION')}}</span>
              </v-tooltip>
            </span>
            <span v-else>{{item.huntGroupExtension}}</span>
        </template>
      </v-data-table>
    </v-row>
  </v-col>
</template>
<script>
  import { mapState, mapGetters } from 'vuex';
  import store from '../store';
  import Utils from '../common/utils';

  export default {
    props: ['customerId'],
    name: 'ConfigurationFeatures',
     data() {
      return {
        selected: [],
        loading: false,
        editRow: '',
        /**
         * The width of the headers has been calculated based on their label and potential content
         */
        headers:[
          { value: 'edit', align: 'start', width: '1', sortable: false, cellClass: "data-table-edit"},
          { text: 'stepper.featureName', value: 'featureName', align: 'start', width: "126", sortable: false,
            class: 'data-table-header required-header configuration-features-headers', cellClass: 'data-table-cell required-input'
          },
          { text: 'stepper.callQueueName', value: 'groupName', align: 'start', width: "126", sortable: false,
            class: 'data-table-header required-header configuration-features-headers', cellClass: 'data-table-cell required-input'
          },
          { text: 'stepper.hgNamePilotNr', value: 'pilotText', align: 'start', width: "126", sortable: false,
            class: 'data-table-header configuration-features-headers', cellClass: 'data-table-cell'
          },
          { text: 'stepper.callQueueMgrEmail', value: 'huntGroupManagerEmail', align: 'start', width: "126", sortable: false,
            class: 'data-table-header required-header configuration-features-headers', cellClass: 'data-table-cell required-input'
          },
          { text: 'stepper.extension', value: 'huntGroupExtension', align: 'start', width: "126", sortable: false,
            class: 'data-table-header configuration-features-headers', cellClass: 'data-table-cell'
          },
          { text: 'stepper.callQueueMembers', value: 'membersTotal', align: 'start', width: "126", sortable: false,
            class: 'data-table-header configuration-features-headers', cellClass: 'data-table-cell'
          }
        ],
        validators: {
          'FEATURE_NAME': [],
          'GROUP_NAME': [this.isRequired, this.isXSSsanitized],
          'PILOT': [],
          'MANAGER': [this.isRequired, this.isEmail],
          'EXTENSION': [this.isDigitsOnlyOrEmpty]
        }, 
        filterTypes: Utils.filterTypes,
        unwatch: null
      }
    },
    mounted() {
      this.unwatch = store.watch(state => state.currentStep, value => {
        if (value === 4) {
          this.fetchData(this.options, this.search, true);
          this.$emit('form-changed');
        }
      });
    },
    beforeDestroy() {
      this.unwatch();
    },
    computed: {
      ...mapState(['customer', 'migrationFeatures', 'selectAllFeatures', 'selectedMigrationSites', 'selectedMigrationFeatures',
      'stepperConsistencyReport', 'selectedMigrationUsers', 'migrationFeatureErrors']),
      ...mapGetters(['getCombinedFeatures', 'getStepperConsistencyErrorText']),
      totalElements() {
        return this.migrationFeatures && this.migrationFeatures.paging && this.migrationFeatures.paging.totalElements || 0;
      },
      cssVariables () {
        // We do not count the edit column
        return Utils.calculateHeaderWidth(this.headers.length - 1);
      },
      items() {
        return this.getCombinedFeatures();
      },
      migrationItems: {
        get: function () {
          return this.selectedMigrationFeatures;
        },
        set: function (value) {
          store.commit('setSelectedMigrationFeatures', value);
        }
      },
      selectedAll: {
        get: function () {
          return this.selectAllFeatures;
        },
        set: function (value) {
          store.commit('setSelectAllFeatures', value);
        }
      },
      featureName: {
        get: function () {
          return this.migrationFeatures && this.migrationFeatures.search && this.migrationFeatures.search.featureName || '';
        },
        set: function (value) {
          this.search.featureName = value;
          this.pageReset();
        }
      },
      groupName: {
        get: function () {
          return this.migrationFeatures && this.migrationFeatures.search && this.migrationFeatures.search.groupName || '';
        },
        set: function (value) {
          this.search.groupName = value;
          this.pageReset();
        }
      },
      pilot: {
        get: function () {
          return this.migrationFeatures && this.migrationFeatures.search && this.migrationFeatures.search.pilot || '';
        },
        set: function (value) {
          this.search.pilot = value;
          this.pageReset();
        }
      },
      search() {
        return {
            featureName: this.featureName,
            groupName: this.groupName,
            pilot: this.pilot || this.huntGroupNumber,
            huntGroupNumber: this.huntGroupNumber,
            extension: this.huntGroupExtension,
        }
      },
      options: {
        get: function () {
          return this.migrationFeatures.paging;
        },
        set: function (value) {
          this.fetchData(value, this.search);
        }
      },
    },  
    methods: {
      getColor(item) {
        let color = 'primary';
        const validationError = this.migrationFeatureErrors[item.uuid]
                                && Object.keys(this.migrationFeatureErrors[item.uuid]).find(key => !!this.migrationFeatureErrors[item.uuid][key]);
        if (validationError) {
          color = 'error';
        }
        return color;
      },
      toggleEdit(item) {
        if (item.isSelected && item.uuid !== this.editRow){
          this.editRow = item.uuid;
        } else {
          this.editRow = '';
        }
      },
      isInEditMode(item) {
        return item.isSelected && item.uuid === this.editRow;
      },
      async validateNow(elem) {
        await this.$nextTick();
        this.$refs[elem] && this.$refs[elem].validate();
      },
      hasNotBeenMigrated(item) {
        return !item.migratedLink;
      },
      selectAll(value) {
        if (value) {
          //In 'Select All' option only edited features are added 
          store.commit('setEditedFeaturesForMigration');
          //Validate all the items in the current page
          this.validateRows(this.items);
        } else {
          // We deselect all users, reset error objects
          this.editRow = '';
          this.migrationItems = [];
          store.commit('resetAllMigrationFeatureErrors');
          this.resetStepConsistencyErrors();
        }
        this.selectedAll = value;
      },
      editCell(item, uuid, field) {
        store.commit('setEditedFeature', item);
        const uuids = [uuid];
        this.resetItemConsistencyError(uuids, field);
        this.validateRow(true, item);
      },
      async resetStepConsistencyErrors() {
        if (this.stepperConsistencyReport) {
          const somethingChanged = await store.dispatch('resetStepStepperConsistencyError', 4);
          if (somethingChanged) {
            this.$emit('form-changed');
          }
        }
      },
      async resetItemConsistencyError(uuids, field) {
        if (this.stepperConsistencyReport) {
          const somethingChanged = await store.dispatch('resetItemStepperConsistencyError', {uuids, field});
          if (somethingChanged) {
            this.validateStep();
            this.$emit('form-changed');
          }
        }
      },
      pageReset() {
        //If a new filter is applied we reset page number, the select all option and the selected items
        this.options.page = 1;
        this.selectedAll = false;
        this.migrationItems = [];
        this.fetchData(this.options, this.search);
      }, 
      detectQueryChange(options, search) {
         const { page, itemsPerPage } = options
         const { featureName, groupName, user, pilot } = search
         const noChange = page && itemsPerPage && this.selected && this.migrationFeatures.paging && this.migrationFeatures.search
                          && this.migrationFeatures.paging.page === page && this.migrationFeatures.paging.itemsPerPage === itemsPerPage
                          && this.migrationFeatures.search.featureName === featureName 
                          && this.migrationFeatures.search.groupName === groupName
                          && this.migrationFeatures.search.user === user
                          && this.migrationFeatures.search.pilot === pilot
         return !noChange
      },
      getQuery(options, search) {
         const { page, itemsPerPage } = options
         const pagingParameters = { page, itemsPerPage }
         return Utils.encodeBase64Url(JSON.stringify({ options: pagingParameters, search }));
      },
      fetchData: async function(options, search, initialize) {
        if (this.loading) { return; }
        try {
          this.loading = true;
          const migrationSite = this.selectedMigrationSites && this.selectedMigrationSites.length && this.selectedMigrationSites[0];
          if (initialize || (this.detectQueryChange(options,search) && migrationSite)) {
            // We only bring Hunt groups in the UI now. If that changes make sure to remove the "disabled" prop for the v-combobox
            const featureName = 'Hunt Group'
            this.search.featureName = featureName;
            this.search.site = migrationSite && migrationSite.uuid;
            await store.dispatch('getMigrationFeatures', {customerId: this.customer.uuid, query: this.getQuery(options,search)});
          }
        } finally {
          this.loading = false;
          if (this.selectedAll) {
            this.validateRows(this.items);
          } 
        }
      },
      validateStep() {
        if (this.selectedAll) {
          this.validateRows(this.items);
        } else {
          this.validateRows(this.migrationItems);
        }
      },
      validateRow(value, item) {
        if (value) {
          const errorObj = this.createErrorObj(item)
          if(!this.migrationFeatureErrors[item.uuid] || !Utils.isEqualErrorObj(this.migrationFeatureErrors[item.uuid], errorObj)) {
            store.commit('setMigrationFeatureErrors', { featureUuid: item.uuid, errorObj });
          }
        } else {
           this.resetItemConsistencyError([item.uuid]);
           store.commit('resetMigrationFeatureErrors', item.uuid);
        }
      },
      validateRows(items) {
        const errorObjs = {};
        items.forEach(item => {
          errorObjs[item.uuid] = this.createErrorObj(item);
        })
        let errorChanges = Object.keys(errorObjs).find(uuid => {
          return !this.migrationFeatureErrors[uuid] || !Utils.isEqualErrorObj(this.migrationFeatureErrors[uuid], errorObjs[uuid]);
        });
        if (errorChanges) {
          store.commit('setAllMigrationFeatureErrors', errorObjs);
        }
      },
      createErrorObj(item) {
        const errorObj = {};
        Object.keys(this.validators).forEach(field => {
            const errorMsg = this.getValidatorRules(item.uuid, field).map(validator => validator(this.getValueToValidate(item, field)))
                            .find(errorText => errorText !== true) || '';
            errorObj[field] = errorMsg;
        })
        return errorObj;
      },
      getValueToValidate(item, field) {
        switch(field) {
          case 'FEATURE_NAME':
            return item.featureName;
          case 'GROUP_NAME':
            return item.groupName;
          case 'PILOT':
            return item.pilot;
          case 'MANAGER':
            return item.huntGroupManagerEmail;
          case 'EXTENSION':
            return item.huntGroupExtension;
          default:
            return '';
        }
      },
      getFieldErrorText(item, field) {
        return (this.migrationFeatureErrors[item.uuid] && this.migrationFeatureErrors[item.uuid][field]) || this.getStepperConsistencyErrorText(item.uuid, field);
      },
      getValidatorRules(uuid, field) {
        const validators = this.validators;
        return validators[field].concat([this.getConsistencyErrorText(uuid, field)]);
      },
      getConsistencyErrorText(uuid, fieldName) {
        let func = this.getStepperConsistencyErrorText;
        return function() {
          const errorTxt = func(uuid, fieldName);
          if (errorTxt) {
            return errorTxt;
          } else {
            return true;
          }
        };
      },
      isDigitsOnlyOrEmpty: field => (/^\d+$/.test(field) || field === "" || field === '' || field === undefined) || 'Only digits are allowed',
      isEmail: field => 
            /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])$/.test(field) || 
            'Not valid e-mail',
      isXSSsanitized: field => /^((?!<.*?>).)*$/.test(field) || 'Tags \'<\' and \'>\' are not allowed',
      isRequired: field => (!!field && field.trim() != "") || "Field is required",
    }
  }
</script>
<style>
  .configuration-features-headers {
      width: var(--width) !important;
  }
</style>
