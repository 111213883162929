<template>
  <v-container fluid class="pbx-container">
    <v-row no-gutter class="pbx-header" justify="space-between">
      <v-col sm="auto" class="text-bold-24 pa-0">{{ $t("sources.sources") }}</v-col>
      <v-col sm="auto" class="pa-0">
        <v-btn class="button-text-16 no-text-transform"
          color="primary" width="150"
          dark rounded outlined    
          @click="importPbx()"
        >
          <v-icon left>add</v-icon>
          {{ $t("sources.import") }}
        </v-btn>
        <v-btn class="button-text-16 no-text-transform left-margin"
          color="primary" width="150"
          rounded outlined    
          @click="confirmDeleteCustomer()"
          :disabled='noPbxImported'
        >
          <v-icon left>delete</v-icon>
          {{ $t("sources.deleteAll") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutter :style="cssVariables">
      <v-data-table
        :headers="headers"
        :items="pbxItems"
        :options.sync="options"
        :server-items-length="totalElements"
        :loading="loading"
        :no-data-text = "$t('common.noDataAvailable')"
        :footer-props="{
            disablePagination: loading,
            itemsPerPageOptions: [5, 10, 20],
            showFirstLastPage: true,
            firstIcon: 'first_page',
            prevIcon: 'chevron_left',
            nextIcon: 'chevron_right',
            lastIcon: 'last_page',
            itemsPerPageText: $t('common.rowsPerPage')
        }"
        class="data-table full-width"
      >
      <template v-slot:[`header.description`]="{ header }">
          {{$t(header.text)}}
      </template>
      <template v-slot:[`header.name`]="{ header }">
        <v-text-field
          :value="name"
          @change="$event => name = $event"
          @click:clear="$event => name = ''"
          :label="$t(header.text)"
          append-icon="search"
          clear-icon="close"
          single-line hide-details
          flat solo clearable
          :placeholder="$t(header.text)"
        ></v-text-field>
      </template>
      <template v-slot:[`header.created`]="{ header }">
          {{$t(header.text)}}
      </template>
      <template v-slot:[`header.synced`]="{ header }">
          {{$t(header.text)}}
      </template>
      <template v-slot:[`header.updated`]="{ header }">
          {{$t(header.text)}}
      </template>
      <template v-slot:[`header.actions`]="{ header }">
          {{$t(header.text)}}
      </template>
      <template v-slot:top>
        <delete-dialog class="lvl2-elevation"
          :dialogTitle="deleteDialogTitle"
          :dialogTarget="deleteDialogTarget"
          :showProgress="showDeleteProgress"
          v-model="showDeleteDialog"
          :errorMessage="dialogErrorMessage"
          @close-dialog="closeDelete"
          @confirm-dialog="deleteItem"/>

        <import-pbx-dialog class="lvl2-elevation"
          :customerId="customer.uuid"
          v-model="showImportPbxDialog"
          @close-dialog="showImportPbx=false"
          @import-pbx="showImportPbx=false"/>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        small
        @click="confirmDeleteItem(item)"
      >
        fas fa-trash-alt
      </v-icon>
      <v-icon
        v-if="showDownloadItem(item)"
        small
        class="ml-3"
        @click="downloadItem(item)"
        
      >
        fas fa-download
      </v-icon>
    </template>
      </v-data-table>
    </v-row>
  </v-container>
</template>

<script>
  import { mapState } from 'vuex';
  import store from '../store';
  import * as RestApiHandler from '../common/restApiHandler';
  import DeleteDialog from "./deleteDialog";
  import ImportPbxDialog from "./importPbxDialog";
  import FileDownloader from '../common/fileDownloader';
  import Utils from '../common/utils';


  export default {
    name: 'CustomerPBXes',
    components: {
      DeleteDialog,
      ImportPbxDialog
    },

    data() {
      return {
        loading: false,
        /**
         * The width of the headers has been calculated based on their label and potential content
         */
        headers:[
          {
            text: 'sources.sourceType', align: 'start', sortable: false, value: 'description', width:"70",
            class: "data-table-header customer-pbxes-headers", cellClass: "data-table-cell"
          },
          {
            text: 'common.name', align: 'start', sortable: false, value: 'name', width:"110",
            class: "data-table-header customer-pbxes-headers", cellClass: "data-table-cell"
          },
          {
            text: 'sources.created', align: 'start', sortable: false, value: 'created', width:"100",
            class: "data-table-header customer-pbxes-headers", cellClass: "data-table-cell"
          },
          {
            text: 'sources.synchronized', align: 'start', sortable: false, value: 'synced', width:"100",
            class: "data-table-header customer-pbxes-headers", cellClass: "data-table-cell"
          },
          {
            text: 'sources.updated', align: 'start', sortable: false, value: 'updated', width:"100",
            class: "data-table-header customer-pbxes-headers", cellClass: "data-table-cell"
          },
          {
            text: 'sources.actions', align: 'start', sortable: false, value: 'actions', width:"75",
            class: "data-table-header customer-pbxes-headers", cellClass: "data-table-cell"
          }
        ],
        deleteDialogTitle: '',
        deleteDialogTarget: '',
        dialogErrorMessage: '',
        itemToDelete: {},
        showDeleteDialog: false,
        showDeleteProgress: false,
        showImportPbx: false
      }
    },
    computed: {
      ...mapState(['customer', 'pbxes', 'unauthorized', 'loggedUser']),
      cssVariables () {
        return Utils.calculateHeaderWidth(this.headers.length);
      },
      totalElements() {
        return this.pbxes && this.pbxes.paging && this.pbxes.paging.totalElements || 0;
      },
      pbxItems() {
        const { page , itemsPerPage } = this.options;
        // This only happens when a new pbx is imported/deleted or at logon. Records.length will be at max as dictated
        // by the server and the page will be 1.
        if (this.pbxes && this.pbxes.records && this.pbxes.records.length > itemsPerPage && page === 1) {
            store.commit('reduceRecords', { type: 'pbxes', number: itemsPerPage })
        }
        return this.pbxes && this.pbxes.records && this.pbxes.records.map(pbx => {
          return {
            description: this.getPbxDescription(pbx),
            name: this.getPbxName(pbx),
            created: this.getPbxHistoryTime(pbx, 'CREATED', true),
            synced: this.getPbxHistoryTime(pbx, 'SYNCHRONIZED', true),
            updated: this.getPbxHistoryTime(pbx, 'UPDATED', true),
            hasRemovalObjects: pbx.hasRemovalObjects,
            hasMigratedTrunkUsers: pbx.hasMigratedTrunkUsers,
            uuid: pbx.uuid,
            type: pbx.type
          }
        });
      },
      name: {
        get: function () {
          return this.pbxes && this.pbxes.search && this.pbxes.search.name;
        },
        set: function (value) {
          this.search.name = value;
          this.pageReset();
        }
      },
      search() {
        return {
            name: this.name
        }
      },
      options: {
        get: function () {
          return this.pbxes.paging;
        },
        set: function (value) {
          this.fetchData(value, this.search);
        }
      },
      showImportPbxDialog: {
        get () {
          return !this.unauthorized && this.showImportPbx;
        }
      },
      noPbxImported() {
        return this.pbxes && this.pbxes.records && this.pbxes.records.length === 0;
      }
    },
    methods: {
      pageReset() {
        this.options.page = 1;
        this.fetchData(this.options, this.search);
      },
      detectQueryChange(options, search) {
        const { page, itemsPerPage } = options;
        const { name } = search;
        const noChange =  page && itemsPerPage && this.pbxes && this.pbxes.paging && this.pbxes.search
                        && this.pbxes.paging.page === page && this.pbxes.paging.itemsPerPage === itemsPerPage
                        && this.pbxes.search.name === name;
         return !noChange
      },
      getQuery(options, search) {
        const { page, itemsPerPage } = options
        const pagingParameters = { page, itemsPerPage }
        return Utils.encodeBase64Url(JSON.stringify({ options: pagingParameters, search }));
      },
      fetchData: async function(options, search) {
        if (this.loading) { return; }
        try {
          this.loading = true;
          if (this.detectQueryChange(options,search) && this.customer && this.customer.uuid) {
            await store.dispatch('getPbxes', {customerId: this.customer.uuid, query: this.getQuery(options, search)});
          }
        } finally {
          this.loading = false;
        }
      },

      confirmDeleteItem(item) {
        this.showDeleteDialog = true;
        this.deleteDialogTitle = "sources.deletePbxDialogTitle";
        this.deleteDialogTarget = this.getPbxDescription(item);
        this.dialogErrorMessage = '';
        this.showDeleteProgress = false;
        this.itemToDelete = item;
      },

      confirmDeleteCustomer() {
        this.showDeleteDialog = true;
        this.deleteDialogTitle = "sources.deleteAllDialogTitle";
        this.deleteDialogTarget = this.loggedUser && this.loggedUser.account && this.loggedUser.account.company || 'the customer';
        this.dialogErrorMessage = '';
        this.showDeleteProgress = false;
        this.itemToDelete = null;
      },

      closeDelete() {
        this.showDeleteDialog = false;
        this.itemToDelete = null;
        this.showDeleteProgress = false;
      },

      async deleteItem() {
        if (this.itemToDelete) {
          this.$log.debug('[CustomerPbxes] deletePbx: ', this.itemToDelete);
          this.showDeleteProgress = true;
          const result = await RestApiHandler.deletePbx(this.customer.uuid, this.itemToDelete.type, this.itemToDelete.uuid);
          await this.handleDeleteResult(result);
        } else {
          this.$log.debug('[CustomerPbxes] deleteAll');
          this.showDeleteProgress = true;
          const result = await RestApiHandler.deleteCustomer(this.customer.uuid);
          await this.handleDeleteResult(result);
        }
      },
      
      async downloadItem(pbx) {
        try {
          this.$log.debug(`[CustomerPbxes] Download for ${pbx.type}, customer ${this.customerId}`)
          this.statusMessage = {text: `Downloading ${pbx.type} file`};
          const fileDownloader = new FileDownloader();
          const result = await fileDownloader.download(pbx.type, this.customer.uuid, pbx.uuid);
          if (result.status === 200) {
            this.statusMessage = {text: this.$t('common.downloadFinished')}
          } else {
            this.statusMessage = {text: result.statusText, error: true};
          }
        } catch(err) {
          this.$log.error(`[CustomerPbxes] Download for ${pbx.type}, customer ${this.customerId} error:`, err);
        }
      },

      async handleDeleteResult(result) {
        if (result.status === 200) {
          const response = await result.json();
          if (response && response.customers) {
            await store.commit('setCustomers', response.customers);
            this.closeDelete()
          }
        } else {
          this.$log.error('[Customer] delete error: ', result);
          this.dialogErrorMessage = `Server error while ${this.deleteDialogTitle}. Error: ${result.statusText}`
        }
      },

      showDownloadItem(item) {
        return item.hasRemovalObjects || item.hasMigratedTrunkUsers;
      },

      getFromHistory(history, state) {
        const items = history && history.filter(historyEvent => historyEvent.state === state);
        // the last item is the most recent item
        return items && items.length && items[items.length-1];
      },

      getPbxDescription(pbx) {
        switch (pbx && pbx.type) {
          case 'OSV': return 'Openscape Voice'
          case 'OS4K': return 'Openscape 4000'
          case 'OSBiz': return 'Openscape Business'
          case 'UO':
            if (pbx.isUnifyVideo) {
              return 'Unify Video';
            } else {
              return 'Unify Office';
            }
          case 'DLS': return 'DLS';
          case 'Circuit': return 'Circuit';
          case 'OSUC': return 'Openscape UC';
          case 'CSV': return 'CSV';
          case 'NGTC': return 'Unify Phone';
          case 'MiVoice400': return 'MiVoice 400';
          default: return pbx && pbx.type;
        }
      },

      getPbxHistoryTime(pbx, state, suffixBy) {
        const updateEvent = pbx && this.getFromHistory(pbx.history, state);
        if (updateEvent) {
          const updateTime = new Date(updateEvent.timestamp * 1000);
          let result = updateTime.toLocaleString();
          if (suffixBy) {
            const admin = this.getAdminDisplayName(updateEvent.adminId);
            result = `${result} by ${admin}`;
          }
          return result;
        }
      },

      getPbxName(pbx) {
        switch (pbx && pbx.type) {
          case 'OSV': return pbx.name || pbx.PBXID;
          case 'UO': return pbx.name || pbx.companyName;
          case 'DLS': return pbx.name || pbx.hostname;
          case 'OS4K': return pbx.name || '';
          case 'OSBiz': return pbx.name || '';
          case 'Circuit': return pbx.name || '';
          case 'OSUC': return pbx.name || '';
          case 'CSV': return pbx.name || '';
          case 'NGTC': return pbx.name || '';
          case 'MiVoice400': return pbx.name || '';
          default: return '';
        }
      },

      getAdminDisplayName(adminId) {
        const admin = this.customer && this.customer.administrators && this.customer.administrators.filter(administrator => {
          return administrator.uuid === adminId;
        });
        return admin && admin.length && admin[0].displayName;
      },

      importPbx() {
        this.showImportPbx = true;
      },

      deleteItemConfirm() {

      }
    }
  }
</script>

<style scoped>
  .pbx-container {
    margin-top: 5.6rem;
  }
  .pbx-header {
    margin-bottom: 1.5rem;
  }
  .left-margin {
    margin-left: 1.0rem;
  }
</style>
<style>
  .customer-pbxes-headers {
      width: var(--width) !important;
  }
</style>
