<template>
  <v-container fluid class="report-container">
    <v-row no-gutter class="report-header" justify="space-between">
      <v-col sm="auto" class="text-bold-24 pa-0">{{ $t("migrationReport.label") }}</v-col>
      <v-col sm="auto" class="pa-0">
        <v-btn class="button-text-16 no-text-transform"
          color="primary" width="150"
          dark rounded outlined    
          @click="downloadReport()"
        >
          <v-icon left>download</v-icon>
          {{ $t("migrationReport.download") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutter :style="cssVariables">
      <v-data-table
        :headers="headers"
        :items="pagedReport"
        :options.sync="options"
        :server-items-length="totalElements"
        :no-data-text = "$t('common.noDataAvailable')"
        :footer-props="{
            disablePagination: false,
            itemsPerPageOptions: [5, 10, 20],
            showFirstLastPage: true,
            firstIcon: 'first_page',
            prevIcon: 'chevron_left',
            nextIcon: 'chevron_right',
            lastIcon: 'last_page',
            itemsPerPageText: $t('common.rowsPerPage')
        }"
        class="data-table full-width"
      >
      <template v-slot:[`header.action`]="{ header }">
        <v-text-field
          :value="name"
          @change="$event => name = $event"
          @click:clear="$event => name = ''"
          :label="$t(header.text)"
          append-icon="search"
          clear-icon="close"
          single-line hide-details
          flat solo clearable
          :placeholder="$t(header.text)"
        ></v-text-field>
      </template>
      <template v-slot:[`header.target`]="{ header }">
          {{$t(header.text)}}
      </template>
      <template v-slot:[`header.result`]="{ header }">
        <v-select
          :items="resultTypes"
          item-text="resultType"
          item-value="resultValue"
          @change="$event => result = $event"
          @click:clear="$event => result = ''"
          :label="$t(header.text)"
          append-icon="search"
          clear-icon="close"
          single-line hide-details
          flat solo clearable
          :placeholder="$t(header.text)"
          :menuProps="{ bottom: true, offsetY: true, maxHeight: 300 }"
        ></v-select>
      </template>
      <template v-slot:[`header.message`]="{ header }">
          {{$t(header.text)}}
      </template>
      </v-data-table>
    </v-row>
  </v-container>
</template>

<script>
  import { mapState } from 'vuex';
  import Utils from '../common/utils';


  export default {
    name: 'CustomerPBXes',
    components: {},

    data() {
      return {
        /**
         * The width of the headers has been calculated based on their label and potential content
         */
        headers:[
          {
            text: 'migrationReport.action', align: 'start', sortable: false, value: 'action', width:"400",
            class: "data-table-header migration-report-headers", cellClass: "data-table-cell"
          },
          {
            text: 'migrationReport.target', align: 'start', sortable: false, value: 'target', width:"100",
            class: "data-table-header migration-report-headers", cellClass: "data-table-cell"
          },
          {
            text: 'migrationReport.result', align: 'start', sortable: false, value: 'result', width:"100",
            class: "data-table-header migration-report-headers", cellClass: "data-table-cell"
          },
          {
            text: 'migrationReport.errorMessage', align: 'start', sortable: false, value: 'message', width:"300",
            class: "data-table-header migration-report-headers", cellClass: "data-table-cell"
          }
        ],
        reportOptions: {
          "page":1,
          "itemsPerPage":10,
          "search": '',
          "result": ''
        },
        resultTypes: Utils.resultTypes,
      }
    },
    computed: {
      ...mapState(['customer', 'unauthorized', 'loggedUser', 'migrationReport', 'deviceTypes']),
      cssVariables () {
        return Utils.calculateHeaderWidth(this.headers.length);
      },
      report() {
        return this.migrationReport && this.migrationReport.filter(item => item.handled === true) || [];
      },
      translatedReport() {
        return this.report.map(item => {
          const action = this.$t(`migrationReport.report.action.${item.action}`);
          const type = this.$t(`migrationReport.report.objectType.${item.objectType}`);
          const state = this.$t(`migrationReport.report.state.${item.state}`);
          return {
            action: `${action} ${type} ${this.getObjectNameForReport(item.object)}`,
            target: this.getPbxDescription(item.target),
            result: state,
            message: item.message,
            originalState: item.state
          }
        })
      },
      totalElements() {
        return this.filteredReport.length;
      },
      filteredReport() {
        return this.translatedReport
        .filter(item => item.originalState.match(this.reportOptions.result))
        .filter(item => item.action.toLowerCase().match(this.reportOptions.search.toLowerCase()));
      },
      pagedReport() {
        const items = this.filteredReport;
        const startElem = (this.reportOptions.page - 1) * this.reportOptions.itemsPerPage;
        const EndElem = startElem + this.reportOptions.itemsPerPage;
        return items.slice(startElem, EndElem);
      },
      name: {
        get: function () {
          return this.reportOptions.search;
        },
        set: function (value) {
          this.reportOptions.search = value === null ? '' : value;
          this.pageReset();
        }
      },
      result: {
        get: function () {
          return this.reportOptions.result;
          },
        set: function (value) {
          this.reportOptions.result = value === null ? '' : value;
          this.pageReset();
        }
      },
      options: {
        get: function () {
          return this.reportOptions;
        },
        set: function (value) {
          this.reportOptions = value;
        }
      }
    },
    methods: {
      isUnifyVideoAccount () {
        return this.loggedUser && this.loggedUser.user && this.loggedUser.user.isUnifyVideo === true;
      },
      pageReset() {
        this.options.page = 1;
      },
      getPbxDescription(pbx) {
        return Utils.getPbxFriendlyName(pbx, this.isUnifyVideoAccount());
      },
      getObjectNameForReport(name) {
        let objectName = name;
        this.deviceTypes && this.deviceTypes.forEach(device => {
          if (device.deviceValue === name) {
            objectName = this.$t(device.deviceName);
          }
        });
        return objectName;
      },
      downloadReport() {
        this.$log.debug('download report');
        try {
          let content = 'action;target;result;message\n';
          this.translatedReport.forEach(report => {
            content += `${report.action};${report.target};${report.result};${report.message}\n`
          })
          const data = new Blob([content], { type: 'application/csv' });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(data);
          link.download = 'MigrationReport.csv';
          link.click();
          URL.revokeObjectURL(link.href);
        } catch (err) {
          this.$log.error('[MigrationReport]: Error downloading report', err);
        }
      }
    }
  }
</script>

<style scoped>
  .report-container {
    margin-top: 5.6rem;
  }
  .report-header {
    margin-bottom: 1.5rem;
  }
  .left-margin {
    margin-left: 1.0rem;
  }
</style>
<style>
  .migration-report-headers {
      width: var(--width) !important;
  }
</style>
