<template>
  <v-row :style="cssVariables">
    <v-col>
      <v-data-table
        :headers="computedHeaders"
        :items="items"
        :options.sync="options"
        :server-items-length="totalElements"
        :loading="loading"
        :no-data-text = "$t('common.noDataAvailable')"
        :footer-props="{
            disablePagination: loading,
            itemsPerPageOptions: [5, 10, 20],
            showFirstLastPage: true,
            firstIcon: 'first_page',
            prevIcon: 'chevron_left',
            nextIcon: 'chevron_right',
            lastIcon: 'last_page',
            itemsPerPageText: $t('common.rowsPerPage')
        }"
        class="data-table full-width">
        <template v-slot:[`header.name`]="{ header }">
            <v-text-field
                :value="name"
                @change="$event => name = $event"
                @click:clear="$event => name = ''"
                :label="$t(header.text)"
                append-icon="search"
                clear-icon="close"
                single-line hide-details
                flat solo clearable
                :placeholder="$t(header.text)"
            ></v-text-field>
        </template>
        <template v-slot:[`header.source`]="{ header }">
            <v-text-field
                :value="source"
                @change="$event => source = $event"
                @click:clear="$event => source = ''"
                :label="$t(header.text)"
                append-icon="search"
                clear-icon="close"
                single-line hide-details
                flat solo clearable
                :placeholder="$t(header.text)"
            ></v-text-field>
        </template>
        <template v-slot:[`header.target`]="{ header }">
            <v-combobox
              v-model="target"
              :items="!target ? filterTypes : undefined"
              item-text="filterLabel"
              item-value="filterValue"
              :label="$t(header.text)"
              hide-no-data hide-details hide-selected
              append-icon="search"
              clear-icon="close"
              solo single-line flat clearable
              @change="$event => target = $event"
              @click:clear="$event => target = ''"
              :placeholder="$t(header.text)"
            >
        </v-combobox>
        </template>
        <template v-slot:[`header.removal`]="{ header }">
            <v-combobox
              v-model="removal"
              :items="!removal ? filterTypes : undefined"
              item-text="filterLabel"
              item-value="filterValue"
              :label="$t(header.text)"
              hide-no-data hide-details hide-selected
              append-icon="search"
              clear-icon="close"
              solo single-line flat clearable
              @change="$event => removal = $event"
              @click:clear="$event => removal = ''"
              :placeholder="$t(header.text)"
            ></v-combobox>
        </template>
        <template v-slot:[`header.code`]="{ header }">
            <v-combobox
              v-model="code"
              :items="!code ? filterTypes : undefined"
              item-text="filterLabel"
              item-value="filterValue"
              :label="$t(header.text)"
              hide-no-data hide-details hide-selected
              append-icon="search"
              clear-icon="close"
              solo single-line flat clearable
              @change="$event => code = $event"
              @click:clear="$event => code = ''"
              :placeholder="$t(header.text)"
            ></v-combobox>
        </template>
        <template v-slot:[`header.usersTotal`]="{ header }">
          {{$t(header.text)}}
        </template>
        <template v-slot:[`header.usersUsed`]="{ header }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                {{ $t(header.text )}} 
                <v-icon >info</v-icon>
              </span>
            </template>
            <span>{{ $t("configureMigrate.usersTooltip") }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`header.usersFree`]="{ header }"> 
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                {{$t(header.text)}}
                <v-icon>info</v-icon>
              </span>
            </template>
            <span>{{ $t("configureMigrate.usersTooltip") }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`header.callQueues`]="{ header }">
          {{$t(header.text)}}
        </template>
        <template v-slot:[`header.status`]="{ header }">
          <v-select
              :items="statusTypes"
              item-text="statusType"
              item-value="statusValue"
              @change="$event => status = $event"
              @click:clear="$event => status = ''"
              :label="$t(header.text)"
              append-icon="search"
              clear-icon="close"
              single-line hide-details
              flat solo clearable
              :placeholder="$t(header.text)"
              :menuProps="{ bottom: true, offsetY: true, maxHeight: 300 }"
          ></v-select>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip v-show="item.status" :class="getClass(item.status)"
             label
          >  {{ $t(item.status && "status." + item.status.toLowerCase() || "status.none") }}
          </v-chip>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
  import { mapState } from 'vuex';
  import store from '../store';
  import Utils from '../common/utils';

  export default {
    name: 'CustomerSites',
    data() {
      return {
        /**
         * The width of the headers has been calculated based on their label and potential content
         */
        headers:[
          { 
						text: 'common.name', value: 'name', align: 'start', sortable: false, width: "120",
						class: 'data-table-header customer-sites-headers', cellClass: 'data-table-cell', hideForVideo: false
					},
          { 
						text: 'configureMigrate.migrateFrom', value: 'source', align: 'start', sortable: false, width: "120",
						class: 'data-table-header customer-sites-headers', cellClass: 'data-table-cell', hideForVideo: false
					},
          { 
						text: 'configureMigrate.migrateTo', value: 'target', align: 'start', sortable: false, width: "120",
						class: 'data-table-header customer-sites-headers', cellClass: 'data-table-cell', hideForVideo: false
					},
          { 
						text: 'configureMigrate.status', value: 'status', align: 'start', sortable: false, width: "120",
						class: 'data-table-header customer-sites-headers', cellClass: 'data-table-cell', hideForVideo: false
					},
          { 
						text: 'configureMigrate.removeFrom', value: 'removal', align: 'start', sortable: false, width: "120",
						class: 'data-table-header customer-sites-headers', cellClass: 'data-table-cell', hideForVideo: false
					},
          {	
						text: 'configureMigrate.code', value: 'code', align: 'start', sortable: false, width: "100",
						class: 'data-table-header customer-sites-headers', cellClass: 'data-table-cell', hideForVideo: true
					},
          { 
						text: 'configureMigrate.totalUsers', value: 'usersTotal', align: 'start', sortable: false, width: "70",
						class: 'data-table-header customer-sites-headers', cellClass: 'data-table-cell', hideForVideo: false
					},
          { 
						text: 'configureMigrate.usedUsers', value: 'usersUsed', align: 'start', sortable: false, width: "100",
						class: 'data-table-header customer-sites-headers', cellClass: 'data-table-cell', hideForVideo: true
					},
          { 
						text: 'configureMigrate.freeUsers', value: 'usersFree', align: 'start', sortable: false, width: "100",
						class: 'data-table-header customer-sites-headers', cellClass: 'data-table-cell', hideForVideo: true
					},
          { 
						text: 'configureMigrate.callQueues', value: 'callQueues', align: 'start', sortable: false, width: "70",
						class: 'data-table-header customer-sites-headers', cellClass: 'data-table-cell', hideForVideo: true
					}
        ],
        statusTypes: Utils.statusTypes,
        filterTypes: Utils.filterTypes,
        loading: false
      }
    },
    computed: {
      ...mapState(['customer', 'sites', 'loggedUser']),
			cssVariables () {
        return Utils.calculateHeaderWidth(this.headers.length);
      },
      totalElements() {
        return this.sites && this.sites.paging && this.sites.paging.totalElements || 0;
      },
      computedHeaders() {
        return this.headers.filter(header => header.hideForVideo === false || this.isUnifyVideoAccount() === false);
      },
      items() {
        const { page , itemsPerPage } = this.options;
        // This only happens when a new pbx is imported/deleted or at logon. Records.length will be at max as dictated
        // by the server and the page will be 1.
        if (this.sites && this.sites.records && this.sites.records.length > itemsPerPage && page === 1) {
            store.commit('reduceRecords', { type: 'sites', number: itemsPerPage })
        }
        return this.sites && this.sites.records && this.sites.records.map(item => {
          const name = item.translatableName && this.$t(item.translatableName) || item.name;
          return {
            name: name,
            code: item.code,
            source: item.sourcesText,
            target: item.targetText,
            status: item.status,
            removal: item.removalsText,
            usersTotal: item.users && item.users.total,
            usersUsed: item.users && item.users.used && `(${item.users.used.total}/${item.users.used.withoutNumber}/${item.users.used.withNumber}/${item.users.used.withDevice})`,
            usersFree: item.users && item.users.used && `(${item.users.free.total}/${item.users.free.withoutNumber}/${item.users.free.withNumber}/${item.users.free.withDevice})`,
            callQueues: item.features && item.features.callQueues
          }
        });
      },
      name: {
        get: function () {
          return this.sites && this.sites.search && this.sites.search.name;
          },
        set: function (value) {
          this.search.name = value;
          this.pageReset();
        }
      },
      source: {
        get: function () {
          return this.sites && this.sites.search && this.sites.search.source;
          },
        set: function (value) {
          this.search.source = value;
          this.pageReset();
        }
      },
      target: {
        get: function () {
          return this.sites && this.sites.search && this.sites.search.target;
          },
        set: function (value) {
          this.search.target = value;
          this.pageReset();
        }
      },
      removal: {
        get: function () {
          return this.sites && this.sites.search && this.sites.search.removal;
          },
        set: function (value) {
          this.search.removal = value;
          this.pageReset();
        }
      },
      code: {
        get: function () {
          return this.sites && this.sites.search && this.sites.search.code;
          },
        set: function (value) {
          this.search.code = value;
          this.pageReset();
        }
      },
      status: {
        get: function () {
          return this.sites && this.sites.search && this.sites.search.status;
          },
        set: function (value) {
          this.search.status = value;
          this.pageReset();
        }
      },
      search() {
        return {
            name: this.name,
            source: this.source,
            target: this.target,
            removal: this.removal,
            status: this.status,
            code: this.code
        }
      },
      options: {
        get: function () {
          return this.sites.paging;
        },
        set: function (value) {
          this.fetchData(value, this.search);
        }
      }
    },
    methods: {
      isUnifyVideoAccount () {
        return this.loggedUser && this.loggedUser.user && this.loggedUser.user.isUnifyVideo === true;
      },
      pageReset() {
        this.options.page = 1;
        this.fetchData(this.options, this.search);
      },
      getClass(value) {
        let statusClass;
        if (value === 'Pending') {
          statusClass = 'status pending';
        } else if (value === 'Successful') {
          statusClass = 'status successful';
        } else if (value === 'Failed') {
          statusClass = 'status fail';
        } else {
          statusClass = '';
        }
        return statusClass;
      },
      detectQueryChange(options, search) {
         const { page, itemsPerPage } = options
         const { name, source, target, removal, status, code } = search
         const noChange = page && itemsPerPage && this.sites && this.sites.paging
                          && this.sites.paging.page === page && this.sites.paging.itemsPerPage === itemsPerPage
                          && this.sites && this.sites.search
                          && this.sites.search.name === name && this.sites.search.source === source
                          && this.sites.search.target === target && this.sites.search.removal === removal
                          && this.sites.search.status === status && this.sites.search.code === code;
         return !noChange
      },
      getQuery(options, search) {
         const { page, itemsPerPage } = options
         const pagingParameters = { page, itemsPerPage }
         return Utils.encodeBase64Url(JSON.stringify({ options: pagingParameters, search }));
      },
      fetchData: async function(options, search) {
        if (this.loading) { return; }
        try {
          this.loading = true;
          if (this.detectQueryChange(options,search)) {
            await store.dispatch('getSites', {customerId: this.customer.uuid, query: this.getQuery(options, search)});
          }
        } finally {
          this.loading = false;
        }
      }
    }
  }
</script>
<style>
  .customer-sites-headers {
      width: var(--width) !important;
  }
</style>
