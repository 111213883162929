import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home';
import Logon from '../views/Logon';
import About from '../views/About';
import New from '../views/New';
import Stepper from '../views/Stepper';
import * as RestApiHandler from '../common/restApiHandler';
import { logger } from 'vue-logger';

Vue.use(VueRouter);

const navigationGuard = async (to, from, next) => {
  if (to.path === '/session' && to.query && to.query.session) {
    logger.debug('[Router] navigationGuard received SessionToken. Redirects to /');
    RestApiHandler.setSessionToken(to.query.session);
    window.location.assign('/');
    return;
  }

  if (!RestApiHandler.getSessionToken() && to.path !== '/logon') {
    logger.debug('[Router] navigationGuard no SessionToken available. Redirects to /logon');
    next('/logon');
    return;
  }
  next();
};

const routes = [
  {
    path: '/',
    name: '',
    component: Home,
    beforeEnter: navigationGuard,
  },
  {
    path: '/session',
    name: '',
    beforeEnter: navigationGuard,
  },
  {
    path: '/logon',
    component: Logon
  },
  {
    path: '/about',
    component: About
  },
  {
    path: '/new',
    component: New
  },
  {
    path: '/stepper',
    component: Stepper,
    beforeEnter: navigationGuard
  }
];

const router = new VueRouter({
  routes
})

export default router;
