'use strict';

import Vue from 'vue'
import Vuex from 'vuex'
import { logger } from 'vue-logger';
import * as RestApiHandler from '../common/restApiHandler';

Vue.use(Vuex)

async function handleRestApiResponse(request, response) {
  let result = {status: 500, statusText: 'Internal server error'};
  try {
    if (!response) {
      result.statusText = 'Invalid response from server';
    } else {
      switch (response.status) {
        case 200:
          try {
            result =  await response.json();
          } catch (error) {
            logger.error(`[Store] handleRestApiResponse error while getting json from response for request ${request}: `, error);
          }
          store.commit('setRequestStatus', { status: response.status, statusText: response.statusText, request });
          break
        case 401:
          result = { status: response.status, statusText: response.statusText, request };
          store.commit('setUnauthorized', result);
          break;
        default:
          if (typeof response === 'string') {
            result.statusText = response;
          } else {
            result = {
              status: response.status,
              statusText: response.error && response.error.statusText || response.statusText,
              request
            }
          }
          store.commit('setRequestStatus', result);
          break;
      }
    }
  } catch(error) {
    logger.error(`[Store] handleRestApiResponse for ${request} error: `, error);
  }
  return result;
}
const _emptyRecordsObject = {
    records: [],
    search: {},
    paging : {
        page: 1,
        itemsPerPage: 10,
        totalPages: 0,
        totalElements: 0
    }
}
const store = new Vuex.Store({
  state: {
    appName: '',
    appNameSplitted: {},
    customer: {},
    customers: [],
    importingPbx: false,
    dialogStatusText: '',
    loggedUser: {},
    pbxes: _emptyRecordsObject,
    businessGroups: _emptyRecordsObject,
    sites: _emptyRecordsObject,
    users: _emptyRecordsObject,
    trunks: _emptyRecordsObject,
    requestStatus: {},
    unauthorized: false,
    saveConfigurationStatus: '',
    consistencyReport: null,
    migrationReport: null,
    currentFileUploadFormStatus: 'INITIAL',
    migrationEnabled: false,
    downloadEnabled: false,
    multiSiteEnabled: false,
    siteCodesEnabled: false,
    accountDefaultRole: '',
    accountRoles: [],
    deviceTypes: [],
    environments: [],
    allowStepper: false,
    migrationProgress: { totalTransactions: 0, currentTransaction: 0 },
    regionals: {
      countries: [],
      languages: [],
      timezones: [],
      states: [],
      roles:[]
    },
    sendWelcomeEmail: false,
    // States for Stepper
    currentStep: 1,
    migrationBusinessGroups: _emptyRecordsObject,
    selectedMigrationBusinessGroups: [],
    migrationFeatures: _emptyRecordsObject,
    selectedMigrationFeatures: [],
    migrationFeatureErrors: {},
    migrationSites: _emptyRecordsObject,
    selectedMigrationSites: [],
    migrationSiteErrors: {},
    migrationUsers: _emptyRecordsObject,
    selectedMigrationUsers: [],
    migrationUserErrors: {},
    configuredUsers: false,
    selectAllUsers: false,
    selectAllPorting: false,
    selectAllFeatures: false,
    stepperConsistencyReport: null,
  },
  getters: {
    getCurrentStep: (state) => {
      return state.currentStep;
    },
    getCombinedSites: (state) => {
      return state.migrationSites.records.map(dbSite => {
        let migrationSite = state.selectedMigrationSites.find(e => e.uuid == dbSite.uuid);
        let combinedSite = JSON.parse(JSON.stringify(migrationSite || dbSite));
        combinedSite.isSelected = !!migrationSite;
        if (!combinedSite.errors) {
          combinedSite.errors = {};
        }
        return combinedSite;
      });
    },
    getSiteValidationErrors: (state) => () => {
			return Object.keys(state.migrationSiteErrors).filter(uuid => {
				const validationErrorObj = state.migrationSiteErrors[uuid];
				return validationErrorObj && Object.keys(validationErrorObj).find(key => validationErrorObj[key]);
			});
    },
    getCombinedUsers: (state) => () => {
      return state.migrationUsers.records.map(dbUser => {
        let migrationUser = state.selectedMigrationUsers.find(e => e.uuid == dbUser.uuid);
        let combinedUser = JSON.parse(JSON.stringify(migrationUser || dbUser));
        combinedUser.isSelected = !!migrationUser || state.selectAllUsers;
				combinedUser.isEdited = !!migrationUser && !!migrationUser.isEdited;
        return combinedUser;
      });
    },
    getCombinedFeatures: (state) => () => {
      return state.migrationFeatures.records.map(dbFeature => {
        let migrationFeature = state.selectedMigrationFeatures.find(e => e.uuid == dbFeature.uuid);
        let combinedFeature = JSON.parse(JSON.stringify(migrationFeature || dbFeature));
        combinedFeature.isSelected = !!migrationFeature || state.selectAllFeatures;
        combinedFeature.isEdited = !!migrationFeature && !!migrationFeature.isEdited;
        let configuredUsers = state.selectedMigrationUsers.filter(user => combinedFeature.user.find(uuid => uuid == user.uuid)).length;
        let notMigrated = 0;
        let configured = 0;
        if (!state.selectAllUsers) {
            configured = configuredUsers;
            notMigrated = combinedFeature.members.notMigrated - configuredUsers;
        } else {
            configured = combinedFeature.user.length;
            notMigrated = combinedFeature.user.length - combinedFeature.members.migrated;
        }
        combinedFeature.membersTotal = combinedFeature.members && 
            `(${notMigrated}/${configured}/${combinedFeature.members.migrated})`;
        return combinedFeature;
      });
    },
    getUserValidationErrors: (state) => () => {
      return Object.keys(state.migrationUserErrors).filter(uuid => {
				const validationErrorObj = state.migrationUserErrors[uuid];
				return validationErrorObj && Object.keys(validationErrorObj).find(key => validationErrorObj[key]);
			});
    },
    getFeatureValidationErrors: (state) => () => {
      return Object.keys(state.migrationFeatureErrors).filter(uuid => {
				const validationErrorObj = state.migrationFeatureErrors[uuid];
				return validationErrorObj && Object.keys(validationErrorObj).find(key => validationErrorObj[key]);
			});
    },
    getStepConsistencyErrors: (state) => (step) => {
      if (!state.stepperConsistencyReport) {
        return [];
      }
      return state.stepperConsistencyReport.filter(consistencyError => consistencyError.step === step);
    },
    getStepperConsistencyErrorText: (state) => (uuid, field) => {
      if (!state.stepperConsistencyReport) {
        return '';
      }
      let consistencyErrors =  state.stepperConsistencyReport.filter(consistencyError => consistencyError.uuid === uuid && consistencyError.field === field );
      return consistencyErrors.length ? consistencyErrors.map(error => error.message).join(' ') : '';
    },
  },
  mutations: {
    handleServerLog(state, { type, msg } ) {
      switch(type) {
        case 'info':
          logger.info(msg);
          break;
        case 'error':
          logger.error(msg);
          break;
        case 'warn':
          logger.warn(msg);
          break;
        default:
          logger.debug(msg);
      }
    },
    setAppName(state, value) {
      state.appName = value;
      const appNameArr = value && value.split(' ');
      if (appNameArr && appNameArr.length) {
        state.appNameSplitted = {
          first: appNameArr.length >= 1 ? appNameArr[0] : '',
          second: appNameArr.length >= 2 ? appNameArr[1] : '',
          third: appNameArr.length >= 3 ? appNameArr[2] : '',
          fourth: appNameArr.length >= 4 ? appNameArr[3] : ''
        }
      } else {
        state.appNameSplitted = {};
      }
    },
    setCustomer(state, value) {
      state.customer = value;
      if (value) {
        // When a new pbx is imported/deleted the the itemsPerPage value has to maintained
        const pbxItemsPerPage = state.pbxes && state.pbxes.paging && state.pbxes.paging.itemsPerPage || 10;
        const bgsItemsPerPage = state.businessGroups && state.businessGroups.paging && state.businessGroups.paging.itemsPerPage || 10;
        const sitesItemsPerPage = state.sites && state.sites.paging && state.sites.paging.itemsPerPage || 10;
        const usersItemsPerPage = state.users && state.users.paging && state.users.paging.itemsPerPage || 10;
        const trunkItemsPerPage = state.trunks && state.trunks.paging && state.trunks.paging.itemsPerPage || 10;
        value.pbxes.paging.itemsPerPage = pbxItemsPerPage;
        value.bgs.paging.itemsPerPage = bgsItemsPerPage;
        value.sites.paging.itemsPerPage = sitesItemsPerPage;
        value.users.paging.itemsPerPage = usersItemsPerPage;
        value.trunks.paging.itemsPerPage = trunkItemsPerPage;
        // Change state for all the Objects shown in the UI
        state.pbxes = value.pbxes;
        state.businessGroups = value.bgs;
        state.sites = value.sites;
        state.users = value.users;
        state.trunks = value.trunks;
        state.migrationEnabled = (value.pendingMigrations && value.pendingMigrations > 0);
        state.downloadEnabled = (value.downloadsUnifyPhoneStandAlone && value.downloadsUnifyPhoneStandAlone > 0);
        state.multiSiteEnabled = value.multiSiteEnabled === true;
        state.siteCodesEnabled = value.siteCodesEnabled === true;
        state.accountDefaultRole = value.accountDefaultRole;
        state.accountRoles = value.accountRoles;
        state.allowStepper = value.allowStepper === true;
      }
    },
    setCustomerDisableCustomerDataAutoDelete(state, value) {
      state.customer.disableCustomerDataAutoDelete = value;
      store.commit('setCustomer', state.customer);
    },
    setCustomers(state, value) {
      state.customers = value;
      if (value && value.length === 1) {
        store.commit('setCustomer', value[0])
      }
    },
    reduceRecords(state, { type, number } ) {
        const split = state[type].records.splice(0, number);
        state[type].records = split;
    },
    setImportingPbx(state, value) {
      state.importingPbx = value;
    },
    setDialogStatusText(state, value) {
      state.dialogStatusText = value;
    },
    setLoggedOnUser(state, value) {
      state.loggedUser !== value && (state.loggedUser = value);
    },
    setRequestStatus(state, value) {
      state[`${value.request}RequestStatus`] = value;
    },
    setCurrentStep(state, value) {
      state.currentStep = value;
    },
    setBusinessGroups(state, value) {
        state.businessGroups = value;
    },
    setMigrationBusinessGroups(state, value) {
      state.migrationBusinessGroups = value;
    },
    setSelectedMigrationFeatures(state, features) {
      state.selectedMigrationFeatures = features;
    },
    setSites(state, value) {
      state.sites = value;
    },
    setMigrationSites(state, value) {
      state.migrationSites = value;
    },
    setUsers(state, value) {
      state.users = value;
    },
    setTrunks(state, value) {
      state.trunks = value;
    },
    setMigrationUsers(state, value) {
      state.migrationUsers = value;
    },
    setMigrationFeatures(state, value) {
      state.migrationFeatures = value;
    },
    setPbxes(state, value) {
      state.pbxes = value;
    },
    setSelectedMigrationBusinessGroups(state, value) {
        state.selectedMigrationBusinessGroups = value;

        // Selecting a new business group, need to reset data from following steps
        state.selectedMigrationUsers = [];
        state.selectedMigrationSites = [];

        state.stepperConsistencyReport = null;
    },
    resetMigrationItems(state){
        state.currentStep = 1;
        state.migrationBusinessGroups = _emptyRecordsObject;
        state.migrationSites = _emptyRecordsObject;
        state.migrationUsers = _emptyRecordsObject;
        state.migrationFeatures = _emptyRecordsObject;

        state.selectedMigrationBusinessGroups = [];
        state.selectedMigrationSites = [];
        state.selectedMigrationUsers = [];
        state.selectedMigrationFeatures = [];

        state.migrationSiteErrors = {};
        state.migrationUserErrors = {};
        state.migrationFeatureErrors = {};

				state.selectAllUsers = false;
				state.selectAllPorting = false;
        state.selectAllFeatures = false;

        state.configuredUsers = false;

        state.stepperConsistencyReport = null;
    },
    setUnauthorized(state, value) {
      state.unauthorized = value;
    },
    setConsistencyReport(state, value) {
      state.consistencyReport = value;
    },
    setStepperConsistencyReport(state, value) {
      state.stepperConsistencyReport = value;
    },
    setMigrationReport(state, value) {
      state.migrationReport = value;
    },
    resetReports(state) {
      state.consistencyReport = null;
      state.migrationReport = null;
    },
    setFileUploadFormStatus(state, value) {
      state.currentFileUploadFormStatus = value;
    },
    setMigrationEnabled(state, value) {
      state.migrationEnabled = value;
    },
    setMigrationProgress(state, value) {
      state.migrationProgress = value;
    },
    setSaveConfigurationStatus(state, value) {
      state.saveConfigurationStatus = value;
    },
    setEditedSite(state, site) {
      let currentMigrationSite = state.selectedMigrationSites.find(e => e.uuid == site.uuid);
			let migrationSite = JSON.parse(JSON.stringify(site));
			if (currentMigrationSite) {
				Vue.set(state.selectedMigrationSites, state.selectedMigrationSites.indexOf(currentMigrationSite), migrationSite);
			} else {
				state.selectedMigrationSites.push(migrationSite);
			}
    },
    setSelectedMigrationSites(state, sites) {
      state.selectedMigrationSites = sites;

      // Selecting a new site, need to reset data from following steps
      state.selectedMigrationUsers = [];
      state.selectedMigrationFeatures = [];
      state.selectAllUsers = false;
			state.selectAllPorting = false;
      state.selectAllFeatures = false;

      state.migrationSiteErrors = {};
      state.migrationUserErrors = {};
      state.migrationFeatureErrors = {};

      state.configuredUsers = false;

      state.stepperConsistencyReport = null;

    },
    setSelectAllPorting(state, value) {
      state.selectAllPorting = value;
    },
    setSelectAllUsers(state, value) {
      state.selectAllUsers = value;
    },
    setSelectAllFeatures(state, value) {
      state.selectAllFeatures = value;
    },
    setRegionals(state, regionals) {
      state.regionals.countries = regionals && regionals.countries || [];
      state.regionals.languages = regionals && regionals.languages || [];
      state.regionals.timezones = regionals && regionals.timezones || [];
      state.regionals.states = regionals && regionals.states || [];
      state.regionals.roles = regionals && regionals.roles || [];
    },
    setDevices(state, devices) {
      state.deviceTypes = devices && devices.deviceTypes || [];
    },
    setEnvironments(state, value) {
      state.environments = value;
    },
    setSendWelcomeEmail(state, value) {
      state.sendWelcomeEmail = value;
    },
    
    setEditedUser(state, user) {
			user.isEdited = true;
      let currentMigrationUser = state.selectedMigrationUsers.find(e => e.uuid == user.uuid);
      let migrationUser = JSON.parse(JSON.stringify(user));
			if (currentMigrationUser) {
				Vue.set(state.selectedMigrationUsers, state.selectedMigrationUsers.indexOf(currentMigrationUser), migrationUser);
			} else {
				state.selectedMigrationUsers.push(migrationUser);
			}
    },
    setEditedFeature(state, feature) {
      feature.isEdited = true;
      let currentMigrationFeature = state.selectedMigrationFeatures.find(e => e.uuid == feature.uuid);
      let migrationFeature = JSON.parse(JSON.stringify(feature));
      if (currentMigrationFeature) {
				Vue.set(state.selectedMigrationFeatures, state.selectedMigrationFeatures.indexOf(currentMigrationFeature), migrationFeature);
			} else {
				state.selectedMigrationFeatures.push(migrationFeature);
			}
    },

    togglePortingAll(state, value) {
      const migrationUsers = state.selectedMigrationUsers.map(user => {
          const migrationUser = JSON.parse(JSON.stringify(user));
          migrationUser.numberPorting = value;
          return migrationUser;
      })
      state.selectedMigrationUsers = migrationUsers;
    },
    setSelectedMigrationUsers(state, users) {
      state.selectedMigrationUsers = users;
    },
    setConfiguredUsers(state, users) {
      // We add only new/unedited users 
      const newUsers = users.filter(user => !state.selectedMigrationUsers.find(e => e.uuid === user.uuid));
      state.selectedMigrationUsers = newUsers.concat(state.selectedMigrationUsers);
      state.configuredUsers = true;
    },
    setEditedUsersForMigration(state) {
      const editedUsers = state.selectedMigrationUsers.filter(e => e.isEdited);
      state.selectedMigrationUsers = editedUsers
    },
    setEditedFeaturesForMigration(state) {
      const editedFeatures = state.selectedMigrationFeatures.filter(e => e.isEdited);
      state.selectedMigrationFeatures = editedFeatures;
    },
    setMigrationSiteErrors(state, {siteUuid, errorObj}) {
      Vue.set(state.migrationSiteErrors, siteUuid, errorObj);
    },
    resetMigrationSiteErrors(state, siteUuid) {
      if (state.migrationSiteErrors[siteUuid]) {
        Vue.delete(state.migrationSiteErrors, siteUuid)
      }
    },
    setMigrationUserErrors(state, {userUuid, errorObj}) {
      Vue.set(state.migrationUserErrors, userUuid, errorObj);
    },
    setAllMigrationUserErrors(state, value) {
      state.migrationUserErrors = value;
    },
		resetMigrationUserErrors(state, userUuid) {
			if (state.migrationUserErrors[userUuid]) {
				Vue.delete(state.migrationUserErrors, userUuid)
			}
		},
		resetAllMigrationUserErrors(state) {
			state.migrationUserErrors = {};
		},
    setMigrationFeatureErrors(state, {featureUuid, errorObj}) {
      Vue.set(state.migrationFeatureErrors, featureUuid, errorObj);
    },
    setAllMigrationFeatureErrors(state, value) {
      state.migrationFeatureErrors = value;
    },
    resetMigrationFeatureErrors(state, featureUuid) {
			if (state.migrationFeatureErrors[featureUuid]) {
				Vue.delete(state.migrationFeatureErrors, featureUuid)
			}
		},
    resetAllMigrationFeatureErrors(state) {
			state.migrationFeatureErrors = {};
		},
  },
  actions: {
    async getBusinessGroups({commit}, options) {
      logger.error('[Store] Enter getBusinessGroups');
      try {
        const { customerId, query } = options;
        const result = await RestApiHandler.getBusinessGroups(customerId, query);
        const response = await result.json();
        commit('setBusinessGroups', response && response.bgs);
      } catch (error) {
        logger.error('[Store] getBusinessGroups error:\n', error);
      } finally {
        logger.error('[Store] Leave getBusinessGroups');
      }
    },

    async getMigrationBusinessGroups({commit}, options) {
      logger.error('[Store] Enter getMigrationBusinessGroups');
      try {
        const { customerId, query } = options;
        const result = await RestApiHandler.getBusinessGroups(customerId, query);
        const response = await result.json();
        commit('setMigrationBusinessGroups', response && response.bgs);
      } catch (error) {
        logger.error('[Store] getMigrationBusinessGroups error:\n', error);
      } finally {
        logger.error('[Store] Leave getMigrationBusinessGroups');
      }
    },

    async getMigrationFeatures({commit}, options) {
      logger.error('[Store] Enter getMigrationFeatures');
      try {
        const { customerId, query } = options;
        const result = await RestApiHandler.getFeatures(customerId, query);
        const response = await result.json();
        commit('setMigrationFeatures', response && response.features);
      } catch (error) {
        logger.error('[Store] getMigrationFeatures error:\n', error);
      } finally {
        logger.error('[Store] Leave getMigrationFeatures');
      }
    },

    async getSites({commit}, options) {
      logger.error('[Store] Enter getSites');
      try {
        const { customerId, query } = options;
        const result = await RestApiHandler.getSites(customerId, query);
        const response = await result.json();
        commit('setSites', response && response.sites);
      } catch (error) {
        logger.error('[Store] getSites error:\n', error);
      } finally {
        logger.error('[Store] Leave getSites');
      }
    },

    async getMigrationSites({commit}, options) {
      logger.error('[Store] Enter getMigrationSites');
      try {
        const { customerId, query } = options;
        const result = await RestApiHandler.getSites(customerId, query);
        const response = await result.json();
        commit('setMigrationSites', response && response.sites);
      } catch (error) {
        logger.error('[Store] getMigrationSites error:\n', error);
      } finally {
        logger.error('[Store] Leave getMigrationSites');
      }
    },

    async getUsers({commit}, options) {
      logger.error('[Store] Enter getUsers');
      try {
        const { customerId, query } = options;
        const result = await RestApiHandler.getUsers(customerId, query);
        const response = await result.json();
        commit('setUsers', response && response.users);
      } catch (error) {
        logger.error('[Store] getUsers error: ', error);
      } finally {
        logger.error('[Store] Leave getUsers');
      }
    },
  
    async getTrunks({commit}, options) {
      logger.error('[Store] Enter getTrunks');
      try {
        const { customerId, query } = options;
        const result = await RestApiHandler.getTrunks(customerId, query);
        const response = await result.json();
        commit('setTrunks', response && response.trunks);
      } catch (error) {
        logger.error('[Store] getTrunks error: ', error);
      } finally {
        logger.error('[Store] Leave getTrunks');
      }
    },

    async getMigrationUsers({commit}, options) {
      logger.error('[Store] Enter getMigrationUsers');
      try {
        const { customerId, query } = options;
        const result = await RestApiHandler.getUsers(customerId, query);
        const response = await result.json();
        commit('setMigrationUsers', response && response.users);
      } catch (error) {
        logger.error('[Store] getMigrationUsers error: ', error);
      } finally {
        logger.error('[Store] Leave getMigrationUsers');
      }
    },

    async getConfiguredUsers({commit}, options) {
      if (!this.state.configuredUsers) {
        logger.error('[Store] Enter getMigrationUsers');
        try {
          const { customerId, query } = options;
          const result = await RestApiHandler.getUsers(customerId, query);
          const response = await result.json();
          commit('setConfiguredUsers', response && response.users.records);
        } catch (error) {
          logger.error('[Store] getAlreadyConfiguredUsers error: ', error);
        } finally {
          logger.error('[Store] Leave getAlreadyConfiguredUsers');
        }
      }
    },

    async resetItemStepperConsistencyError({state, commit}, {uuids, field} ) {
      if (state.stepperConsistencyReport) {

        let matchedErrorFilter = function(error) {
          const uuidIncluded = uuids.indexOf(error.uuid) > -1;
          const fieldFilter = field ? error.field === field : true;
          return uuidIncluded && fieldFilter;
        };
        const consistencyErrors = state.stepperConsistencyReport.filter(error => matchedErrorFilter(error));
        let filteredConsistencyReport = state.stepperConsistencyReport.filter(error => !matchedErrorFilter(error));

        consistencyErrors.forEach(consistencyError => {
          if (consistencyError && consistencyError.duplicateUuids) {
          
            if (consistencyError.duplicateUuids.length > 2) {
              // Remove uuid from other duplicates
              const otherUuids = consistencyError.duplicateUuids.filter(duplicateUuid => duplicateUuid !== consistencyError.uuid);
              otherUuids.forEach(otherUuid => {
                const duplicateError = state.stepperConsistencyReport.find(error => error.uuid === otherUuid && (field ? error.field === field : true));
                if (duplicateError) {
                  duplicateError.duplicateUuids = otherUuids;
                }
              });
            } else {
              // Remove last duplicate error (no longer duplicate)
              const otherUuid = consistencyError.duplicateUuids.find(duplicateUuid => duplicateUuid !== consistencyError.uuid);
              filteredConsistencyReport = filteredConsistencyReport.filter(error => error.uuid !== otherUuid || (field ? error.field !== field : false));
            }
          }
        });

        if (state.stepperConsistencyReport.length !== filteredConsistencyReport.length) {
          commit('setStepperConsistencyReport', filteredConsistencyReport);
          return true;
        } 
      }
      return false;
    },

    async resetStepStepperConsistencyError({state, commit}, step) {
      if (state.stepperConsistencyReport) {
        const filteredConsistencyReport = state.stepperConsistencyReport.filter(consistencyError => consistencyError.step !== step);
        if (state.stepperConsistencyReport.length !== filteredConsistencyReport.length) {
          commit('setStepperConsistencyReport', filteredConsistencyReport);
          return true;
        } 
      }
      return false;
    },

    async getPbxes({commit}, options) {
      logger.error('[Store] Enter getPbxes');
      try {
        const { customerId, query } = options;
        const result = await RestApiHandler.getPbxes(customerId, query);
        const response = await result.json();
        commit('setPbxes', response && response.pbxes);
      } catch (error) {
        logger.error('[Store] getPbxes error: ', error);
      } finally {
        logger.error('[Store] Leave getPbxes');
      }
    },

    async importPbx({commit}, options) {
      logger.error('[Store] Enter importPbx');
      try {
        const { pbxType, customerId } = options;
        commit('setImportingPbx', true);
        const response = await RestApiHandler.importPbx(pbxType, customerId);
        const result = await handleRestApiResponse('importPbx', response);
        commit('setCustomers', result && result.customers);
        commit('setDialogStatusText', result && result.message);
      } catch (error) {
        logger.error('[Store] getPbxes error: ', error);
      } finally {
        commit('setImportingPbx', false);
        logger.error('[Store] Leave importPbx');
      }
    },

    async getRegionals({commit, state}) {
      logger.error('[Store] Enter getRegionals');
      try {
        if (!state.countries || !state.countries.length) {
          const response = await RestApiHandler.getRegionals();
          const result = await handleRestApiResponse('getRegionals', response);
          commit('setRegionals', result);
        }
        
      } catch (error) {
        logger.error('[Store] getRegionals error: ', error);
      } finally {
        logger.error('[Store] Leave getRegionals');
      }
    },

    async getDevices({commit, state}) {
      logger.error('[Store] Enter getDevices');
      try {
        if (!state.countries || !state.countries.length) {
          const response = await RestApiHandler.getDevices();
          const result = await handleRestApiResponse('getDevices', response);
          commit('setDevices', result);
        }
        
      } catch (error) {
        logger.error('[Store] getDevices error: ', error);
      } finally {
        logger.error('[Store] Leave getDevices');
      }
    },

    async getEnvironments({commit, state}) {
        logger.error('[Store] Enter getEnvironments');
        try {
          if (!state.environments || !state.environments.length) {
            const response = await RestApiHandler.getEnvironments();
            const result = await handleRestApiResponse('getEnvironments', response);
            commit('setEnvironments', result && result.environments);
          }
        } catch (error) {
          logger.error('[Store] getEnvironments error: ', error);
        } finally {
          logger.error('[Store] Leave getEnvironments');
        }
      },

    socket_migration({commit}, migrationProgress) {
      commit('setMigrationProgress', migrationProgress);
    },
    socket_logging({commit}, data) {
      commit('handleServerLog', { type: data[0], msg: `[SERVER] ${data[1]}` });
    }
  },
  modules: {
  }
})

export default store;
