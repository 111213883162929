<template>
  <v-dialog persistent no-click-animation v-model="show" max-width="700px">
    <v-card>
      <v-card-title class="text-bold-24">{{ $t('factoryResetTool.title') }}</v-card-title>
      <v-card-text>
        <v-container>
        <div class='md-body-2'>{{ $t('factoryResetTool.installationInstructions') }}</div>
        <div class='md-body-1'>{{ $t('factoryResetTool.instructionsStep1') }}</div>
        <div class='md-body-1'>{{ $t('factoryResetTool.instructionsStep2') }}</div>
        <div class='md-body-1'>{{ $t('factoryResetTool.instructionsStep3') }}</div>
        <div class='md-body-1'>{{ $t('factoryResetTool.instructionsStep4') }}</div>
        <div class='md-body-1'>{{ $t('factoryResetTool.instructionsStep5') }}</div>
        <div class='md-body-1'>{{ $t('factoryResetTool.instructionsStep6') }}</div>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn text color="primary" @click="downloadNodeJs">{{ $t('factoryResetTool.downloadNodeJs') }}</v-btn>   
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="downloadFactoryResetTool">{{ $t('factoryResetTool.downloadFactoryResetTool') }}</v-btn>  
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="closeDialog">{{ $t('common.close') }}</v-btn>  
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import * as RestApiHandler from '../common/restApiHandler';
  export default {
    name: 'FactoryResetToolDialog',
    props: {
      value: Boolean
    },
    data: () => ({
      files: []
    }),
  computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('factory-reset', value)
        }
      },
    },
    methods: {
      closeDialog() {
        this.$emit('close-dialog');
      },

      downloadNodeJs() {
        window.open("https://nodejs.org/en/download/", "_blank");
      },

      async downloadFactoryResetTool() {
        const result = await RestApiHandler.downloadFactoryResetTool();
        if (result.status === 200) {
          const blob = new Blob([await result.blob()], { type: 'application/zip' });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = "factory-reset-tool.zip";
          link.click();
          URL.revokeObjectURL(link.href);
        } else {
          this.$log.error('[downloadFactoryResetTool] error: ', result.err);
        }
      }

    }
  }
</script>
