var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{style:(_vm.cssVariables)},[_c('v-col',[_c('v-data-table',{staticClass:"data-table full-width",attrs:{"headers":_vm.computedHeaders,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalElements,"loading":_vm.loading,"no-data-text":_vm.$t('common.noDataAvailable'),"footer-props":{
          disablePagination: _vm.loading,
          itemsPerPageOptions: [10, 20, 50, 100],
          showFirstLastPage: true,
          firstIcon: 'first_page',
          prevIcon: 'chevron_left',
          nextIcon: 'chevron_right',
          lastIcon: 'last_page',
          itemsPerPageText: _vm.$t('common.rowsPerPage')
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:`header.name`,fn:function({ header }){return [_c('v-text-field',{attrs:{"value":_vm.name,"label":_vm.$t(header.text),"append-icon":"search","clear-icon":"close","single-line":"","hide-details":"","flat":"","solo":"","clearable":"","placeholder":_vm.$t(header.text)},on:{"change":$event => _vm.name = $event,"click:clear":$event => _vm.name = ''}})]}},{key:`header.id`,fn:function({ header }){return [_c('v-text-field',{attrs:{"value":_vm.id,"label":_vm.$t(header.text),"append-icon":"search","clear-icon":"close","single-line":"","hide-details":"","flat":"","solo":"","clearable":"","placeholder":_vm.$t(header.text)},on:{"change":$event => _vm.id = $event,"click:clear":$event => _vm.id = ''}})]}},{key:`header.source`,fn:function({ header }){return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:`header.ip`,fn:function({ header }){return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:`header.port`,fn:function({ header }){return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:`header.protocol`,fn:function({ header }){return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:`header.trunkType`,fn:function({ header }){return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:`header.trunkAssignedUsers`,fn:function({ header }){return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}}],null,true)})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }