<template>
  <v-container fluid class="actions-container">

  <template>
  <migrate-dialog class="lvl2-elevation" id='confirm-migrate-dialog'
    :dialogTitle="migrateDialogTitle"
    v-model="showMigrateDialog"
    :errorMessage="dialogErrorMessage"
    @close-dialog="closeMigrate"
    @confirm-dialog="migrate"/>
  </template>

  <div class='md-layout md-alignment-center-right md-caption' id='umt-actions' v-click-outside="resetMessages">
    <md-dialog-confirm
      :md-active.sync="statusMessage.dialog"
      :md-title="statusMessage.title"
      :md-content="statusMessage.dialogText"
      @md-confirm="onConfirmErrorMessage"/>

    <div v-if='showError' class='md-subheading error-message'>{{statusMessage.text}}</div>
     
    <div v-if='showStatus' class='md-layout md-subheading status-message'>
      <div class="md-layout-item">
        <span id="statusMessage">{{statusMessage.text}} </span>
        <span v-if="migrationProgress.totalTransactions && showProgress">{{migrationProgessText}}</span>
      </div>
      <span class="space-10">
        <md-progress-spinner v-if="showProgress && !migrationProgress.totalTransactions" :md-diameter=25 :md-stroke="3" 
          md-mode="indeterminate"></md-progress-spinner>
        <md-progress-spinner v-if="showProgress && migrationProgress.totalTransactions" :md-diameter=25 :md-stroke="3" 
          md-mode="determinate" :md-value="migrationProgress.currentTransaction * 100 / migrationProgress.totalTransactions"></md-progress-spinner>
      </span>
      
    </div>
    <v-btn depressed id="migrateButton" class="no-text-transform" color="primary" square width="140"
      v-if='!isStandalone()'
      :disabled="!enableMigration"
      @click="confirmMigration()">
      {{ $t('configureMigrate.migrate') }}
    </v-btn>
    <v-btn depressed id="downloadButton" class="no-text-transform" color="primary" square width="140"
      v-if='isStandalone()'
      :disabled="!enableDownload"
      @click="getUnifyPhoneData()">
      {{ $t('migrationReport.download') }}
    </v-btn>
  </div>

  </v-container>
</template>

<script>
  import FileDownloader from '../common/fileDownloader';
  import * as RestApiHandler from '../common/restApiHandler';
  import store from '../store';
  import { mapState } from 'vuex';
  import { logger } from 'vue-logger';
  import MigrateDialog from "./migrateDialog";


  export default {
    name: 'ActionMenu',
    props: ['customerId'],
    components: {
      MigrateDialog
    },
    data: () => ({
      pbxType: '',
      statusMessage: {
        dialog: false,
        error: false,
        text: ''
      },
      migrateDialogTitle: '',
      dialogErrorMessage: '',
      showMigrateDialog: false,
    }),
    computed: {
      ...mapState(['migrationEnabled', 'migrationProgress', 'sendWelcomeEmail', 'loggedUser', 'downloadEnabled']),
      isDisabled() {
        return !this.customerId;
      },
      isfileUploadVisible() {
        return this.fileUploadVisible;
      },
      showError() {
        return this.statusMessage && this.statusMessage.text && this.statusMessage.error && !this.statusMessage.dialog;
      },
      showStatus() {
        return this.statusMessage && this.statusMessage.text && !this.statusMessage.error;
      },
      showProgress() {
        return this.statusMessage && this.statusMessage.showProgress;
      },
      enableMigration() {
        return this.migrationEnabled;
      },
      enableDownload() {
        return this.downloadEnabled;
      },
      migrationProgessText() {
        return this.migrationProgress.totalTransactions && '(' + this.migrationProgress.currentTransaction + '/' + this.migrationProgress.totalTransactions + ')';
      }
    },
    methods: {
      isStandalone () {
        return this.loggedUser && this.loggedUser.user && this.loggedUser.user.isStandalone === true;
      },
      onConfirmErrorMessage() {
        if (this.statusMessage.unauthorized) {
          RestApiHandler.logout();
        }
      },
      setUnauthorizedDialog(title) {
        this.statusMessage = {
          unauthorized: true,
          dialog: true,
          error: true,
          dialogText: 'Your user is not authorized to execute the operation.<br>You need to login again.',
          text: 'You need to login again',
          title
        };
      },
      resetMessages() {
        if (!this.showProgress) {
          this.statusMessage.text = '';
        this.fileUploadVisible = false;
        }

      },

      async download(pbxType, contentType) {
        try {
          logger.debug(`[ActionMenu] download for ${pbxType}, customer ${this.customerId}`)
          this.statusMessage = {text: `Downloading ${pbxType} ${contentType ? contentType : 'spreadsheet'}`, showProgress: true};
          const fileDownloader = new FileDownloader();
          const result = await fileDownloader.download(pbxType, this.customerId, contentType);
          if (result.status === 200) {
            this.statusMessage = {text: this.$t('common.downloadFinished')}
          } else {
            this.statusMessage = {text: result.statusText, error: true};
          }
        } catch(err) {
          logger.error(`[ActionMenu] download for ${pbxType}, customer ${this.customerId} error:`, err);
        }
      },

      confirmMigration() {
        this.showMigrateDialog = true;
        this.migrateDialogTitle = this.$t('configureMigrate.confirmMigrateTitle');
        this.dialogErrorMessage = '';
      },

      closeMigrate() {
        this.showMigrateDialog = false;
      },

      async migrate() {
        logger.debug(`[ActionMenu] migrate customer ${this.customerId}`)
        store.commit('resetReports');
        this.closeMigrate();
        const sendMailParam = this.sendWelcomeEmail;
        try {
          store.commit('setMigrationEnabled', false);
          this.statusMessage = {text: this.$t('customerView.migrationProcessing'), showProgress: true };
          const result = await RestApiHandler.migrate(this.customerId, sendMailParam);
          store.commit('setMigrationProgress', { currentTransaction: 0, totalTransactions: 0 });
          if (result.status === 200) {
            this.statusMessage = {text: this.$t('customerView.migrationFinished')}
            const response = await result.json();
            if (response && response.report) {
              response.customerId = this.customerId;
              store.commit('setMigrationReport', response && response.report);
            }
            if (response && response.customers) {
              store.commit('setCustomers', response.customers);
            }
          } else if (result.status === 401) {
            this.setUnauthorizedDialog('Migrate error:');
            RestApiHandler.clearRefreshToken();
          } else {
            this.statusMessage = {text: result.statusText, error: true};
          }
          store.commit('setSendWelcomeEmail', false);
        } catch(err) {
          logger.error(`[ActionMenu] migrate customer ${this.customerId} error:`, err);
          this.statusMessage = {text: err, error: true};
        }
      },
      async getUnifyPhoneData() {
        try {
          this.$log.debug(`[actionMenu] download for NGTC, customer ${this.customerId}`)
          this.statusMessage = {text: `Downloading Unify Phone data`, showProgress: true};
          const fileDownloader = new FileDownloader();
          const result = await fileDownloader.download('NGTC', this.customerId, 'UnifyPhoneStandAlone');
          if (result.status === 200) {
            this.statusMessage = {text: this.$t('common.downloadFinished')}
          } else {
            this.statusMessage = {text: result.statusText, error: true};
          }
        } catch(err) {
          this.$log.error(`[actionMenu] download for NGTC, customer ${this.customerId} error:`, err);
        }
      }
    }
  }
</script>

<style scoped>
  #umt-actions > .md-subheading {
    text-align: right;
    margin-left: 1.5rem;
    margin-right: 1.5rem;

  }
  #umt-actions > .error-message {
    color:red;
  }
  #umt-actions > .status-message {
    color: #257CAE;
  }
  .space-10 {
    margin-left: 10px !important;
  }

</style>
