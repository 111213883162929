import { logger } from 'vue-logger';
import ErrorHandler from './errorHandler';
import router from '../router';
import store from '../store';

const SESSION_TOKEN = '_token';
const _apiLocation = 'https://backend.flip.unifyoffice.com';
const _apiUrl = `${_apiLocation}/api/v1`;
const _logonApiUrl = `${_apiUrl}/auth/logon`;
const _uploadUrl = `${_apiLocation}/fileupload`;
const _downloadFactoryResetToolUrl = `${_apiLocation}/download/frt`;
const _regionalsUrl = `${_apiUrl}/regionals`;
const _devicesUrl = `${_apiUrl}/devices`;
const _environmentsUrl = `${_apiUrl}/environments`;
const _refreshToken =`${_apiUrl}/auth/refresh_token`;
const _logout =`${_apiUrl}/auth/logout`;

let loggedOnUser;
let timerID;

function getLoggedOnUser() {
    return loggedOnUser;
}

function clearRefreshTokenTimeout() {
    if(timerID) {
        clearTimeout(timerID);
        timerID = null;
    }
}

async function refresh_token() {
    const response = await fetch(_refreshToken, {
        method: "GET",
        cache: "no-cache",
        credentials: "include",
        headers: {
        Accept: "application/json"
        },
        redirect: "follow",
        referrerPolicy: "no-referrer"
    });

    const jsonResp = await response.json();
    logger.debug(`[RestApiHandler] logon ${JSON.stringify(jsonResp)}`);
    if (jsonResp && jsonResp.redirectToLogin) {
        logout(jsonResp && jsonResp.redirectToLogin);
    }
    if (jsonResp.session) {
    setSessionToken(jsonResp.session); // store the new token
    let expiresInHalfHour;
        expiresInHalfHour = jsonResp.session_expirey / 2;
        scheduleRefreshToken(expiresInHalfHour); // restart countdown to next refresh
    }
}


function scheduleRefreshToken(expirationInSeconds) {
    const expirationInMilliseconds = expirationInSeconds * 1000;
    clearRefreshTokenTimeout();
    
    timerID = setTimeout( async function() {
        await refresh_token();
        
        
    }, expirationInMilliseconds);
}

async function logon(environment, sessionToken) {
    logger.debug('[RestApiHandler] logon', sessionToken ? ' - sessionToken' : '');
    try {
        sessionToken = sessionToken || getSessionToken();
        if(!sessionToken) { //if no jwt is in memory we try silent refresh
            await refresh_token();
            sessionToken = sessionToken || getSessionToken();
        }
        const param = (environment) ? `?${environment ? `env=${environment}` : '' }` : '';
        const response = await restCall(`${_logonApiUrl}${param}` , {
            method: "GET",
            // mode: "no-cors",
            cache: "no-cache",
            credentials: "include",
            headers: {
              Accept: "application/json",
              'x-session-token': sessionToken || ''
            },
            redirect: "follow",
            referrerPolicy: "no-referrer"
        });
        const result = await response.json();
        let expiresInHalfHour;
        if (result.sessionToken) {
            expiresInHalfHour = result.sessionToken.expires_in / 2;
            scheduleRefreshToken(expiresInHalfHour);
        }
        if (result && result.account) {
            loggedOnUser = result;
        }
        return result;
    } catch (err) {
        logger.debug('[RestApiHandler] logon error: ', err);
        return {};
    }
}


async function clearRefreshToken() {
    const response = await fetch(_logout, {
        method: "GET",
        cache: "no-cache",
        credentials: "include",
        headers: {
          Accept: "application/json"
        },
        redirect: "follow",
        referrerPolicy: "no-referrer"
    });
    const jsonObj = await response.json();
    logger.debug(`[RestApiHandler] clearRefreshToken: ${JSON.stringify(jsonObj)}`);
}
function logout(redirectUri) {
    logger.debug('[RestApiHandler] logout');
    window.sessionStorage.setItem(SESSION_TOKEN, '');
    clearRefreshToken();
    store.commit('setLoggedOnUser', {});
    if (redirectUri && loggedOnUser) {
        window.location.assign(redirectUri);
    } else {
        router.push('/logon');
    }
}

function isLoggedIn() {
    return !!window.sessionStorage.getItem(SESSION_TOKEN);
}

function getSessionToken() {
    return window.sessionStorage.getItem(SESSION_TOKEN);
}

function setSessionToken(sessionToken) {
    logger.debug('[RestApiHandler] setSessionToken: ', sessionToken);
    return window.sessionStorage.setItem(SESSION_TOKEN, sessionToken);
}

async function save(content, customerId) {
    logger.debug(`[RestApiHandler] save ${customerId}`);
    try {
        const response = await restCall(`${_apiUrl}/save/${customerId}` , {
            method: "POST",
            body: content,
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getSessionToken()}`,
                'Content-Type': "application/json" 
            },
            redirect: "follow",
            referrerPolicy: "no-referrer"
         }, true);
         return response;
    } catch (err) {
        logger.debug('[RestApiHandler] save error: ', err);
        return err;
    }
}

async function upload(formData, pbxType, customerId) {
    logger.debug(`[RestApiHandler] upload ${pbxType} for customer ${customerId}`);
    try {
        const response = await restCall(`${_uploadUrl}/${pbxType}/${customerId}` , {
            method: "POST",
            body: formData,
            cache: "no-cache",
            credentials: "include",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getSessionToken()}`
            },
            redirect: "follow",
            referrerPolicy: "no-referrer"
         });
         return response;
    } catch (err) {
        logger.debug('[RestApiHandler] upload error: ', err);
        return err;
    }
}

async function download(pbxType, customerId, pbxId) {
    logger.debug(`[RestApiHandler] download pbx: ${pbxType}, customer: ${customerId}${pbxId ? ', pbxIdentifier: ' + pbxId : ''}`);
    try {
        let url = `${_apiUrl}/download/${pbxType}/customer/${customerId}`;
        pbxId && (url += `?pbxId=${pbxId}`);
        const response = await restCall(url, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${getSessionToken()}`
            }
         }, true);
         return response;
    } catch (err) {
        logger.debug('[RestApiHandler] download error: ', err);
        return err;
    }
}

async function migrate(customerId, sendWelcomeEmail) {
    logger.debug(`[RestApiHandler] migrate ${customerId}. sendWelcomeEmail[${sendWelcomeEmail}]`);
    try {
        let url = `${_apiUrl}/migrate/customer/${customerId}`;
        url += `?sendWelcomeEmail=${sendWelcomeEmail}`;
        const response = await restCall(url, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${getSessionToken()}`
            },
         });
         return response;
    } catch (err) {
        logger.debug('[RestApiHandler] migrate error: ', err);
        return err;
    }
}

async function resetConfig(customerId) {
    logger.debug(`[RestApiHandler] reset ${customerId}`);
    try {
        const response = await restCall(`${_apiUrl}/resetConfig/customer/${customerId}` , {
            method: "GET",
            headers: {
                Authorization: `Bearer ${getSessionToken()}`
            },
         });
         return response;
    } catch (err) {
        logger.debug('[RestApiHandler] reset error: ', err);
        return err;
    }
}

async function changeCustomerSettings(content, customerId) {
    logger.debug('[RestApiHandler] Change User Settings');
    try {
        const response = await restCall(`${_apiUrl}/customer/settings/${customerId}` , {
            method: "POST",
            body: content,
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getSessionToken()}`,
                'Content-Type': "application/json" 
            },
            redirect: "follow",
            referrerPolicy: "no-referrer"
         }, true);
         return response;
    } catch (err) {
        logger.debug('[RestApiHandler] Change Customer Settings error: ', err);
        return err;
    }
}

async function importPbx(pbxType, customerId) {
    logger.debug(`[RestApiHandler] importPbx ${pbxType} of customer ${customerId}`);
    try {
        const response = await restCall(`${_apiUrl}/import/${pbxType}/${customerId}` , {
            method: "GET",
            headers: {
                Authorization: `Bearer ${getSessionToken()}`
            },
         });
         return response;
    } catch (err) {
        logger.debug('[RestApiHandler] importPbx error: ', err);
        return err;
    }
}

async function fetchRequest(method, url) {
    logger.debug(`[RestApiHandler] Enter fetchRequest method: ${method}, url: ${url}`);
    try {
        const response = await fetch(url , {
            method: method,
            headers: {
                Authorization: `Bearer ${getSessionToken()}`
            }
         });
         if (response.status === 403 || response.status === 401) {

            const jsonResp = await response.json();
            const redirectUri = jsonResp && jsonResp.redirectToLogin;
            logout(redirectUri);
        }
        return response;
    } catch (err) {
        logger.debug('[RestApiHandler] fetchRequest error: ', err);
        throw err;
    } finally {
        logger.debug(`[RestApiHandler] Exit fetchRequest method: ${method}, url: ${url}`);
    }
}

async function deletePbx(customerId, pbxType, pbxId) {
    logger.debug(`[RestApiHandler] deletePbx ${pbxType}[${pbxId}] of customer ${customerId}`);
    return fetchRequest("DELETE", `${_apiUrl}/pbx/${customerId}/${pbxType}/${pbxId}`);
}

async function deleteCustomer(customerId) {
    logger.debug(`[RestApiHandler] deleteCustomer ${customerId}`);
    return fetchRequest("DELETE", `${_apiUrl}/customer/${customerId}`);
}

async function getCustomer(customerId) {
    logger.debug(`[RestApiHandler] getCustomer ${customerId ? customerId : ''}`);
    try {
        const response = await restCall(`${_apiUrl}/customer/${customerId}` , {
            method: "GET",
            headers: {
                Authorization: `Bearer ${getSessionToken()}`
            },
         }, true);
         return response;
    } catch (err) {
        logger.debug('[RestApiHandler] upload error: ', err);
        return err;
    }
}

async function getCustomerItem(item, customerId, query) {
    try {
        const params = (query) ? `?${query ? `q=${query}&` : '' }` : '';
        const response = await restCall(`${_apiUrl}/customer/${customerId ? customerId : ''}/${item}${params}` , {
            method: "GET",
            headers: {
                Authorization: `Bearer ${getSessionToken()}`
            },
         }, true);
         return response;
    } catch (err) {
        logger.debug(`[RestApiHandler] getCustomerItem ${item} error:`, err);
        return err;
    }
}

async function getBusinessGroups(customerId, query) {
    logger.debug(`[RestApiHandler] getBusinessGroups ${customerId ? customerId : ''}`);
    return getCustomerItem('businessgroup', customerId, query);
}

async function getTrunks(customerId, query) {
    logger.debug(`[RestApiHandler] getTrunks ${customerId ? customerId : ''}`);
    return getCustomerItem('trunk', customerId, query);
}

async function getFeatures(customerId, query) {
    logger.debug(`[RestApiHandler] getFeatures ${customerId ? customerId : ''}`);
    return getCustomerItem('feature', customerId, query);
}

async function getSites(customerId, query) {
    logger.debug(`[RestApiHandler] getSites ${customerId ? customerId : ''}`);
    return getCustomerItem('site', customerId, query);
}

async function getUsers(customerId, query) {
    logger.debug(`[RestApiHandler] getCustomerUser ${customerId ? customerId : ''}`);
    return getCustomerItem('user', customerId, query);
}

async function getPbxes(customerId, query) {
    logger.debug(`[RestApiHandler] getPbxes ${customerId ? customerId : ''}`);
    return getCustomerItem('pbx', customerId, query);
}

async function downloadFactoryResetTool() {
    logger.debug('[RestApiHandler] downloadFactoryResetTool');
    try {
        const response = await restCall(_downloadFactoryResetToolUrl, {
            method: "GET",
            cache: "no-cache",
            headers: {
              Accept: "application/zip"
            }
        });
        return response;
    } catch (err) {
        logger.debug('[RestApiHandler] downloadFactoryResetTool error: ', err);
        return err;
    }
}

async function getRegionals() {
    logger.debug('[RestApiHandler] getRegionals');
    try {
        const response = await restCall(_regionalsUrl, {
            method: "GET",
            cache: "no-cache",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${getSessionToken()}`
            }
        });
        return response;
    } catch (err) {
        logger.debug('[RestApiHandler] getRegionals error: ', err);
        return false;
    }
}

async function getDevices() {
    logger.debug('[RestApiHandler] getDevices');
    try {
        const response = await restCall(_devicesUrl, {
            method: "GET",
            cache: "no-cache",
            headers: {
              Accept: "application/json"
            }
        });
        return response;
    } catch (err) {
        logger.debug('[RestApiHandler] getDevices error: ', err);
        return err;
    }
}

async function getEnvironments() {
    logger.debug('[RestApiHandler] getEnvironments');
    try {
        const response = await restCall(_environmentsUrl, {
            method: "GET",
            cache: "no-cache",
            headers: {
              Accept: "application/json"
            }
        });
        return response;
    } catch (err) {
        logger.debug('[RestApiHandler] getEnvironments error: ', err);
        return err;
    }
}

async function restCall(url,  options, showError) {
    const response = await fetch(url, options).catch(err => {
        return new Response(JSON.stringify({
            code: 400,
            message: `network Error: ${err}`
        }));
    });
    if (response.ok) {
        return response;
    } else {
        const responseText = await response.text();
        let message = responseText || 'Call to backend failed';
        let responseJson;
        try {
            responseJson = JSON.parse(responseText);
            if (responseJson && responseJson.message) {
                message = responseJson.message;
            } else if (responseJson && responseJson.statusText) {
                message = responseJson.statusText;
            }
        } catch(err) {
            logger.debug('[RestApiHandler] restCall error handling JSON data');
        }
        if (response.status === 403 || response.status === 401) {
            const redirectUri = responseJson && (responseJson.redirectToLogin || (responseJson.message && responseJson.message.redirectToLogin) || responseJson.redirectUrl);
            logout(redirectUri);
            message = 'Session has expired';
        }
        if (showError) {
            ErrorHandler.onError({
                code: response.status,
                title: response.statusText,
                message: message
            });
        }
        response.message = message;
        return Promise.reject(response);
    }
}

export {
    changeCustomerSettings,
    resetConfig,
    download,
    deleteCustomer,
    deletePbx,
    getBusinessGroups,
    getTrunks,
    getFeatures,
    getCustomer,
    getLoggedOnUser,
    getPbxes,
    getSessionToken,
    getSites,
    getUsers,
    importPbx,
    isLoggedIn,
    logon,
    logout,
    clearRefreshToken,
    migrate,
    setSessionToken,
    upload,
    save,
    downloadFactoryResetTool,
    getRegionals,
    getDevices,
    getEnvironments
};
