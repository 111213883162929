<template>
  <v-row :style="cssVariables">
    <v-col>
      <v-data-table
        :headers="computedHeaders"
        :items="items"
        :options.sync="options"
        :server-items-length="totalElements"
        :loading="loading"
        :no-data-text = "$t('common.noDataAvailable')"
        :footer-props="{
            disablePagination: loading,
            itemsPerPageOptions: [10, 20, 50, 100],
            showFirstLastPage: true,
            firstIcon: 'first_page',
            prevIcon: 'chevron_left',
            nextIcon: 'chevron_right',
            lastIcon: 'last_page',
            itemsPerPageText: $t('common.rowsPerPage')
        }"
        class="data-table full-width">
        <template v-slot:[`header.name`]="{ header }">
          <v-text-field
            :value="name"
            @change="$event => name = $event"
            @click:clear="$event => name = ''"
            :label="$t(header.text)"
            append-icon="search"
            clear-icon="close"
            single-line hide-details
            flat solo clearable
            :placeholder="$t(header.text)"
          ></v-text-field>
        </template>
        <template v-slot:[`header.id`]="{ header }">
          <v-text-field
            :value="id"
            @change="$event => id = $event"
            @click:clear="$event => id = ''"
            :label="$t(header.text)"
            append-icon="search"
            clear-icon="close"
            single-line hide-details
            flat solo clearable
            :placeholder="$t(header.text)"
          ></v-text-field>
        </template>
        <template v-slot:[`header.source`]="{ header }">
          {{$t(header.text)}}
        </template>
        <template v-slot:[`header.ip`]="{ header }">
          {{$t(header.text)}}
        </template>
        <template v-slot:[`header.port`]="{ header }">
          {{$t(header.text)}}
        </template>
        <template v-slot:[`header.protocol`]="{ header }">
          {{$t(header.text)}}
        </template>
        <template v-slot:[`header.trunkType`]="{ header }">
          {{$t(header.text)}}
        </template>
        <template v-slot:[`header.trunkAssignedUsers`]="{ header }">
          {{$t(header.text)}}
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
  import { mapState } from 'vuex';
  import store from '../store';
  import Utils from '../common/utils';

  export default {
    name: 'CustomerTrunks',
    data() {
      return {
        loading: false,
        /**
         * The width of the headers has been calculated based on their label and potential content
         */
        headers:[
          {
            text: 'common.name', value: 'name', align: 'start', sortable: false, width:"150",
            class: "data-table-header customer-trunks-headers", cellClass: "data-table-cell", hideForVideo: false
          },
          { 
            text: 'configureMigrate.id', value: 'id', align: 'start', sortable: false, width:"150",
            class: "data-table-header customer-trunks-headers", cellClass: "data-table-cell", hideForVideo: false
          },
          { 
            text: 'configureMigrate.source', value: 'source', align: 'start', sortable: false, width:"120",
            class: "data-table-header customer-trunks-headers", cellClass: "data-table-cell", hideForVideo: false
          },
          { 
            text: 'configureMigrate.ipAddress', value: 'ip', align: 'start', sortable: false, width:"150",
            class: "data-table-header customer-trunks-headers", cellClass: "data-table-cell", hideForVideo: false
          },
          { 
            text: 'configureMigrate.port', value: 'port', align: 'start', sortable: false, width:"150",
            class: "data-table-header customer-trunks-headers", cellClass: "data-table-cell", hideForVideo: false
          },
          { 
            text: 'configureMigrate.protocol', value: 'protocol', align: 'start', sortable: false, width:"150",
            class: "data-table-header customer-trunks-headers", cellClass: "data-table-cell", hideForVideo: false
          },
          { 
            text: 'configureMigrate.trunkType', value: 'trunkType', align: 'start', sortable: false, width:"150",
            class: "data-table-header customer-trunks-headers", cellClass: "data-table-cell", hideForVideo: false
          },
          { 
            text: 'configureMigrate.users', value: 'trunkAssignedUsers', align: 'start', sortable: false, width:"150",
            class: "data-table-header customer-trunks-headers", cellClass: "data-table-cell", hideForVideo: false
          }
        ],
        statusTypes: Utils.statusTypes,
        filterTypes: Utils.filterTypes,
      }
    },
    computed: {
      ...mapState(['customer', 'trunks', 'loggedUser']),
      cssVariables () {
        return Utils.calculateHeaderWidth(this.headers.length);
      },
      totalElements() {
        return this.trunks && this.trunks.paging && this.trunks.paging.totalElements || 0;
      },
      computedHeaders() {
        return this.headers.filter(header => header.hideForVideo === false || this.isUnifyVideoAccount() === false);
      },
      items() {
        const { page , itemsPerPage } = this.options;
        // This only happens when a new pbx is imported/deleted or at logon. Records.length will be at max as dictated
        // by the server and the page will be 1.
        if (this.trunks && this.trunks.records && this.trunks.records.length > itemsPerPage && page === 1) {
            store.commit('reduceRecords', { type: 'trunks', number: itemsPerPage })
        }
        return this.trunks && this.trunks.records && this.trunks.records.map(item => {
          return {
            id: item.trunkId || '',
            name: item.name || item.uuid,
            source: item.sourcesText,
            ip: item.ip,
            port: item.port,
            protocol: item.protocol && item.protocol.toUpperCase() || '',
            trunkType: this.getTrunkType(item.trunkType),
            trunkAssignedUsers: item.trunkAssignedUsers
          }
        });
      },
      name: {
        get: function () {
          return this.trunks && this.trunks.search && this.trunks.search.name;
        },
        set: function (value) {
          this.search.name = value;
          this.pageReset();
        }
      },
      id: {
        get: function () {
          return this.trunks && this.trunks.search && this.trunks.search.id;
        },
        set: function (value) {
          this.search.id = value;
          this.pageReset();
        }
      },
      search() {
        return {
            name: this.name,
            id: this.id
        }
      },
      options: {
        get: function () {
          return this.trunks.paging;
        },
        set: function (value) {
          this.fetchData(value, this.search);
        }
      }
    },
    methods: {
      isUnifyVideoAccount () {
        return this.loggedUser && this.loggedUser.user && this.loggedUser.user.isUnifyVideo === true;
      },
      pageReset() {
        this.options.page = 1;
        this.fetchData(this.options, this.search);
      },
      detectQueryChange(options, search) {
        const { page, itemsPerPage } = options;
        const { name, id } = search;
        const noChange = page && itemsPerPage && this.trunks && this.trunks.paging && this.trunks.search
                        && this.trunks.paging.page === page && this.trunks.paging.itemsPerPage === itemsPerPage
                        && this.trunks.search.name === name && this.trunks.search.id === id;
        return !noChange
      },
      getQuery(options, search) {
        const { page, itemsPerPage } = options
        const pagingParameters = { page, itemsPerPage }
        return Utils.encodeBase64Url(JSON.stringify({ options: pagingParameters, search }));
      },
      fetchData: async function(options, search) {
        if (this.loading) { return; }
        try {
          this.loading = true;
          if (this.detectQueryChange(options,search)) {
            await store.dispatch('getTrunks', {customerId: this.customer.uuid, query: this.getQuery(options, search)});
          }
        } finally {
          this.loading = false;
        }
      },
      getTrunkType(type) {
        return Utils.getPbxFriendlyName(type, this.isUnifyVideoAccount());
      }
    }
  }
</script>
<style>
  .customer-trunks-headers {
      width: var(--width) !important;
  }
</style>
