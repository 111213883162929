<template>
  <v-container fluid class="umt-container">
    <v-row no-gutters class="fill-height">
      <v-col class="profile-col">
        <app-logo/>
        <uo-account :companyName='companyName' :phoneNumber='mainNumber'/>
      </v-col>
      <v-col class="new-col">
        <v-row no-gutters class="new-header">
          <p class="font-weight-bold">{{ $t("app.new") }}</p>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-row v-for="release in getCurrentRnData()" :key="release.version" no-gutters class="new-version flex-column">
              {{ release.version }}
              <ul class="version-record">
                <img v-if="release.picture" class="release-picture" :src="require(`../assets/rn/${release.picture}`)"/>
                <li v-for="record in release.records" :key="record.title">
                  {{ record.title }}
                  <div class="version-description">{{ record.description }}</div>
                </li>
              </ul>
            </v-row>
            <v-btn x-small @click="toggleShowAllRn" class="toggle-all-button">{{ showAllRn ? $t("common.hideOld") : $t("common.showOld") }}</v-btn>
            <div v-if="showAllRn">
              <v-row v-for="release in getOlderRnData()" :key="release.version" no-gutters class="new-version flex-column">
                {{ release.version }}
                <ul class="version-record">
                  <img v-if="release.picture" class="release-picture" :src="require(`../assets/rn/${release.picture}`)"/>
                  <li v-for="record in release.records" :key="record.title">
                    {{ record.title }}
                    <div class="version-description">{{ record.description }}</div>
                  </li>
                </ul>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters class="close-button flex-column">
          <v-col>
            <v-btn class="button-text-18 no-text-transform"
              depressed dark color="primary"
              @click="onClickClose">
              {{ $t("common.close") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapState } from 'vuex';
  import AppLogo from "../components/appLogo";
  import UoAccount from "../components/uoAccount";
  import router from '../router';
  import getBrowserLocale from "../util/i18n/get-browser-locale"
  import rn from "../common/releaseNotes"

  export default {
    name: 'About',
    components: {
      AppLogo,
      UoAccount
    },
    data() {
      return {
        legalContent: '',
        showDialog: false,
        showAllRn: false,
        language: 'en'
      }
    },
    mounted() {
      const browserLocale = getBrowserLocale({ countryCodeOnly: true })
      if (Object.keys(rn).includes(browserLocale)) {
        this.language = browserLocale;
      }
    },
    methods: {
      onClickClose() {
          this.$log.debug('[UOMTApp] Route to Home');
          router.push('/');
      },
      toggleShowAllRn() {
          this.showAllRn = !this.showAllRn
      },
      getCurrentRnData() {
        return rn[this.language].length && [rn[this.language][0]] || [];
      },
      getOlderRnData() {
        const data = [];
        for (let i = 1; i < rn[this.language].length; i++) {
          data.push(rn[this.language][i])
        }
        return data;
      }
    },
    computed: {
      ...mapState(['loggedUser', 'appNameSplitted']),
      companyName() {
        return this.loggedUser && this.loggedUser.account && this.loggedUser.account.company;
      },
      mainNumber() {
        return this.loggedUser && this.loggedUser.user && this.loggedUser.user.customers && this.loggedUser.user.customers.length && this.loggedUser.user.customers[0].mainNumber;
      },
      appVersion() {
        return `Version ${process.env.VUE_APP_VERSION}`;
      },
      appNameObject() {
        return this.appNameSplitted;
      }
    }
  }
</script>

<style scoped  lang="scss">
  .new-col.col {
    padding: 4rem 4rem;
    background-color: #F3F3F3;
    color: #0D65A3
  }
  .new-header {
    font-size: 24px;
    padding: 0 3rem;
    margin-bottom: 1rem;
  }
  .new-version {
    font: normal normal bold 16px/19px Arial;
    padding: 0.5rem 3rem;
    margin-bottom: 1rem;
  }
  .version-record {
    color: #263038;
    margin: 1rem 0rem 0rem 0rem;
    padding: 0px;
  }
  .version-record li {
    list-style-type: none;
    counter-increment: item;
    margin-bottom: 1rem;
  }
  .version-record li:before {
    content: counter(item);
    margin-right: 5px;
    font-size: 80%;
    background-color: #0D65A3;
    color: #ffffff;
    font-weight: bold;
    padding: 3px 8px;
    border-radius: 3px;
  }
  .version-description {
    font-weight: normal;
    margin-top: 0.5rem;
  }
  .release-picture {
    float: right;
    max-width: 250px;
    border: 5px solid rgba(243, 243, 243, 1.0);
    border-radius: 20px;
  }
  .toggle-all-button {
    margin-bottom: 10px;
  }
</style>
