<template>
  <v-row :style="cssVariables">
    <v-col>
      <v-data-table
        :headers="computedHeaders"
        :items="items"
        :options.sync="options"
        :server-items-length="totalElements"
        :loading="loading"
        :no-data-text = "$t('common.noDataAvailable')"
        :footer-props="{
            disablePagination: loading,
            itemsPerPageOptions: [10, 20, 50, 100],
            showFirstLastPage: true,
            firstIcon: 'first_page',
            prevIcon: 'chevron_left',
            nextIcon: 'chevron_right',
            lastIcon: 'last_page',
            itemsPerPageText: $t('common.rowsPerPage')
        }"
        class="data-table full-width">
        <template v-slot:[`header.name`]="{ header }">
            <v-text-field
                :value="name"
                @change="$event => name = $event"
                @click:clear="$event => name = ''"
                :label="$t(header.text)"
                append-icon="search"
                clear-icon="close"
                single-line hide-details
                flat solo clearable
                :placeholder="$t(header.text)"
            ></v-text-field>
        </template>
        <template v-slot:[`header.source`]="{ header }">
            <v-text-field
                :value="source"
                @change="$event => source = $event"
                @click:clear="$event => source = ''"
                :label="$t(header.text)"
                append-icon="search"
                clear-icon="close"
                single-line hide-details
                flat solo clearable
                :placeholder="$t(header.text)"
            ></v-text-field>
        </template>
        <template v-slot:[`header.target`]="{ header }">
            <v-combobox
              v-model="target"
              :items="!target ? filterTypes : undefined"
              item-text="filterLabel"
              item-value="filterValue"
              :label="$t(header.text)"
              hide-no-data hide-details hide-selected
              append-icon="search"
              clear-icon="close"
              solo single-line flat clearable
              @change="$event => target = $event"
              @click:clear="$event => target = ''"
              :placeholder="$t(header.text)"
            ></v-combobox>
        </template>
        <template v-slot:[`header.removal`]="{ header }">
            <v-combobox
              v-model="removal"
              :items="!removal ? filterTypes : undefined"
              item-text="filterLabel"
              item-value="filterValue"
              :label="$t(header.text)"
              hide-no-data hide-details hide-selected
              append-icon="search"
              clear-icon="close"
              solo single-line flat clearable
              @change="$event => removal = $event"
              @click:clear="$event => removal = ''"
              :placeholder="$t(header.text)"
            ></v-combobox>
        </template>
        <template v-slot:[`header.siteName`]="{ header }">
            <v-text-field
                :value="siteName"
                @change="$event => siteName = $event"
                @click:clear="$event => siteName = ''"
                :label="$t(header.text)"
                append-icon="search"
                clear-icon="close"
                single-line hide-details
                flat solo clearable
                :placeholder="$t(header.text)"
            ></v-text-field>
        </template>
        <template v-slot:[`header.status`]="{ header }">
          <v-select
              :items="statusTypes"
              item-text="statusType"
              item-value="statusValue"
              @change="$event => status = $event"
              @click:clear="$event => status = ''"
              :label="$t(header.text)"
              append-icon="search"
              clear-icon="close"
              single-line hide-details
              flat solo clearable
              :placeholder="$t(header.text)"
              :menuProps="{ bottom: true, offsetY: true, maxHeight: 300 }"
          ></v-select>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip v-show="item.status" :class="getClass(item.status)"
             label
          > {{ $t(item.status && "status." + item.status.toLowerCase() || "status.none") }}
          </v-chip>
        </template>
        <template v-slot:[`header.primaryNumber`]="{ header }">
            <v-combobox
              v-model="number"
              :items="!number ? filterTypes : undefined"
              item-text="filterLabel"
              item-value="filterValue"
              :label="$t(header.text)"
              hide-no-data hide-details hide-selected
              append-icon="search"
              clear-icon="close"
              solo single-line flat clearable
              @change="$event => number = $event"
              @click:clear="$event => number = ''"
              :placeholder="$t(header.text)"
            ></v-combobox>
        </template>
        <template v-slot:[`header.digestInformation`]="{ header }">
          {{$t(header.text)}}
        </template>
        <template v-slot:[`header.email`]="{ header }">
            <v-combobox
              v-model="email"
              :items="!email ? filterTypes : undefined"
              item-text="filterLabel"
              item-value="filterValue"
              :label="$t(header.text)"
              hide-no-data hide-details hide-selected
              append-icon="search"
              clear-icon="close"
              solo single-line flat clearable
              @change="$event => email = $event"
              @click:clear="$event => email = ''"
              :placeholder="$t(header.text)"
            ></v-combobox>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
  import { mapState } from 'vuex';
  import store from '../store';
  import Utils from '../common/utils';

  export default {
    name: 'CustomerUsers',
    data() {
      return {
        loading: false,
        /**
         * The width of the headers has been calculated based on their label and potential content
         */
        headers:[
          { 
            text: 'common.name', value: 'name', align: 'start', sortable: false, width:"150",
            class: "data-table-header customer-users-headers", cellClass: "data-table-cell", hideForVideo: false, hideForOffice: false
          },
          { 
            text: 'configureMigrate.migrateFrom', value: 'source', align: 'start', sortable: false, width:"120",
            class: "data-table-header customer-users-headers", cellClass: "data-table-cell", hideForVideo: false, hideForOffice: false
          },
          { 
            text: 'configureMigrate.migrateTo', value: 'target', align: 'start', sortable: false, width:"120",
            class: "data-table-header customer-users-headers", cellClass: "data-table-cell", hideForVideo: false, hideForOffice: false
          },
          { 
            text: 'configureMigrate.status', value: 'status', align: 'start', sortable: false, width: "120",
            class: 'data-table-header customer-users-headers', cellClass: 'data-table-cell', hideForVideo: false, hideForOffice: false
          },
          { 
            text: 'configureMigrate.removeFrom', value: 'removal', align: 'start', sortable: false, width:"120",
            class: "data-table-header customer-users-headers", cellClass: "data-table-cell", hideForVideo: false, hideForOffice: false
          },
          { 
            text: 'configureMigrate.site', value: 'siteName', align: 'start', sortable: false, width:"120",
            class: "data-table-header customer-users-headers", cellClass: "data-table-cell", hideForVideo: false, hideForOffice: false
          },
          { 
            text: 'configureMigrate.primaryNumber', value: 'primaryNumber', align: 'start', sortable: false, width:"125",
            class: "data-table-header customer-users-headers", cellClass: "data-table-cell", hideForVideo: true, hideForOffice: false
          },
          { 
            text: 'configureMigrate.digestInformation', value: 'digestInformation', align: 'start', sortable: false, width:"125",
            class: "data-table-header customer-users-headers", cellClass: "data-table-cell", hideForVideo: false, hideForOffice: true
          },
          { 
            text: 'configureMigrate.email', value: 'email', align: 'start', sortable: false, width:"150",
            class: "data-table-header customer-users-headers", cellClass: "data-table-cell", hideForVideo: false, hideForOffice: false
          }
        ],
        statusTypes: Utils.statusTypes,
        filterTypes: Utils.filterTypes,
      }
    },
    computed: {
      ...mapState(['customer', 'users', 'loggedUser']),
      cssVariables () {
        return Utils.calculateHeaderWidth(this.headers.length);
      },
      totalElements() {
        return this.users && this.users.paging && this.users.paging.totalElements || 0;
      },
      computedHeaders() {
        return this.headers.filter(header => {
          if (this.isUnifyVideoAccount() === true) {
            return header.hideForVideo === false;
          } else {
            return header.hideForOffice === false;
          }
        });
      },
      items() {
        const { page , itemsPerPage } = this.options;
        // This only happens when a new pbx is imported/deleted or at logon. Records.length will be at max as dictated
        // by the server and the page will be 1.
        if (this.users && this.users.records && this.users.records.length > itemsPerPage && page === 1) {
            store.commit('reduceRecords', { type: 'users', number: itemsPerPage })
        }
        return this.users && this.users.records && this.users.records.map(item => {
          const translatedExtensionText = item.uoUnassignedExtensionText && this.$t(item.uoUnassignedExtensionText);
          const translatedDigestInfoText = item.digestInformation && this.$t(item.digestInformation) ;
          return {
            name: item.displayName || translatedExtensionText || item.uuid,
            source: item.sourcesText,
            target: item.targetText,
            status: item.status,
            removal: item.removalsText,
            siteName: item.siteText,
            primaryNumber: item.primaryNumberText,
            digestInformation: translatedDigestInfoText || '',
            email: item.email
          }
        });
      },
      name: {
        get: function () {
          return this.users && this.users.search && this.users.search.name;
        },
        set: function (value) {
          this.search.name = value;
          this.pageReset();
        }
      },
      source: {
        get: function () {
          return this.users && this.users.search && this.users.search.source;
        },
        set: function (value) {
          this.search.source = value;
          this.pageReset();
        }
      },
      target: {
        get: function () {
          return this.users && this.users.search && this.users.search.target;
        },
        set: function (value) {
          this.search.target = value;
          this.pageReset();
        }
      },
      removal: {
        get: function () {
          return this.users && this.users.search && this.users.search.removal;
        },
        set: function (value) {
          this.search.removal = value;
          this.pageReset();
        }
      },
      number: {
        get: function () {
          return this.users && this.users.search && this.users.search.number;
        },
        set: function (value) {
          this.search.number = value;
          this.pageReset();
        }
      },
      siteName: {
        get: function () {
          return this.users && this.users.search && this.users.search.siteName;
        },
        set: function (value) {
          this.search.siteName = value;
          this.pageReset();
        }
      },
      email: {
        get: function () {
          return this.users && this.users.search && this.users.search.email;
        },
        set: function (value) {
          this.search.email = value;
          this.pageReset();
        }
      },
      status: {
        get: function () {
          return this.users && this.users.search && this.users.search.status;
          },
        set: function (value) {
          this.search.status = value;
          this.pageReset();
        }
      },
      search() {
        return {
            name: this.name,
            source: this.source,
            target: this.target,
            removal: this.removal,
            status: this.status,
            siteName: this.siteName,
            number: this.number,
            email: this.email
        }
      },
      options: {
        get: function () {
          return this.users.paging;
        },
        set: function (value) {
          this.fetchData(value, this.search);
        }
      }
    },
    methods: {
      isUnifyVideoAccount () {
        return this.loggedUser && this.loggedUser.user && this.loggedUser.user.isUnifyVideo === true;
      },
      pageReset() {
        this.options.page = 1;
        this.fetchData(this.options, this.search);
      },
      getClass(value) {
        let statusClass;
        if (value === 'Pending') {
          statusClass = 'status pending';
        } else if (value === 'Successful') {
          statusClass = 'status successful';
        } else if (value === 'Failed') {
          statusClass = 'status fail';
        } else {
          statusClass = '';
        }
        return statusClass;
      },
      detectQueryChange(options, search) {
        const { page, itemsPerPage } = options;
        const { name, source, target, removal, siteName, number, email, status } = search;
        const noChange =  page && itemsPerPage && this.users && this.users.paging && this.users.search
                        && this.users.paging.page === page && this.users.paging.itemsPerPage === itemsPerPage
                        && this.users.search.name === name && this.users.search.source === source
                        && this.users.search.target === target && this.users.search.removal === removal
                        && this.users.search.siteName === siteName && this.users.search.number === number
                        && this.users.search.status === status && this.users.search.email === email;
        return !noChange
      },
      getQuery(options, search) {
        const { page, itemsPerPage } = options
        const pagingParameters = { page, itemsPerPage }
        return Utils.encodeBase64Url(JSON.stringify({ options: pagingParameters, search }));
      },
      fetchData: async function(options, search) {
        if (this.loading) { return; }
        try {
          this.loading = true;
          if (this.detectQueryChange(options,search)) {
            await store.dispatch('getUsers', {customerId: this.customer.uuid, query: this.getQuery(options, search)});
          }
        } finally {
          this.loading = false;
        }
      }
    }
  }
</script>
<style>
  .customer-users-headers {
      width: var(--width) !important;
  }
</style>
