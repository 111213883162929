import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const colors = {
  mitelDarkBlue: "#15325f",
  mitelLightBlue: "#00a1e0",
  mitelMidBlue: "#0073d0",
  mitelGray: "#58595b",
  mitelYellow: "#fdb714",
  mitelPurple: "#812cc4",
  mitelCyan: "#72efdd",
  mitelGreen: "#8ac926",
  mitelPink: "#e2348b",
  mitelRed: "#ec3831",
};

export default new Vuetify({
  icons: {
    iconfont: "fa",
  },
  theme: {
    themes: {
      light: {
        primary: colors.mitelMidBlue,
        error: colors.mitelRed,
        success: colors.mitelGreen,
        light: colors.mitelLightBlue,
        medium: colors.mitelMidBlue,
        gray: colors.mitelGray,
        dark: colors.mitelDarkBlue,
        white: "#FFFFFF",
      },
      dark: {
        primary: colors.mitelMidBlue,
        error: colors.mitelRed,
        success: colors.mitelGreen,
        light: colors.mitelLightBlue,
        medium: colors.mitelMidBlue,
        gray: colors.mitelGray,
        dark: colors.mitelDarkBlue,
        white: "#FFFFFF",
      },
    },
  },
});
