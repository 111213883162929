 <template>
  <v-dialog persistent no-click-animation v-model="show" max-width="500px">
    <v-card>
      <v-card-title class="text-bold-24">{{ $t('uploadXls.uploadXls') }}</v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
            <v-row>
              <fileUpload
                :pbxType='item'
                :customerId='customerId'
                :hideProgress=true
                @upload-finished="uploadFinished"
                @upload-started="uploadStarted"
              ></fileUpload>
            </v-row>
            </v-col>
          </v-row>
          <v-row class="status-info" v-if="!progressIndicator">
            <v-col align="center">
              <p class="text-gray-18">{{ dialogStatusText }}</p>
            </v-col>
          </v-row>
          <v-row class="status-info" v-if="progressIndicator">
            <v-col align="center">
              <v-progress-circular :size="36" indeterminate color="primary"></v-progress-circular>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn text color="primary" @click="download('UOMT')">{{ $t('uploadXls.downloadXls') }}</v-btn>     
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="closeDialog">{{ $t('common.close') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import FileUpload from "../components/fileUpload";
  import FileDownloader from '../common/fileDownloader';
  import store from '../store';
  import { mapState } from 'vuex';

  export default {
    name: 'UploadXlsDialog',
    props: {
      value: Boolean,
      customerId: String
    },
    components: {
      FileUpload
    },
    data() {
      return {
        item: 'UOMT',
        progressText: '',
        showProgressIndicator: false,
        showStatusMessage: false,
        dialogStatusText: '',
        statusMessage: {}
      }
    },
    computed: {
      ...mapState(['unauthorized']),
      showProgress() {
        return this.uploadingXls;
      },
      progressIndicator() {
        return this.uploadingXls || this.showProgressIndicator;
      },
      showConfirmDialog: {
        get () {
          return this.unauthorized;
        },
        set (value) {
          this.$emit('input', value)
        }
      },

      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        }
      }
    },
    methods: {
      closeDialog() {
        this.dialogStatusText = '';
        this.showStatusMessage = false;
        this.$emit('close-dialog');
        store.commit('setFileUploadFormStatus', 'INITIAL');
      },
      onConfirmErrorMessage() {

      },
      uploadStarted(pbxType) {
        this.showProgressIndicator = true;
        this.showStatusMessage = true;
        this.dialogStatusText = `Uploading ${pbxType} file`;
        store.commit('resetReports');
      },
      uploadFinished(pbxType, result, response) {
        this.showProgressIndicator = false;
        if (result.status === 'SUCCESS') {
          this.dialogStatusText = `File upload for ${pbxType} finished`;
          store.commit('setCustomers', response && response.customers);
        } else {
          this.dialogStatusText = `File upload for ${pbxType} failed`;
          if (response.consistencyReport) {
            store.commit('setConsistencyReport', response.consistencyReport);
          }
        }
      },
      async download(pbxType, contentType) {
        try {
          this.$log.debug(`[CustomerDownloadUpload] download for ${pbxType}, customer ${this.customerId}`)
          this.statusMessage = {text: `Downloading ${pbxType} ${contentType ? contentType : 'spreadsheet'}`};
          store.commit('resetReports');
          const fileDownloader = new FileDownloader();
          const result = await fileDownloader.download(pbxType, this.customerId, contentType);
          if (result.status === 200) {
            this.statusMessage = {text: this.$t('common.downloadFinished')}
          } else {
            this.statusMessage = {text: result.statusText, error: true};
          }
        } catch(err) {
          this.$log.error(`[CustomerDownloadUpload] download for ${pbxType}, customer ${this.customerId} error:`, err);
        }
      }  
    }
  }
</script>

<style scoped>
.status-info {
  min-height: 50px;
}
</style>
