import { logger } from 'vue-logger';
import Swal from "sweetalert2";

export default class ErrorHandler {
  constructor() {
    this.initHandler();
  }

  static onError(error) {
      logger.error(`[errorHandler] error captured: ${error}`);

      if(error.code && error.title && error.message) {
        this.displayAlert(error.code, error.title, error.message);
      } 
      return false;
  }

  static initHandler() {
    const scope = this;
    window.onerror = (message, url, lineNo, columnNo, error) => {
      if (error) {
        scope.onError(error);
      }
    };
  }

  static displayAlert(code, title, message) {
    Swal.fire({
      title: title,
      text: message,
      icon: code >= 500 ? "error" : "warning",
    });
  }
}
