 <template>
 <v-dialog persistent no-click-animation v-model="show" max-width="500px">
    <v-card>
      <v-card-title class="text-bold-24">{{ $t('resetDialog.title') }}</v-card-title>
      <div>
        <v-card-text>
        <v-container>
        <div class='md-body-2'>{{ $t('resetDialog.description') }}</div>
        <div class='md-body-2'>{{ $t('common.areYouSureYouWantToProceed') }}</div>
        </v-container>
      </v-card-text>
       <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="confirmDialog">{{ $t('common.yes') }}</v-btn>   
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="closeDialog">{{ $t('common.no') }}</v-btn>  
        <v-spacer></v-spacer>
      </v-card-actions>
      </div>
    </v-card>
  </v-dialog>

</template>
<script>

  export default {
    name: 'CancelStepperDialog',
    props: {
      value: Boolean
    },
    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('cancel', value)
        }
      },
    },
    methods: {
      closeDialog() {
        this.$emit('close-dialog');
      },
      confirmDialog() {
        this.$emit('confirm-dialog');
      },
    }
  }
</script>
