<template>
  <v-dialog persistent no-click-animation scrollable v-model="show" max-width="52%">
    <v-card>
      <v-card-title class="text-bold-24">{{ $t('instructions.osuc.title') }}</v-card-title>
      <v-card-text>
        <v-container>
      <div class='md-body-1 left-0-5'>{{ $t('instructions.osuc.line1') }}</div>
      <div class='text-center top-1-0'>
        <div><a download="fetchUCContacts.sh" href="../assets/scripts/fetchUCContacts.sh"><v-icon x-large>fa fa-download</v-icon></a></div>
        <div>fetchUCContacts.sh</div> </div>
      <div class='md-body-1 left-0-5 top-1-0'>{{ $t('instructions.osuc.line2') }}</div>
      <div class='md-body-1 left-0-5 top-1-0'>{{ $t('instructions.osuc.line3') }}<span class='bold'>./fetchUCContacts.sh</span></div>
      <div class='md-body-1 left-0-5 top-1-0 bold-red'>{{ $t('instructions.osuc.line4') }}</div>
      <div class='md-body-1 left-0-5 top-1-0'>{{ $t('instructions.osuc.line5') }}<span class='bold'>uc_contacts.csv</span>.</div>
      <div class='md-body-1 left-0-5 top-1-0'>{{ $t('instructions.osuc.line6') }}</div>
      <div class='md-body-1 left-0-5 top-1-0 bottom-0-5 bold-red'>{{ $t('instructions.osuc.line7') }}</div>
    </v-container>
      </v-card-text>
      <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog">{{ $t('common.close') }}</v-btn>  
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    name: 'OsucInstructionsDialog',
    props: {
      value: Boolean
    },
    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('osuc-instructions', value)
        }
      },
    },
    methods: {
      closeDialog() {
        this.$emit('close-dialog');
      }
    }
  }
</script>

<style scoped>
  .bold-red {
    font-weight: bold;
    color: red;
  }
  .bold {
    font-weight: bold;
  }
  .left-0-5 {
    margin-left: 0.5rem;
  }
  .top-0-5 {
    margin-top: 0.5rem;
  }
  .left-1-0 {
    margin-left: 1.0rem;
  }
  .top-1-0 {
    margin-top: 1.0rem;
  }
  .bottom-0-5 {
    margin-bottom: 0.5rem;
  }
</style>
