 <template>
 <v-dialog persistent no-click-animation v-model="show" max-width="400px">
    <v-card>
      <v-card-title class="text-bold-24">{{ $t(dialogTitle) }}</v-card-title>
      <div v-if='!errorMessage'>
        <v-card-text>
        <v-container>
        <div class='md-body-2'>{{ $t('deleteDialog.deleteDialogText', { dialogTarget:  dialogTarget} ) }}</div>
        <div class='md-body-2'>{{ $t('common.actionCannotBeUndone') }}</div>
        </v-container>
      </v-card-text>
       <v-card-actions>
        <md-progress-spinner v-if="showProgress" :md-diameter=25 :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
        <v-spacer></v-spacer>
        <v-btn text color="primary" :disabled="showProgress" @click="confirmDialog">{{ $t('common.yes') }}</v-btn>   
        <v-spacer></v-spacer>
        <v-btn text color="primary" :disabled="showProgress" @click="closeDialog">{{ $t('common.no') }}</v-btn>  
        <v-spacer></v-spacer>
      </v-card-actions>
      </div>
      <div v-if='errorMessage'>
        <v-card-text>
        <v-container>
        <div class='md-body-2'>{{ errorMessage }}</div>
        </v-container>
      </v-card-text>
       <v-card-actions>
        <md-progress-spinner v-if="showProgress" :md-diameter=25 :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
        <v-btn text color="primary" @click="closeDialog">{{ $t('common.ok') }}</v-btn>  
       </v-card-actions>
      </div>
    </v-card>
  </v-dialog>

</template>
<script>
  export default {
    name: 'DeleteDialog',
    props: ['value','showProgress', 'errorMessage', 'dialogTitle', 'dialogTarget'],
    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('delete', value)
        }
      },
    },
    methods: {
      closeDialog() {
        this.$emit('close-dialog');
      },
      confirmDialog() {
        this.$emit('confirm-dialog');
      }
    }
  }
</script>
