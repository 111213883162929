<template>
  <v-dialog persistent no-click-animation v-model="show" max-width="700px">
    <v-card>
      <v-card-title class="text-bold-24">{{ $t('settings.title') }}</v-card-title>
      <v-spacer></v-spacer>
      <v-card-text>
         <div>
            <div class='md-body-2'>{{ $t('settings.customerDataHeader') }}</div>
            <input id="autoDeleteCustomerData" v-model="autoDeleteCustomerData" @change="onChangeAutoDelete($event)" type="checkbox" class="auto-delete-checkbox">
            <label for="autoDeleteCustomerData">
              {{ $t('settings.autoDeleteCustomerData') }}
            </label>
          </div>
      </v-card-text>
      <v-card-actions>
        <v-btn text color="primary" @click="closeDialog">{{ $t('common.close') }}</v-btn>  
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import * as RestApiHandler from '../common/restApiHandler';
  import { logger } from 'vue-logger';
  import { mapState } from 'vuex';
  import store from '../store';

  export default {
    name: 'SettingsDialogue',
    props: {
      value: Boolean,
      customerId: String
    },
    computed: {
      ...mapState([
        'customer', 'migrationUsers', 'selectedMigrationUsers', 'selectedMigrationSites', 'selectAllUsers', 'selectAllPorting', 
        'stepperConsistencyReport', 'regionals', 'migrationUserErrors'
      ]),
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('settings', value)
        }
      },
      autoDeleteCustomerData: {
        get () {
          return !this.customer.disableCustomerDataAutoDelete;
        },
        set (value) {
          store.commit('setCustomerDisableCustomerDataAutoDelete', !value);
        }
      },
    },
    methods: {
      closeDialog() {
        this.$emit('close-dialog');
      },
      async onChangeAutoDelete() {
        try { 
          logger.debug(`[Settings] Change settings for customer.`);
          var changes = {};
          changes.disableCustomerDataAutoDelete = !this.autoDeleteCustomerData;
          const result = await RestApiHandler.changeCustomerSettings(JSON.stringify(changes), this.customerId);
          if (result.status !== 200) {
            this.$log.error('[Customer Settings] save error: ', result);
          } else {
            const response = result.json ? await result.json() : result;
            this.$log.debug('[Customer Settings] response: ', response);
          }
        } catch(err) {
          logger.error(`[Settings] Change settings for customer`, err);
        }
      },
    }
  }
</script>
<style scoped>
.auto-delete-checkbox {
  vertical-align: middle;
  margin-right: 8px;
}
</style>
