<template>
  <v-col>
    <v-row>
      <v-data-table
        item-key="uuid"
        show-select single-select
        v-model="migrationItems"
        mobile-breakpoint=0
        :options.sync="options"
        :headers="headers"
        :items="items"
        :server-items-length="totalElements"
        :loading="loading"
        :no-data-text = "$t('common.noDataAvailable')"
        :footer-props="{
            disablePagination: loading,
            itemsPerPageOptions: [5, 10, 20],
            showFirstLastPage: true,
            firstIcon: 'first_page',
            prevIcon: 'chevron_left',
            nextIcon: 'chevron_right',
            lastIcon: 'last_page',
            itemsPerPageText: $t('common.rowsPerPage')
        }"
        class="full-width data-table">
        <template v-slot:[`header.name`]="{ header }">
          <v-text-field
            :value="name"
            @change="$event => name = $event"
            @click:clear="$event => name = ''"
            :label="$t(header.text)"
            append-icon="search"
            clear-icon="close"
            single-line hide-details
            flat solo clearable
            :placeholder="$t(header.text)"
          ></v-text-field>
        </template>
        <template v-slot:[`item.data-table-select`]="{ isSelected, select }">
            <v-simple-checkbox
                color="primary"
                v-ripple
                :value="isSelected"
                @input="select($event)"
            ></v-simple-checkbox>
        </template>
      </v-data-table>
    </v-row>
  </v-col>
</template>

<script>
  import { mapState } from 'vuex';
  import store from '../store';
  import Utils from '../common/utils';

  export default {
    name: 'configurationBgs',
    data() {
      return {
        loading: false,
        /**
         * The width of the headers has been calculated based on their label and potential content
         */
        headers:[
          { value: 'uuid', align: ' d-none', sortable: false },
          { text: 'common.name', value: 'name', align: 'start', width:'300', sortable: false, class: 'data-table-header no-header-divider', cellClass: 'data-table-cell' },
          { value: 'none', align: 'start', sortable: false },
        ]
      }
    },
    mounted() {
			this.fetchData(this.options, this.search, true); 
    },
    computed: {
      ...mapState(['customer', 'currentStep', 'migrationBusinessGroups', 'selectedMigrationBusinessGroups']),
      totalElements() {
        return this.migrationBusinessGroups && this.migrationBusinessGroups.paging && this.migrationBusinessGroups.paging.totalElements || 0;
      },
      editableItem() {
        return this.loading;
      },
      items() {
        return this.migrationBusinessGroups && this.migrationBusinessGroups.records && this.migrationBusinessGroups.records.map(item => {
          return {
            uuid: item.uuid,
            name: item.name,
          }
        });
      },
      migrationItems: {
        get: function () {
          return this.selectedMigrationBusinessGroups;
        },
        set: function (value) {
          store.commit('setSelectedMigrationBusinessGroups', value);
        }
      },
      name: {
        get: function () {
          return this.migrationBusinessGroups && this.migrationBusinessGroups.search && this.migrationBusinessGroups.search.name || '';
        },
        set: function (value) {
          this.search.name = value;
          this.pageReset();
        }
      },
      search() {
        return {
            name: this.name,
        }
      },
      options: {
        get: function () {
          return this.migrationBusinessGroups.paging;
        },
        set: function (value) {
          this.fetchData(value, this.search);
        }
      }
    },
    methods: {
      pageReset(){
        this.options.page = 1;
        this.fetchData(this.options, this.search);
      },
      detectQueryChange(options, search) {
         const { page, itemsPerPage } = options
         const { name } = search
         const noChange = page && itemsPerPage && this.migrationBusinessGroups && this.migrationBusinessGroups.paging
                          && this.migrationBusinessGroups.paging.page === page && this.migrationBusinessGroups.paging.itemsPerPage === itemsPerPage
                          && name && this.migrationBusinessGroups && this.migrationBusinessGroups.search
                          && this.migrationBusinessGroups.search.name === name;
         return !noChange
      },
      getQuery(options, search) {
         const { page, itemsPerPage } = options
         const pagingParameters = { page, itemsPerPage }
         return Utils.encodeBase64Url(JSON.stringify({ options: pagingParameters, search }));
      },
      fetchData: async function(options, search, initialize) {
        if (this.loading) { return; }
        try {
          this.loading = true;
          if (initialize || this.detectQueryChange(options,search)) {
            this.search.excludeUO = true;
            await store.dispatch('getMigrationBusinessGroups', {customerId: this.customer.uuid, query: this.getQuery(options,search)});
          }
        } finally {
          this.loading = false;
        }
      }
    }
  }
</script>
