'use strict';

//Useful filters that we can use in Vue components

function pluralize (count, singular, plural) {
    return (count > 1 || count === 0) ? plural : singular;
}

export {
    pluralize
}
