<template>
  <v-col :style="cssVariables">
    <v-row>
      <v-data-table
        item-key="uuid"
        v-model="migrationItems"
        show-select single-select
        mobile-breakpoint=0
        :headers="computedHeaders"
        :items="getCombinedSites"
        :options.sync="options"
        :server-items-length="totalElements"
        :loading="loading"
        :no-data-text = "$t('common.noDataAvailable')"
        :footer-props="{
            disablePagination: loading,
            itemsPerPageOptions: [5, 10, 20],
            showFirstLastPage: true,
            firstIcon: 'first_page',
            prevIcon: 'chevron_left',
            nextIcon: 'chevron_right',
            lastIcon: 'last_page',
            itemsPerPageText: $t('common.rowsPerPage')
        }"
        class="full-width data-table"
      >
        <template v-slot:[`header.name`]="{ header }">
          <v-text-field
            :value="name"
            @change="$event => name = $event"
            @click:clear="$event => name = ''"
            :label="$t(header.text)"
            append-icon="search"
            clear-icon="close"
            single-line hide-details
            flat solo clearable
            :placeholder="$t(header.text)"
          ></v-text-field>
        </template>
        <template v-slot:[`header.code`]="{ header }">
          <v-combobox
              v-model="code"
              :items="!code ? filterTypes : undefined"
              item-text="filterLabel"
              item-value="filterValue"
              :label="$t(header.text)"
              hide-no-data hide-details hide-selected
              append-icon="search"
              clear-icon="close"
              solo single-line flat clearable 
              @change="$event => code = $event"
              @click:clear="$event => code = ''"
              :placeholder="$t(header.text)"
          ></v-combobox>
        </template>
        <template v-slot:[`header.locationName`]="{ header }">
          <v-combobox
              v-model="locationName"
              :items="!locationName ? filterTypes : undefined"
              item-text="filterLabel"
              item-value="filterValue"
              :label="$t(header.text)"
              hide-no-data hide-details hide-selected
              append-icon="search"
              clear-icon="close"
              solo single-line flat clearable 
              @change="$event => locationName = $event"
              @click:clear="$event => locationName = ''"
              :placeholder="$t(header.text)"
          ></v-combobox>
        </template>
        <template v-slot:[`header.country`]="{ header }">
          <v-select
            :items="countryNamesFilter"
            item-text="name"
            item-value="isoCode"
            :value="country"
            :label="$t(header.text)"
            @change="$event => country = $event"
             @click:clear="$event => country = null"
            append-icon="search"
            clear-icon="close"
            single-line hide-details
            flat solo clearable
            :placeholder="$t(header.text)"
            :menuProps="{ auto: true, bottom: true, offsetY: true }"
          ></v-select>
        </template>
        <template v-slot:[`header.state`]="{ header }">
          <v-select
            :items="supportedStates"
            item-text="name"
            item-value="isoCode"
            :value="state"
            :label="$t(header.text)"
            @change="$event => state = $event"
            @click:clear="$event => state = null"
            append-icon="search"
            clear-icon="close"
            single-line hide-details
            flat solo clearable
            :placeholder="$t(header.text)"
            :menuProps="{ auto: true, bottom: true, offsetY: true }"
          ></v-select>
        </template>
        <template v-slot:[`header.city`]="{ header }">
          <v-combobox
              v-model="city"
              :items="!city ? filterTypes : undefined"
              item-text="filterLabel"
              item-value="filterValue"
              :label="$t(header.text)"
              hide-no-data hide-details hide-selected
              append-icon="search"
              clear-icon="close"
              solo single-line flat clearable 
              @change="$event => city = $event"
              @click:clear="$event => city = ''"
              :placeholder="$t(header.text)"
          ></v-combobox>
        </template>
        <template v-slot:[`header.streetName`]="{ header }">
          <v-combobox
              v-model="streetName"
              :items="!streetName ? filterTypes : undefined"
              item-text="filterLabel"
              item-value="filterValue"
              :label="$t(header.text)"
              hide-no-data hide-details hide-selected
              append-icon="search"
              clear-icon="close"
              solo single-line flat clearable 
              @change="$event => streetName = $event"
              @click:clear="$event => streetName = ''"
              :placeholder="$t(header.text)"
          ></v-combobox>
        </template>
        <template v-slot:[`header.postalCode`]="{ header }">
          <v-combobox
              v-model="postalCode"
              :items="!postalCode ? filterTypes : undefined"
              item-text="filterLabel"
              item-value="filterValue"
              :label="$t(header.text)"
              hide-no-data hide-details hide-selected
              append-icon="search"
              clear-icon="close"
              solo single-line flat clearable 
              @change="$event => postalCode = $event"
              @click:clear="$event => postalCode = ''"
              :placeholder="$t(header.text)"
          ></v-combobox>
        </template>
        <template v-slot:[`header.timeZoneName`]="{ header }">
          <v-select
            :items="timeZones"
            item-text="name"
            item-value="name"
            :label="$t(header.text)"
            @change="$event => timeZoneName = $event"
            @click:clear="$event => timeZoneName = null"
            append-icon="search"
            clear-icon="close"
            single-line hide-details
            flat solo clearable
            :placeholder="$t(header.text)"
            :menuProps="{ auto: true, bottom: true, offsetY: true }"
          ></v-select>
        </template>
        <template v-slot:[`item.data-table-select`]="{ item, select }">
            <v-simple-checkbox
              color="primary"
              :value="item.isSelected"
              @input="$event => {select($event); editRow = '';}"
              :ripple="false" dense
            ></v-simple-checkbox>
        </template>
        <template v-slot:[`item.edit`]="{ item }">
            <v-btn
              icon small
              :color="getColor(item)"
              v-bind:class="item.isSelected ? 'visible' : 'invisible'"
              @click="toggleEdit(item)"
            >
              <v-icon>edit</v-icon>
            </v-btn>
        </template>
        <template v-slot:[`item.name`]="{ item }">
            <span v-if="isSiteEditable(item) && isInEditMode(item) && validateNow('name')">
              <v-tooltip :disabled="!getFieldErrorText(item, item.uuid, 'SITE_NAME')" top>
                <template v-slot:activator="{ on }">
                  <v-form ref="name">
                    <v-combobox
                      :items="accountSites(item.name)"
                      item-text="name"
                      item-value="value"
                      :value="getValueForSiteName(item)"
                      @change="$event => {item.name = $event.value || $event; item.translatableName = $event.translatableName; editCell(item, item.uuid, 'SITE_NAME');}"
                      :rules="getValidatorRules(item, item.uuid, 'SITE_NAME')"
                      hide-details
                      outlined solo flat dense
                      v-on="on"
                    ></v-combobox>
                  </v-form>
                </template>
                <span>{{getFieldErrorText(item, item.uuid, 'SITE_NAME')}}</span>            
              </v-tooltip>
            </span>
            <span v-else>{{getValueForSiteName(item)}}</span>
        </template>
        <template v-slot:[`item.code`]="{ item }">
            <span v-if="isSiteEditable(item) && isInEditMode(item) && validateNow('code')">
              <v-tooltip :disabled="!getFieldErrorText(item, item.uuid, 'SITE_CODE')" top>
                <template v-slot:activator="{ on }">
                  <v-form ref="code">
                    <v-text-field
                      :value="item.code"
                      :label="item.code"
                      @change="$event => {item.code = $event; editCell(item, item.uuid, 'SITE_CODE');}"                            
                      :rules="getValidatorRules(item, item.uuid, 'SITE_CODE')"
                      hide-details
                      outlined solo flat dense
                      v-on="on"
                    ></v-text-field>
                  </v-form>
                </template>
                <span>{{getFieldErrorText(item, item.uuid, 'SITE_CODE')}}</span>
              </v-tooltip>                        
            </span>
            <span v-else>{{item.resolvedCode || item.code}}</span>
        </template>
        <template v-slot:[`item.locationName`]="{ item }">
            <span v-if="item.location && isInEditMode(item) && validateNow('location')">
              <v-tooltip :disabled="!getFieldErrorText(item, item.location.uuid, 'LOCATION_NAME')" top>
                <template v-slot:activator="{ on }">
                  <v-form ref="location">
                    <v-text-field
                      :value="item.location.name"
                      :label="item.location.name"
                      @change="$event => {item.location.name = $event; editCell(item, item.location.uuid, 'LOCATION_NAME');}"
                      :rules="getValidatorRules(item, item.location.uuid, 'LOCATION_NAME')"
                      hide-details
                      outlined solo flat dense
                      v-on="on"
                    ></v-text-field>
                  </v-form>
                </template>
                <span>{{getFieldErrorText(item, item.location.uuid, 'LOCATION_NAME')}}</span>
              </v-tooltip>
            </span>
            <span v-else>{{item.location && item.location.name}}</span>
        </template>
        <template v-slot:[`item.country`]="{ item }">
            <span v-if="item.location && isInEditMode(item) && validateNow('country')">
              <v-tooltip :disabled="!getFieldErrorText(item, item.location.uuid, 'COUNTRY')" top>
                <template v-slot:activator="{ on }">
                  <v-form ref="country">
                    <v-select
                      :items="countryNames"
                      item-text="name"
                      item-value="isoCode"
                      :value="item.location.countryIso2Code"
                      @input="$event => {item.location.countryIso2Code = $event; item.location.state = ''; editCell(item, item.location.uuid, 'COUNTRY');}"
                      :rules="getValidatorRules(item, item.location.uuid, 'COUNTRY')"
                      outlined solo flat dense
                      hide-details
                      v-on="on"
                      :menuProps="{ auto: true, bottom: true, offsetY: true }"
                    ></v-select>
                  </v-form>
                </template>
                <span>{{getFieldErrorText(item, item.location.uuid, 'COUNTRY')}}</span>
              </v-tooltip>
            </span>
            <span v-else>{{item.location && item.location.countryIso2Code && getNameByCountryIsoCode(item.location.countryIso2Code)}}</span>
        </template>
        <template v-slot:[`item.state`]="{ item }">
          <span v-if="needToShowStateSelection(item.location)">
            <span v-if="isInEditMode(item) && validateNow('state')">
              <v-tooltip :disabled="!getFieldErrorText(item, item.location.uuid, 'STATE')" top>
                <template v-slot:activator="{ on }">
                  <v-form ref="state">
                    <v-select
                      :items="statesByCountry(item.location)"
                      item-text="name"
                      item-value="isoCode"
                      :value="item.location.state"
                      @change="$event => {item.location.state = $event; editCell(item, item.location.uuid, 'STATE');}"
                      :rules="getValidatorRules(item, item.location.uuid, 'STATE')"
                      outlined solo flat dense
                      v-on="on"
                      hide-details
                      :menuProps="{ auto: true, bottom: true, offsetY: true }"
                    ></v-select>
                  </v-form>
                </template>
                <span>{{getFieldErrorText(item, item.location.uuid, 'STATE')}}</span>
              </v-tooltip>                        
            </span>
            <span v-else>{{item.location && item.location.state && getStateByIsoCode(item.location.state)}}</span>
          </span>
        </template>
        <template v-slot:[`item.city`]="{ item }">
            <span v-if="item.location && isInEditMode(item) && validateNow('city')">
              <v-tooltip :disabled="!getFieldErrorText(item, item.location.uuid, 'CITY')" top>
                <template v-slot:activator="{ on }">
                  <v-form ref="city">
                    <v-text-field
                      :value="item.location.city"
                      :label="item.location.city"
                      @change="$event => {item.location.city = $event; editCell(item, item.location.uuid, 'CITY');}"
                      :rules="getValidatorRules(item, item.location.uuid, 'CITY')"
                      hide-details
                      outlined solo flat dense
                      v-on="on"
                    ></v-text-field>
                  </v-form>
                </template>
                <span>{{getFieldErrorText(item, item.location.uuid, 'CITY')}}</span>
              </v-tooltip>                        
            </span>
            <span v-else>{{item.location && item.location.city}}</span>
        </template>
        <template v-slot:[`item.streetName`]="{ item }">
            <span v-if="item.location && isInEditMode(item) && validateNow('street')">
              <v-tooltip :disabled="!getFieldErrorText(item, item.location.uuid, 'STREET_NAME')" top>
                <template v-slot:activator="{ on }">
                  <v-form ref="street">
                    <v-text-field
                      :value="item.location.streetName"
                      :label="item.location.streetName"
                      @change="$event => {item.location.streetName = $event; editCell(item, item.location.uuid, 'STREET_NAME');}"
                      :rules="getValidatorRules(item, item.location.uuid, 'STREET_NAME')"
                      hide-details
                      outlined solo flat dense
                      v-on="on"
                    ></v-text-field>
                  </v-form>
                </template>
                <span>{{getFieldErrorText(item, item.location.uuid, 'STREET_NAME')}}</span>
              </v-tooltip>                        
            </span>
            <span v-else>{{item.location && item.location.streetName}}</span>
        </template>  
        <template v-slot:[`item.postalCode`]="{ item }">
            <span v-if="item.location && isInEditMode(item) && validateNow('postal')">
              <v-tooltip :disabled="!getFieldErrorText(item, item.location.uuid, 'POSTAL_CODE')" top>
                <template v-slot:activator="{ on }">
                  <v-form ref="postal">
                    <v-text-field
                      :value="item.location.postalCode"
                      :label="item.location.postalCode"
                      @change="$event => {item.location.postalCode = $event; editCell(item, item.location.uuid, 'POSTAL_CODE');}"
                      :rules="getValidatorRules(item, item.location.uuid, 'POSTAL_CODE')"
                      hide-details
                      outlined solo flat dense
                      v-on="on"
                    ></v-text-field>
                  </v-form>
                </template>
                <span>{{getFieldErrorText(item, item.location.uuid, 'POSTAL_CODE')}}</span>
              </v-tooltip>
            </span>
            <span v-else>{{item.location && item.location.postalCode}}</span>
        </template>
        <template v-slot:[`item.timeZoneName`]="{ item }">
            <span v-if="item.location && isInEditMode(item)">
              <v-tooltip :disabled="getFieldErrorText(item, item.location.uuid, 'TIMEZONE')" top>
                <template v-slot:activator="{ on }">              
                  <v-select
                    :items="timeZones"
                    item-text="name"
                    item-value="name"
                    :value="item.location.timeZoneName || 'GMT'"
                    @input="$event => {item.location.timeZoneName = $event; editCell(item, item.location.uuid, 'TIMEZONE');}"
                    :rules="getValidatorRules(item, item.location.uuid, 'TIMEZONE')"
                    outlined solo flat dense
                    hide-details
                    v-on="on"
                    :menuProps="{ auto: true, bottom: true, offsetY: true }"
                  ></v-select>
                </template>
                <span>{{getFieldErrorText(item, item.location.uuid, 'TIMEZONE')}}</span>
              </v-tooltip>
            </span>
            <span v-else>{{(item.location && item.location.timeZoneName) || 'GMT'}}</span>
        </template>
      </v-data-table>
    </v-row>
  </v-col>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import store from '../store';
  import Utils from '../common/utils';

  export default {
    name: 'ConfigurationSite',
    data() {
      return {
        /**
         * The min-width of the headers has been calculated based on their label and potential content
         */
        // headers for Unify Office/Video only
        unifyOfficeHeaders:[
          { value: 'edit', align: 'start', width: '1', sortable: false, cellClass: "data-table-edit"},
          { text: 'stepper.siteName', value: 'name', align: 'start', width: '130', sortable: false,
            class: "data-table-header required-header configuration-sites-headers", cellClass: "data-table-cell required-input" 
          },
          { text: 'configureMigrate.code', value: 'code', align: 'start', width: '130', sortable: false,
            class: "data-table-header required-header configuration-sites-headers", cellClass: "data-table-cell required-input"
          },
          { text: 'stepper.customerName', value: 'locationName', align: 'start', width: '130', sortable: false,
            class: "data-table-header required-header configuration-sites-headers", cellClass: "data-table-cell required-input" 
          },
          { text: 'stepper.country', value: 'country', align: 'start', width: '130', sortable: false,
            class: "data-table-header required-header configuration-sites-headers", cellClass: "data-table-cell required-input" 
          },
          { text: 'stepper.state', value: 'state', align: 'start', width: '130', sortable: false,
            class: "data-table-header configuration-sites-headers", cellClass: "data-table-cell" 
          },
          { text: 'stepper.city', value: 'city', align: 'start', width: '130', sortable: false,
            class: "data-table-header required-header configuration-sites-headers", cellClass: "data-table-cell required-input" 
          },
          { text: 'stepper.streetName', value: 'streetName', align: 'start', width: '130', sortable: false,
            class: "data-table-header required-header configuration-sites-headers", cellClass: "data-table-cell required-input" 
          },
          { text: 'stepper.postalCode', value: 'postalCode', align: 'start', width: '130', sortable: false,
            class: "data-table-header required-header configuration-sites-headers", cellClass: "data-table-cell required-input" 
          },
          { text: 'stepper.timeZone', value: 'timeZoneName', align: 'start', width: '130', sortable: false,
            class: "data-table-header configuration-sites-headers", cellClass: "data-table-cell" 
          }
        ],
        // headers for Unify Phone Standalone only
        unifyPhoneHeaders:[
          { text: 'stepper.siteName', value: 'name', align: 'start', width: '300', sortable: false,
            class: "data-table-header no-header-divider required-header configuration-sites-headers", cellClass: "data-table-cell required-input"
          },
          { value: 'none', align: 'start', sortable: false },
        ],
        validators: {
          'SITE_NAME': [this.isRequired, this.isXSSsanitized],
          'SITE_CODE': [this.isRequired, this.isDigitsOnly],
          'LOCATION_NAME': [this.isRequired, this.isXSSsanitized],
          'COUNTRY': [this.isRequired],
          'STATE': [this.isRequired],
          'CITY': [this.isRequired, this.isCityName],
          'STREET_NAME': [this.isRequired, this.isStreetName],
          'POSTAL_CODE': [this.isRequired, this.isPostalCode],
          'TIMEZONE': [],
        },
        editRow: '',
        filterTypes: Utils.filterTypes,
        countryCodesWithStatesRequired: ['US', 'CA', 'GB'],
        loading: false,
        unwatch: null
      }
    },
    mounted() {
      this.fetchRegionals();
      this.unwatch = store.watch(state => state.currentStep, value => {
        if (value === 2) {
          this.fetchData(this.options, this.search, true);
          this.$emit('form-changed');
        }
      });
    },
    beforeDestroy() {
      this.unwatch();
    },
    computed: {
      ...mapState([
          'customer', 'migrationSites', 'selectedMigrationSites', 'regionals', 'selectedMigrationBusinessGroups', 'stepperConsistencyReport',
          'multiSiteEnabled', 'siteCodesEnabled', 'migrationSiteErrors', 'loggedUser'
        ]),
      ...mapGetters(['getCombinedSites', 'getStepperConsistencyErrorText']),
      totalElements() {
        return this.migrationSites && this.migrationSites.paging && this.migrationSites.paging.totalElements || 0;
      },
      computedHeaders() {
        const computedHeaders = this.isStandalone() === true ? this.unifyPhoneHeaders : this.unifyOfficeHeaders;
        if (this.selectedMigrationSites && this.selectedMigrationSites.length && this.selectedMigrationSites[0].location) {
          for (const header of computedHeaders) {
            if (header.value === 'state') {
              if (this.selectedMigrationSites[0].location.countryIso2Code === 'GB') {
                header.text = 'stepper.county';
              } else {
                header.text = 'stepper.state';
              }
            } 
          }
        }
        return computedHeaders.filter(header => header.value !== 'code' || (this.multiSiteEnabled && this.siteCodesEnabled));
      },
      cssVariables () {
        let numColumns = 0;
        if (this.isStandalone() === true) {
          numColumns = this.unifyPhoneHeaders.length;
        } else {
          numColumns = (this.multiSiteEnabled && this.siteCodesEnabled) ? this.unifyOfficeHeaders.length : this.unifyOfficeHeaders.length - 1;
        }
        // We do not count the edit column
        return Utils.calculateHeaderWidth(numColumns - 1);
      },
      migrationItems: {
        get: function () {
          return this.selectedMigrationSites;
        },
        set: function (value) {
          store.commit('setSelectedMigrationSites', value);

          if (value && value.length) {
            this.validateRow(true, value[0]);
          }
        }
      },
      name: {
        get: function () {
          return this.migrationSites && this.migrationSites.search && this.migrationSites.search.name || '';
        },
        set: function (value) {
          this.search.name = value;
          this.pageReset();
        }
      },
      code: {
        get: function () {
          return this.migrationSites && this.migrationSites.search && this.migrationSites.search.code || '';
        },
        set: function (value) {
          this.search.code = value;
          this.pageReset();
        }
      },
      locationName: {
        get: function () {
          return this.migrationSites && this.migrationSites.search && this.migrationSites.search.locationName || '';
        },
        set: function (value) {
          this.search.locationName = value;
          this.pageReset();
        }
      },
      country: {
        get: function () {
          return this.migrationSites && this.migrationSites.search && this.migrationSites.search.country || '';
        },
        set: function (value) {
          this.search.country = value;
          this.pageReset();
        }
      },
      state: {
        get: function () {
          return this.migrationSites && this.migrationSites.search && this.migrationSites.search.state || '';
        },
        set: function (value) {
          this.search.state = value;
          this.pageReset();
        }
      },
      city: {
        get: function () {
          return this.migrationSites && this.migrationSites.search && this.migrationSites.search.city || '';
        },
        set: function (value) {
          this.search.city = value;
          this.pageReset();
        }
      },
      streetName: {
        get: function () {
          return this.migrationSites && this.migrationSites.search && this.migrationSites.search.streetName || '';
        },
        set: function (value) {
          this.search.streetName = value;
          this.pageReset();
        }
      },
      postalCode: {
        get: function () {
          return this.migrationSites && this.migrationSites.search && this.migrationSites.search.postalCode || '';
        },
        set: function (value) {
          this.search.postalCode = value;
          this.pageReset();
        }
      },
      search() {
        return {
            name: this.name,
            code: this.code,
            locationName: this.locationName,
            country: this.country,
            state: this.state,
            city: this.city,
            streetName: this.streetName,
            postalCode: this.postalCode
        }
      },
      options: {
        get: function () {
          return this.migrationSites.paging;
        },
        set: function (value) {
          this.fetchData(value, this.search);
        }
      },
      countryNames() {
        return this.regionals && this.regionals.countries;
      },
      countryNamesFilter() {
        const regionalFilter = [ { name: this.filterTypes[0].filterLabel, isoCode: this.filterTypes[0].filterValue},
         { name: this.filterTypes[1].filterLabel, isoCode: this.filterTypes[1].filterValue}];
        return this.regionals && this.regionals.countries && regionalFilter.concat(this.regionals.countries);
      },
      timeZones() {
        return this.regionals && this.regionals.timezones;
      },
      supportedStates() {
        const regionalFilter = [ { name: this.filterTypes[0].filterLabel, isoCode: this.filterTypes[0].filterValue},
         { name: this.filterTypes[1].filterLabel, isoCode: this.filterTypes[1].filterValue}];
        const states = [];
        if (this.regionals && this.regionals.countries && this.regionals.states) {
          let ids = [];
          for (let i = 0; i < this.regionals.countries.length; i++) {
            if (this.countryCodesWithStatesRequired.indexOf(this.regionals.countries[i].isoCode) >= 0) {
              ids.push(this.regionals.countries[i].id);
            }
          }
          for (let i = 0; i < this.regionals.states.length; i++) {
            if (this.regionals.states[i].country && ids.indexOf(this.regionals.states[i].country.id) >= 0) {
              states.push(this.regionals.states[i]);
            }
          }
          
        }
        return regionalFilter.concat(states);
      }
    },
    methods: {
      isStandalone () {
        return this.loggedUser && this.loggedUser.user && this.loggedUser.user.isStandalone === true;
      },
      getColor(item) {
        let color = 'primary';
        const validationError = this.migrationSiteErrors[item.uuid] && 
                                Object.keys(this.migrationSiteErrors[item.uuid]).find(key => !!this.migrationSiteErrors[item.uuid][key]);
        if (validationError) {
          color = 'error';
        }
        return color;
      },
      toggleEdit(item) {
        if (item.isSelected && item.uuid !== this.editRow){
          this.editRow = item.uuid;
        } else {
          this.editRow = "";
        }
      },
      isInEditMode(item) {
        return item.isSelected && item.uuid === this.editRow
      },
      async validateNow(elem) {
        await this.$nextTick();
        this.$refs[elem] && this.$refs[elem].validate();
      },
      async editCell(site, uuid, field) {
        store.commit('setEditedSite', site);
        const uuids = [uuid];
        this.resetItemConsistencyError(uuids, field);
        this.validateRow(true, site);
      },
      async resetItemConsistencyError(uuids, field) {
        if (this.stepperConsistencyReport) {
          const somethingChanged = await store.dispatch('resetItemStepperConsistencyError', {uuids, field});
          if (somethingChanged) {
            this.$emit('form-changed');
          }
        }
      },
      pageReset() {
        //If a new filter is applied we reset page number, the selected items
        this.options.page = 1;
        this.migrationItems = [];
        this.fetchData(this.options, this.search);
      },
      detectQueryChange(options, search) {
         const { page, itemsPerPage } = options
         const { name, code, locationName, country, state, city, streetName, postalCode } = search
         const noChange = page && itemsPerPage && this.migrationSites && this.migrationSites.paging && this.migrationSites.search
                          && this.migrationSites.paging.page === page && this.migrationSites.paging.itemsPerPage === itemsPerPage
                          && this.migrationSites.search.name === name && this.migrationSites.search.code === code
                          && this.migrationSites.search.locationName === locationName  && this.migrationSites.search.country === country
                          && this.migrationSites.search.state === state && this.migrationSites.search.city === city 
                          && this.migrationSites.search.streetName === streetName && this.migrationSites.search.postalCode === postalCode;
         return !noChange
      },
      getQuery(options, search) {
         const { page, itemsPerPage } = options
         const pagingParameters = { page, itemsPerPage }
         return Utils.encodeBase64Url(JSON.stringify({ options: pagingParameters, search }));
      },
      fetchData: async function(options, search, initialize) {
        if (this.loading) { return; }
        try {
          this.loading = true;
          const bg = this.selectedMigrationBusinessGroups && this.selectedMigrationBusinessGroups.length && this.selectedMigrationBusinessGroups[0];
          if (initialize || (this.detectQueryChange(options,search) && bg)) {
            this.search.businessGroup = bg && bg.uuid;
            await store.dispatch('getMigrationSites', {customerId: this.customer.uuid, query: this.getQuery(options, search)});
          }
        } finally {
          this.loading = false;
        }
      },
      fetchRegionals: async function() {
        await store.dispatch('getRegionals');
      },
      isSiteEditable(site) {
        return !site.resolvedCode && !site.link;
      },
      isDigitsOnly: field => /^\d+$/.test(field) || 'stepper.isDigitsOnly',
      isPostalCode: field => /^[A-Za-z0-9\-\s]{2,10}$/.test(field) || 'stepper.isPostalCode',
      isCityName: field => /^[\p{L}\s.'-]+$/u.test(field) || 'stepper.isCityName',
      isStreetName: field => /^[\p{L}\s.'0-9-]+$/u.test(field) || 'stepper.isStreetName',
      isRequired: field => (!!field && (typeof field === 'string' || field instanceof String) && field.trim() != "") || (!!field && field.value && field.value.trim() != "") || 'stepper.isRequired',
      isXSSsanitized: field => /^((?!<.*?>).)*$/.test(field) || 'stepper.isXSSsanitized',
      getFieldErrorText(item, uuid, field) {
        return (this.migrationSiteErrors[item.uuid] && this.migrationSiteErrors[item.uuid][field]) || this.getStepperConsistencyErrorText(uuid, field);
      },
      getConsistencyErrorText(uuid, fieldName) {
        let func = this.getStepperConsistencyErrorText;
        return function() { 
          const errorTxt = func(uuid, fieldName);
          if (errorTxt) {
            return errorTxt;
          } else {
            return true;
          }
        };
      },
      getValidatorRules(item, uuid, field) {
        const validators = this.validators;
        let validatorRules;
        switch(field) {
          case 'SITE_CODE':
            validatorRules = !(this.multiSiteEnabled && this.siteCodesEnabled) ? [] : validators[field];
            break;
          case 'STATE':
            validatorRules = !this.needToShowStateSelection(item.location) ? [] :  validators[field];
            break;
          default:
            validatorRules = validators[field];
        }
        if (this.isStandalone() === true) {
          validatorRules = []
        }
        return validatorRules.concat([this.getConsistencyErrorText(uuid, field)]);
      },
      validateStep() {
        // We only have one site to validate
        this.validateRow(true, this.migrationItems[0]);
      },
      validateRow(value, item) {
        if (value) {
          const errorObj = {};
          Object.keys(this.validators).forEach(field => {
            const objectId = this.getObjectId(item, field);
            const errorMsg = this.getValidatorRules(item, objectId, field).map(validator => validator(this.getValueToValidate(item, field)))
                            .find(errorText => errorText !== true) || '';
            errorObj[field] = this.$t(errorMsg);
          })
          if(!this.migrationSiteErrors[item.uuid] || !Utils.isEqualErrorObj(this.migrationSiteErrors[item.uuid], errorObj)) {
            store.commit('setMigrationSiteErrors', { siteUuid: item.uuid, errorObj });
          }
        } else {
           store.commit('resetMigrationSiteErrors', item.uuid);
        }
      },
      getObjectId(item, field) {
        switch(field) {
          case 'SITE_NAME':
          case 'SITE_CODE':
            return item.uuid;
          case 'LOCATION_NAME':
          case 'COUNTRY':
          case 'STATE':
          case 'CITY':
          case 'STREET_NAME':
          case 'POSTAL_CODE':
          case 'TIMEZONE':
            return item.location.uuid;
          default:
            return '';
        }
      },
      getValueToValidate(item, field) {
        switch(field) {
          case 'SITE_NAME':
            return item.name;
          case 'SITE_CODE':
            return item.code || (!this.isSiteEditable(item) ? item.resolvedCode : '');
          case 'LOCATION_NAME':
            return item.location && item.location.name;
          case 'COUNTRY':
            return item.location && item.location.countryIso2Code;
          case 'STATE':
            return item.location && item.location.state;
          case 'CITY':
            return item.location && item.location.city;
          case 'STREET_NAME':
            return item.location && item.location.streetName;
          case 'POSTAL_CODE':
            return item.location && item.location.postalCode;
          case 'TIMEZONE':
            return item.location && item.location.timeZoneName;
          default:
            return '';
        }
      },
      getNameByCountryIsoCode(isoCode) {
        const countries = this.regionals && this.regionals.countries;
        for (let i = 0; i < countries.length; i++) {
          if (countries[i].isoCode === isoCode) {
            return countries[i].name;
          }
        }
        return '';
      },
      getStateByIsoCode(isoCode) {
        const states = this.regionals && this.regionals.states;
        for (let i = 0; i < states.length; i++) {
          if (states[i].isoCode === isoCode) {
            return states[i].name;
          }
        }
        return '';
      },
      needToShowStateSelection(location) {
        return location && location.countryIso2Code && this.countryCodesWithStatesRequired.indexOf(location.countryIso2Code) >= 0;
      },
      statesByCountry(location) {
        const states = [];
        if (this.regionals && this.regionals.countries && this.regionals.states) {
          let id = 0;
          for (let i = 0; i < this.regionals.countries.length; i++) {
            if (this.regionals.countries[i].isoCode === location.countryIso2Code) {
              id = this.regionals.countries[i].id;
              break;
            }
          }
          for (let i = 0; i < this.regionals.states.length; i++) {
            if (this.regionals.states[i].isoCode && this.regionals.states[i].country && this.regionals.states[i].country.id === id) {
              states.push(this.regionals.states[i]);
            }
          }
        }
        return states;
      },
      accountSites(name) {
        const sites = [];
        let needToAddOnPremSite = true;
        this.customer && this.customer.sites && this.customer.sites.records && this.customer.sites.records.forEach(record => {
          if (record.uoId) {
            const siteName = record.translatableName && this.$t(record.translatableName) || record.name;
            sites.push({name: siteName, value: record.name, translatableName: record.translatableName || false});
            record.name === name && (needToAddOnPremSite = false);
          }
        })
        needToAddOnPremSite === true && sites.push({name: name, value: name})
        return sites;
      },
      getValueForSiteName(site) {
        return site.translatableName && this.$t(site.translatableName) || site.name;
      }
    }
  }
</script>
<style>
  .configuration-sites-headers {
    width: var(--width) !important;
  }
</style>
