<template>
  <div v-if='isVisible' id='umt-navigation'>
    <actionMenu :customerId='customerId' @customers-changed='onCustomersChanged'></actionMenu>
  </div>
</template>

<script>
  import ActionMenu from "../components/actionMenu";

  export default {
    name: 'NavigationBar',
    props: ['account', 'customerId'],
    components: {
      ActionMenu
    },

    computed: {
      isVisible() {
        return !window.location.pathname.match(/logon/);
      },
    },
    methods: {
      onCustomersChanged(customers) {
        this.$emit('customers-changed', customers);
      }
  }
}
</script>

<style scoped>
  #umt-navigation {
    text-align: left;
  }
</style>
