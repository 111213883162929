import i18n from '../i18n'

const Base64={_keyStr:"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",encode:function(e){var t="";var n,r,i,s,o,u,a;var f=0;e=Base64._utf8_encode(e);while(f<e.length){n=e.charCodeAt(f++);r=e.charCodeAt(f++);i=e.charCodeAt(f++);s=n>>2;o=(n&3)<<4|r>>4;u=(r&15)<<2|i>>6;a=i&63;if(isNaN(r)){u=a=64}else if(isNaN(i)){a=64}t=t+this._keyStr.charAt(s)+this._keyStr.charAt(o)+this._keyStr.charAt(u)+this._keyStr.charAt(a)}return t},_utf8_encode:function(e){e=e.replace(/\r\n/g,"\n");var t="";for(var n=0;n<e.length;n++){var r=e.charCodeAt(n);if(r<128){t+=String.fromCharCode(r)}else if(r>127&&r<2048){t+=String.fromCharCode(r>>6|192);t+=String.fromCharCode(r&63|128)}else{t+=String.fromCharCode(r>>12|224);t+=String.fromCharCode(r>>6&63|128);t+=String.fromCharCode(r&63|128)}}return t}}

export default class Utils {

    static encodeBase64Url(data) {
        // Following RFC 4648, section 5 for URL safe Base64
        const encoded = Base64.encode(data);
        const encodedUrl = encoded.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
        return encodedUrl;
    }

    static filterTypes = [
      { filterLabel: i18n.t('common.emptyValues'), filterValue: 'empty'},
      { filterLabel: i18n.t('common.filledValues'), filterValue: 'filled'},
    ]

    static statusTypes = [
      { statusType: i18n.t('status.empty'), statusValue: 'Empty'},
      { statusType: i18n.t('status.pending'), statusValue: 'Pending'},
      { statusType: i18n.t('status.successful'), statusValue: 'Successful'},
      { statusType: i18n.t('status.failed'), statusValue: 'Failed' },
    ]

    static resultTypes = [
      { resultType: i18n.t('migrationReport.report.state.PROCESSING'), resultValue: 'PROCESSING' },
      { resultType: i18n.t('migrationReport.report.state.DONE'), resultValue: 'DONE'},
      { resultType: i18n.t('migrationReport.report.state.FAILED'), resultValue: 'FAILED'},
      { resultType: i18n.t('migrationReport.report.state.ROLLBACK'), resultValue: 'ROLLBACK'},
    ]

    static calculateHeaderWidth(columns) {
        const width = 100.0 / columns;
        return{
          '--width': width + '%'
        }
    }

    // Helper functions for the Stepper/Configuration page
    static isEqualErrorObj(oldObj, newObj) {
        let result = Object.keys(newObj).find(key => oldObj[key] !== newObj[key]);
        return !result;
    }

    static getPbxFriendlyName(name, isUnifyVideo) {
      switch (name) {
        case 'OSV':
        case 'OSV_TRUNK_TYPE':
          return 'Openscape Voice';
        case 'OS4K':
        case 'OS4K_TRUNK_TYPE':
          return 'Openscape 4000';
        case 'OSBiz':
        case 'OSBIZ_TRUNK_TYPE':
          return 'Openscape Business';
        case 'UO':
          if (isUnifyVideo) {
            return 'Unify Video';
          } else {
            return 'Unify Office';
          }
        case 'DLS': return 'DLS';
        case 'Circuit': return 'Circuit';
        case 'OSUC': return 'Openscape UC';
        case 'CSV': return 'CSV';
        case 'NGTC': return 'Unify Phone';
        case 'MiVoice400': return 'MiVoice Office 400';
        default: return name;
      }
    }
}
