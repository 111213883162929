<template>
  <v-container fluid>
    <uo-account-details/>
    <uo-account-property :srcIcon="require('../assets/icons/company.svg')" :description='companyName'/>
    <uo-account-property :srcIcon="require('../assets/icons/phonenumber.svg')" :description='phoneNumber'/>
    <!-- <uo-account-property :srcIcon="require('../assets/icons/sync.svg')" :description='lastAction'/>
    <uo-account-property :srcIcon="require('../assets/icons/sync.svg')" :description='lastActionDate'/> -->
  </v-container>
</template>

<script>
  import UoAccountDetails from '@/components/uoAccountDetails'
  import UoAccountProperty from '@/components/uoAccountProperty'

  export default {
    name: 'AppLogo',
    props: ['companyName', 'phoneNumber'],
    data() {
      return {
        lastAction: 'OpenScape 4K imported',
        lastActionDate: 'Jan 28, 2021'
      }
    },
    components: {
      UoAccountDetails,
      UoAccountProperty
    },
  }
</script>
