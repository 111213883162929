var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',[_c('v-row',[_c('v-data-table',{staticClass:"full-width data-table",attrs:{"item-key":"uuid","show-select":"","single-select":"","mobile-breakpoint":"0","options":_vm.options,"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.totalElements,"loading":_vm.loading,"no-data-text":_vm.$t('common.noDataAvailable'),"footer-props":{
          disablePagination: _vm.loading,
          itemsPerPageOptions: [5, 10, 20],
          showFirstLastPage: true,
          firstIcon: 'first_page',
          prevIcon: 'chevron_left',
          nextIcon: 'chevron_right',
          lastIcon: 'last_page',
          itemsPerPageText: _vm.$t('common.rowsPerPage')
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:`header.name`,fn:function({ header }){return [_c('v-text-field',{attrs:{"value":_vm.name,"label":_vm.$t(header.text),"append-icon":"search","clear-icon":"close","single-line":"","hide-details":"","flat":"","solo":"","clearable":"","placeholder":_vm.$t(header.text)},on:{"change":$event => _vm.name = $event,"click:clear":$event => _vm.name = ''}})]}},{key:`item.data-table-select`,fn:function({ isSelected, select }){return [_c('v-simple-checkbox',{directives:[{name:"ripple",rawName:"v-ripple"}],attrs:{"color":"primary","value":isSelected},on:{"input":function($event){return select($event)}}})]}}],null,true),model:{value:(_vm.migrationItems),callback:function ($$v) {_vm.migrationItems=$$v},expression:"migrationItems"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }