<template>
  <v-app>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
    <!-- <link rel="stylesheet" href="//fonts.googleapis.com/css?family=Roboto:400,500,700,400italic|Material+Icons"> -->
    <v-app-bar
      v-if='showMainMenu'
      app
      flat
      color="#F0F0F0"
      dark
    >
      <div class="d-flex align-center justify-center">
        <v-img
          alt="Mitel Logo"
          class="shrink mr-8"
          contain
          src="./assets/mitel-logo.png"
          width="100"
        />
        <div class="unify-logo-text">Unify Flip</div>
      </div>
      <log-management-dialog class="lvl2-elevation" v-model="isLogManagementVisible"
        @close-dialog="logManagementVisible = false">
      </log-management-dialog>
      <factory-reset-tool-dialog class="lvl2-elevation" v-model="isFactoryResetToolVisible"
        @close-dialog="factoryResetToolVisible = false">
      </factory-reset-tool-dialog>

      <ExportMigrationData class="lvl2-elevation"
            :customerId='customer.uuid'
            v-model="showExportMigrationDataDialog"
            @close-dialog="showExportMigrationData=false"
      >
      </ExportMigrationData>
      <upload-xls-dialog class="lvl2-elevation"
            :customerId="customer.uuid"
            v-model="showUploadXlsDialog"
            @close-dialog="showUploadXls=false"
            @import-pbx="showUploadXls=false"
      />
      <settings-dialogue class="lvl2-elevation"
            :customerId='customer.uuid'
            v-model="showSettingsDialogue"
            @close-dialog="showSettings=false"
      >
      </settings-dialogue>

      <v-spacer></v-spacer>

      <v-menu class="lvl2-elevation"
        open-on-hover
        bottom
        offset-y
        transition="scale-transition"
        origin="center top"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="profile-menu-text"
            text
            color="#313131"
            dark
            v-bind="attrs"
            v-on="on"
          >
            {{ displayName }}
            <v-icon right dark>arrow_drop_down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in menuItems"
            :key="index"
            @click="onMenuItemClick(item)"
          >
            <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>

  import SettingsDialogue from './components/settingsDialogue.vue';
  import LogManagementDialog from "./components/logManagementDialog";
  import FactoryResetToolDialog from "./components/factoryResetToolDialog";
  import ExportMigrationData from "./components/exportMigrationData";
  import UploadXlsDialog from "./components/uploadXlsDialog";
  import * as RestApiHandler from './common/restApiHandler';
  import router from './router';
  import store from './store';
  import { mapState } from 'vuex';


  export default {
    name: 'App',

    components: {
      FactoryResetToolDialog,
      LogManagementDialog,
      ExportMigrationData,
      UploadXlsDialog,
      SettingsDialogue
    },
    created() {
      document.title = this.appName;
    },
    computed: {
      ...mapState(['loggedUser', 'appName', 'customer', 'unauthorized']),
      showSettingsDialogue: {
        get () {
          return this.showSettings;
        }
      },
      showExportMigrationDataDialog: {
        get () {
          return this.showExportMigrationData;
        }
      },
      showUploadXlsDialog: {
        get () {
          return !this.unauthorized && this.showUploadXls;
        }
      },
      isLogManagementVisible() {
        return this.logManagementVisible;
      },
      isFactoryResetToolVisible() {
        return this.factoryResetToolVisible;
      },
      showMainMenu() {
        return this.loggedUser && this.loggedUser.account;
      },
      displayName() {
        return this.loggedUser && this.loggedUser.extension && this.loggedUser.extension.name;
      }
    },
    data: () => ({
      logManagementVisible: false,
      factoryResetToolVisible: false,
      showExportMigrationData: false,
      showSettings: false,
      showUploadXls: false,
      menuItems: [
        { id: 'New', title: 'app.new' },
        { id: 'Documentation', title: 'app.documentation' },
        { id: 'ReportIssue', title: 'app.reportIssue' },
        { id: 'FactoryResetTool', title: 'app.factoryResetTool' },
        { id: 'Migration', title: 'app.downloadMigrationData' },
        { id: 'Settings', title: 'app.settings' },
        { id: 'About', title: 'app.about' },
        { id: 'Logout', title: 'app.logout' },
      ],
    }),
    methods: {
      onMenuItemClick(item) {
        switch (item.id) {
          case 'New':
            this.$log.debug('[UOMTApp] Route to New');
            router.push('/new');
            break;
          case 'Documentation':
            this.$log.debug('[UOMTApp] Open Documentation');
            window.open('https://nuxeo.unify.com/nuxeo/site/proxy/nxdoc/view/raw/b7980502-cabe-4a0d-b607-5f31a69911a0', '_blank').focus();
            break;
          case 'ReportIssue':
            this.logManagementVisible = true
            break;
          case 'FactoryResetTool':
            this.factoryResetToolVisible = true;
            break;
          case 'XLS':
            store.commit('resetReports');
            this.showUploadXls = true;
            break;
          case 'Migration':
            this.showExportMigrationData = true;
            break;
          case 'Settings':
            this.showSettings = true;
            break;
          case 'About':
            this.$log.debug('[UOMTApp] Route to About');
            router.push('/about');
            break;
          case 'Logout':
            this.$log.debug('[UOMTApp] Route to Logon');
            RestApiHandler.logout();
            break;
          default:
            this.$log.debug('[UOMTApp] Route to Home');
            router.push('/');
            break;
        }
      }
    }
  }
</script>

<style scoped>
  .profile-menu-text {
    text-align: left;
    font: normal normal bold 14px/19px Arial;
    letter-spacing: 0px;
    color: #313131;
    opacity: 1;
  }
  .unify-logo-text {
    display: flex;
    justify-content: center;
    align-items: center;
    font: normal normal 600 25px/30px Arial;
    width: fit-content;
    white-space: nowrap;
    color:#15325f;
    opacity:1;
    letter-spacing: 0px;
  }
</style>
