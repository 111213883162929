<template>
  <v-dialog persistent no-click-animation scrollable v-model="show" max-width="51%">
    <v-card>
      <v-card-title class="text-bold-24">{{ $t('instructions.dls.title') }}</v-card-title>
      <v-card-text>
        <v-container>
          <div class='md-body-2'>{{ $t('instructions.dls.line1') }}</div>
          <div class='md-body-1 left-0-5'>{{ $t('instructions.dls.line2') }}</div>
          <div class='md-body-2'>{{ $t('instructions.dls.line3') }}</div>
          <div class='md-body-1 left-0-5'>{{ $t('instructions.dls.line4') }}</div>
          <img class="left-1-0 top-1-0" alt="DLS CMD prompt" width="95%" src="../assets/dls-cmd-prompt.jpg">
          <div class='md-caption left-1-0'>{{ $t('instructions.dls.line5') }}</div>
          <div class='md-body-2 top-1-0'>{{ $t('instructions.dls.line6') }}</div>
          <div class='md-body-1 left-0-5 top-0-5 bottom-0-5 bold-red'>{{ $t('instructions.dls.line7') }}</div>
          <div class='md-body-1 left-0-5'>{{ $t('instructions.dls.line8') }}</div>
          <div class='md-body-1 left-0-5'>{{ $t('instructions.dls.line9') }}</div>
          <img class="left-1-0 top-1-0" alt="DLS CMD prompt" width="95%" src="../assets/dls-backup-complete.jpg">
          <div class='md-caption left-1-0'>{{ $t('instructions.dls.line10') }}</div>
          <div class='md-body-2 top-1-0'>{{ $t('instructions.dls.line11') }}</div>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="closeDialog">{{ $t('common.close') }}</v-btn>   
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    name: 'DlsInstructionsDialog',
    props: {
      value: Boolean
    },
    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('dls-instructions', value)
        }
      },
    },
    methods: {
      closeDialog() {
        this.$emit('close-dialog');
      }
    }
  }
</script>

<style scoped>
  .bold-red {
    font-weight: bold;
    color: red;
  }
  .left-0-5 {
    margin-left: 0.5rem;
  }
  .top-0-5 {
    margin-top: 0.5rem;
  }
  .left-1-0 {
    margin-left: 1.0rem;
  }
  .top-1-0 {
    margin-top: 1.0rem;
  }
  .bottom-0-5 {
    margin-bottom: 0.5rem;
  }
</style>
