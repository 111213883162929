<template>
  <v-container fluid class="logo-container">
    <v-row no-gutter justify="center">
      <v-col sm="auto">
        <v-img :contain="true" :src="require('../assets/icons/logotype.svg')"/>
      </v-col>
      <v-col sm="auto">
        <div class="app-name-text"> {{ appNameObject.first }}</div>
        <div class="app-name-text-extrabold"> {{ appNameObject.second }}</div>
        <div class="app-name-text-extrabold"> {{ appNameObject.third }}</div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapState } from 'vuex';
  export default {
    name: 'AppLogo',
    computed: {
      ...mapState(['appNameSplitted']),
      appNameObject() {
        return this.appNameSplitted;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .logo-container {
    margin-top: 3rem;
    margin-bottom: 5rem;

    .app-name-text {
      font: normal normal normal 28px/35px Arial;
      letter-spacing: 0px;
    }
    
    .app-name-text-extrabold {
      font: normal normal 800 28px/35px Arial;
      letter-spacing: 0px;
    }

    .v-image {
      height: 105px;
    }
  }

  @media screen and (max-width: 1400px) {
    .logo-container {
      .app-name-text, .app-name-text-extrabold {
        font-size: 23px;
        line-height: 28px;
      }
      .v-image {
        height: 84px;
      }
    }
  }
</style>
