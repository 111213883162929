<template>
  <v-dialog persistent no-click-animation scrollable v-model="show" max-width="60%">
    <v-card>
      <v-card-title class="text-bold-24">{{ $t('instructions.csv.title') }}</v-card-title>
      <v-card-text>
        <v-container>
          <div class='md-body-1 left-0-5'>{{ $t('instructions.csv.line1') }}</div>
          <img class="left-1-0 top-1-0" alt="CSV button" width="400px" :src="require(`../assets/${$t('instructions.csv.file1')}`)">
          <div class='md-body-1 left-0-5 top-0-5'>{{ $t('instructions.csv.line2') }}</div>
          <div class='md-body-1 left-0-5 bold-red'>{{ $t('instructions.csv.line3') }}</div>
          <div class='md-body-1 left-0-5 bold-red'>{{ $t('instructions.csv.line4') }}</div>
          <div class='md-body-1 left-0-5 bold-red'>{{ $t('instructions.csv.line5') }}</div>
          <div class='md-body-1 left-0-5 top-0-5'>{{ $t('instructions.csv.line6') }}</div>
        </v-container>
      </v-card-text>
      <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog">{{ $t('common.close') }}</v-btn>  
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    name: 'CsvInstructionsDialog',
    props: {
      value: Boolean
    },
    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('csv-instructions', value)
        }
      },
    },
    methods: {
      closeDialog() {
        this.$emit('close-dialog');
      },
      getImagePath(img) {
        return this.$t(img);
      }
    }
  }
</script>

<style scoped>
  .bold-red {
    font-weight: bold;
    color: red;
  }
  .left-0-5 {
    margin-left: 0.5rem;
  }
  .top-0-5 {
    margin-top: 0.5rem;
  }
  .left-1-0 {
    margin-left: 1.0rem;
  }
  .top-1-0 {
    margin-top: 1.0rem;
  }
  .bottom-0-5 {
    margin-bottom: 0.5rem;
  }
</style>
