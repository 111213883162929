<template>
  <form enctype='multipart/form-data' id='fileupload' accept-charset="utf8">
    <div class='md-layout'>
      <div class='dropbox'>
        <md-progress-spinner v-if="isSaving && !hideProgress" class="dragdrop-center-container mt-4" :md-diameter=25 :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
        <div v-if="!isSaving">
          <input type="file" :name="uploadFieldName"
            :disabled="isSaving"
            @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
            class="input-file">
          <div v-if="isInitial || isSuccess" >
            <div class="dragdrop-center-container">
              <img class="dragdrop-img" src="../assets/icons/add.svg">
            </div>
            <div class="dragdrop-text">{{ $t('fileUpload.dragAndDrop') }}</div>
            <div class="dragdrop-text dragdrop-or-text">{{ $t('common.or') }}</div>
            <div class="dragdrop-text dragdrop-browse-files" >{{ $t('fileUpload.browseFiles') }}</div>
          </div>
          <div v-if="isSaving" class="dragdrop-text">{{ $t('fileUpload.uploadingFile', { fileCount:  fileCount} ) }}</div>
          <div v-if="isFailed" class="dragdrop-text error-text">{{ $t('fileUpload.failedToUploadPbxFile', { pbxType:  pbxType} ) }}</div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
  import * as RestApiHandler from '../common/restApiHandler';
  import { mapState } from 'vuex';
  import store from '../store';
  const UploadStatus = Object.freeze({
    INITIAL: 'INITIAL',
    SAVING: 'SAVING',
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED'
  });

  export default {
    name: 'FileUpload',
    props: ['pbxType', 'customerId', 'hideProgress'],
    data() {
      return {
        visible: false,
        uploadedFiles: [],
        uploadFieldName: 'pbxExportFile',
        uploadError: null,
        showProgress: false
      }
    },
    computed: {
      ...mapState(['currentFileUploadFormStatus']),
      isVisible() {
        return this.visible;
      },
      isInitial() {
        return this.currentFileUploadFormStatus === UploadStatus.INITIAL;
      },
      isSaving() {
        return this.currentFileUploadFormStatus === UploadStatus.SAVING;
      },
      isSuccess() {
        return this.currentFileUploadFormStatus === UploadStatus.SUCCESS;
      },
      isFailed() {
        return this.currentFileUploadFormStatus === UploadStatus.FAILED;
      }
    },
    methods: {
      showUpload() {
        this.visible = true;
      },
      reset() {
        // reset form to initial state
        store.commit('setFileUploadFormStatus', UploadStatus.INITIAL);
        this.uploadedFiles = [];
        this.uploadError = null;
      },
      async save(formData) {
        // upload data to the server
        store.commit('setFileUploadFormStatus', UploadStatus.SAVING);
        this.$emit('upload-started', this.pbxType, {
            status: this.currentFileUploadFormStatus
        });
        let response;
        try {
          const result = await RestApiHandler.upload(formData, this.pbxType, this.customerId);
          store.commit('setFileUploadFormStatus', (result.status === 200 ? UploadStatus.SUCCESS : UploadStatus.FAILED));
          if (result.status !== 200) {
            this.$log.error('[FileUpload] upload error: ', result);
            this.uploadError = result.message || result.statusText || 'File Upload Error';
          } else {
            response = result.json ? await result.json() : result;
            this.uploadError = response && response.message;
            if (response && response.consistencyReport) {
              store.commit('setFileUploadFormStatus', UploadStatus.FAILED);
              this.uploadError = 'Consistency errors in Excel file';
            }
          }
        } catch (err) {
          store.commit('setFileUploadFormStatus', UploadStatus.FAILED);
          this.uploadError = err.message || err.statusText || err;
          this.$log.error('[FileUpload] upload error: ', this.uploadError);
        } finally {
          if (response && response.customers) {
            store.commit('setCustomers', response.customers);
          }
          this.$emit('upload-finished', this.pbxType, {
            status: this.currentFileUploadFormStatus,
            error: this.uploadError
           }, response);
          this.$log.debug(`[FileUpload] upload finished for ${this.pbxType}, status: ${this.currentFileUploadFormStatus}`);
        }
      },
      filesChange(fieldName, fileList) {
        // handle file changes
        const formData = new FormData();

        if (!fileList.length) return;
        // append the files to FormData
        Array
          .from(Array(fileList.length).keys())
          .map(x => {
            formData.append(fieldName, fileList[x], fileList[x].name);
          });

        // save it
        this.save(formData);
      }
    }
  }
</script>

<style>
  .dragdrop-center-container {
    display: flex;
    flex: 1;
    justify-content: center;
    margin-top: 4px;
  }
  .dragdrop-img {
    width: 21px;
  }
  .dragdrop-text {
    text-align: center;
    font: normal normal normal 16px/19px Arial;
    letter-spacing: 0px;
    color: #909090;
    opacity: 1;
  }
  .dragdrop-or-text {
    font: normal normal normal 12px/14px Arial;
  }
  .dragdrop-browse-files {
    text-decoration: underline;
  }
  .dropbox {
    outline: 1px dashed grey; /* the dash box */
    outline-offset: -10px;
    background:  #FFFFFF 0% 0% no-repeat padding-box;;
    color: #909090;
    padding: 10px 10px;
    min-height: 100px; /* minimum height */
    max-height: 100px; /* minimum height */
    min-width: 450px;
    max-width: 450px;
    width: 100%;
    position: relative;
    cursor: pointer;
  }
  .top-15-left-100 {
    margin-top: 15px;
    margin-left: 100px;
  }
  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    position: absolute;
    cursor: pointer;
  }
  .dropbox:hover {
    background: #E2F1FC; /* when mouse over to the drop zone, change color */
  }
  .error-text {
    color: red !important;
  }
  .dropbox p {
    font-size: 1em;
    text-align: center;
    padding: 0px 5px 0px 5px;
  }
</style>
