'use strict';
import * as RestApiHandler from './restApiHandler';
import { logger } from 'vue-logger';

export default class FileDownloader {
    ///////////////////////////////////////////////////////////////////////////////
    // Internal methods
    ///////////////////////////////////////////////////////////////////////////////
    _getFilename(result, pbxType, customerId) {
        const contentDisposition = result.headers.get('Content-Disposition');
        const filenameMatch = contentDisposition && contentDisposition.match(/filename="(.*)"/);
        let filename = filenameMatch && filenameMatch.length > 1 && filenameMatch[1];
        filename = filename || `${pbxType}-${customerId}.json`;
        return filename;
    }

    _clickToDownload(blob, filename) {
        const fileLink = document.createElement('a');
        fileLink.href = window.URL.createObjectURL(blob);
        fileLink.setAttribute('download', filename);
        document.body.appendChild(fileLink);
        fileLink.click();
        setTimeout(() => { document.body.removeChild(fileLink); }, 200);
    }

    ///////////////////////////////////////////////////////////////////////////////
    // Public APIs
    ///////////////////////////////////////////////////////////////////////////////

    /**
     * Download the data from a Pbx into a file.
     * @param {string} pbxType - The type of PBX for which to download a file.
     * @param {string} customerId - A unique customer identifier.
     * @param {string} pbxId - An optional unique pbx identifier. If pbxId is not provided, all Pbxes from pbxType are downloaded.
     */
    async download(pbxType, customerId, pbxId) {
        logger.debug(`[FileDownload] download for ${pbxType}, customer ${customerId}`)
        try {
            const result = await RestApiHandler.download(pbxType, customerId, pbxId);
            if (result.status === 200) {
                const blob = await result.blob();
                this._clickToDownload(blob, this._getFilename(result, pbxType, customerId));
            } else {
                const error = {
                    status: result.status || 500,
                    statusText: result.message || result.statusText || 'Error while downloading'
                };
                logger.error(`[FileDownload] download for ${pbxType}, customer ${customerId} error:\n`, error);
                return error;
            }
            return result;
        } catch (error) {
            logger.error(`[FileDownload] download for ${pbxType}, customer ${customerId} error:`, error);
            return {
                status: error && error.status || 500,
                statusText: error && error.statusText || 'Error while downloading'
            }
        }
    }
}
