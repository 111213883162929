<template>
  <v-dialog persistent no-click-animation v-model="show" max-width="500px">
    <v-card>
      <v-card-title class="text-bold-24">{{ $t("logFileManagement.title") }}</v-card-title>
      <v-card-actions>
        <v-btn text color="primary" @click="download">{{ $t("logFileManagement.downloadFile") }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="deleteAll">{{ $t("logFileManagement.deleteAllFiles") }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="closeDialog">{{ $t("common.close") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>
<script>
  export default {
    name: 'LogManagementDialog',
    data: () => ({
      files: []
    }),
    props: {
      value: Boolean
    },
    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('log-management', value)
        }
      },
    },
    methods: {
      closeDialog() {
        this.$emit('close-dialog');
      },

      deleteAll() {
      this.$log.removeLogFiles(() => {
        this.$log.debug('[LogManagementDialog]: Deleted all log files');
      });
      },

      download() {
        this.$log.getLogFiles(async files => {
          try {
            /* eslint-disable no-await-in-loop */
            if (files.length > 0) {
              let content = '';
              for (const f of files) {
                const res = await this.fetchLocal(f.uri);
                if (!res.ok) {
                  throw new Error('Network response was not ok');
                }
                const blob = new Blob([await res.blob()], { type: 'application/text' });
                content += await blob.text();
              }
              const data = new Blob([content], { type: 'application/text' });
              const link = document.createElement('a');
              link.href = URL.createObjectURL(data);
              link.download = files[0].fileName;
              link.click();
              URL.revokeObjectURL(link.href);
            } else {
              this.$log.debug('[LogManagementDialog]: No log files available');
            }
          } catch (err) {
            this.$log.error('[LogManagementDialog]: Error downloading log files', err);
          }
        });
      },

      async fetchLocal(url) {
        return new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest;
          xhr.onload = () => {
            resolve(new Response(xhr.responseText, { status: xhr.status }));
          };
          xhr.onerror = () => {
            reject(new TypeError('Local request failed'));
          };
          xhr.open('GET', url);
          xhr.send(null);
        });
      }
    }
  }
</script>
