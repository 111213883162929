<template>
  <v-container fluid class="account-container">
    <v-row no-gutters justify="center" >
      <v-col align="center">
        <v-avatar color="light-blue">
          <img v-if="!userInitials" :src="userImage">
          <span v-if="userInitials" class="v-avatar-text">{{ userInitials }}</span>
        </v-avatar>
        <p class="mt-8 user-name-text">{{ displayName }}</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';    
export default {
  name: 'UoAccountDetails',
  computed: {
    ...mapState(['loggedUser']),
    displayName() {
      return this.loggedUser && this.loggedUser.extension && this.loggedUser.extension.name;
    },
    userImage() {
      if (this.loggedUser && this.loggedUser.extension && this.loggedUser.extension.profileImage && this.loggedUser.extension.profileImage.buffer) {
        var byteArray = new Uint8Array(this.loggedUser.extension.profileImage.buffer.data)
        return URL.createObjectURL(new Blob([byteArray], { type: this.loggedUser.extension.profileImage.contentType }));
      }
      return '';
    },
    userInitials() {
      if (this.loggedUser && this.loggedUser.extension && this.loggedUser.extension.profileImage && this.loggedUser.extension.profileImage.buffer) {
        return ''
      } else {
        const contact = this.loggedUser && this.loggedUser.extension && this.loggedUser.extension.contact;
        return contact && `${contact.firstName.substr(0,1)}${contact.lastName.substr(0,1)}`;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .account-container {
    margin-bottom: 3rem;
  }

  .v-avatar {
    height: 130px !important;
    width: 130px !important;

    .v-avatar-text {
      font-size: 2.8rem !important;
      font-weight: 400;
      line-height: 3.125rem;
      letter-spacing: normal !important;
      font-family: Arial !important;
    }
  }

  .user-name-text {
    font: normal normal 500 25px/40px Arial;
    text-align: center;
  }

  @media screen and (max-width: 1400px) {
    .v-avatar {
      height: 116px !important;
      width: 116px !important;
    
      .v-avatar-text {
        font-size: 2.25rem !important;
      }
    }

    .user-name-text {
      font-size: 23px;
    }
  }


</style>
