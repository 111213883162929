<template>
  <v-dialog persistent no-click-animation scrollable v-model="show" max-width="60%">
    <v-card>
      <v-card-title class="text-bold-24">{{ $t('instructions.ngtc.title') }}</v-card-title>
      <v-card-text>
        <v-container>
          <div class='md-body-1 left-0-5 top-0-5'>{{ $t('instructions.ngtc.line1') }}</div>
          <div class='md-body-1 left-0-5 top-0-5'>{{ $t('instructions.ngtc.line2') }}</div>
        </v-container>
      </v-card-text>
      <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog">{{ $t('common.close') }}</v-btn>  
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    name: 'NgtcInstructionsDialog',
    props: {
      value: Boolean
    },
    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('ngtc-instructions', value)
        }
      },
    },
    methods: {
      closeDialog() {
        this.$emit('close-dialog');
      },
      getImagePath(img) {
        return this.$t(img);
      }
    }
  }
</script>

<style scoped>
  .bold-red {
    font-weight: bold;
    color: red;
  }
  .left-0-5 {
    margin-left: 0.5rem;
  }
  .top-0-5 {
    margin-top: 0.5rem;
  }
  .left-1-0 {
    margin-left: 1.0rem;
  }
  .top-1-0 {
    margin-top: 1.0rem;
  }
  .bottom-0-5 {
    margin-bottom: 0.5rem;
  }
</style>
