<template>
  <v-dialog persistent no-click-animation scrollable v-model="show" max-width="75%">
    <v-card>
      <v-card-title class="text-bold-24">{{ $t('instructions.osv.title') }}</v-card-title>
      <v-card-text>
        <v-container>
          <div class='md-body-2'>{{ $t('instructions.osv.line1') }}</div>
          <div class='md-body-1 left-0-5 top-0-5'>{{ $t('instructions.osv.line2') }}</div>
          <img class="left-1-0 top-1-0" alt="OSV navigate to export" :src="require(`../assets/${$t('instructions.osv.file1')}`)">
          <div class='md-body-1 left-0-5 top-0-5'>{{ $t('instructions.osv.line3') }}</div>
          <img class="left-1-0 top-1-0" alt="OSV select export data" :src="require(`../assets/${$t('instructions.osv.file2')}`)">
          <div class='md-body-1 left-0-5 top-1-0'>{{ $t('instructions.osv.line4') }}</div>
        </v-container>
      </v-card-text>
      <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog">{{ $t('common.close') }}</v-btn>  
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    name: 'OsvInstructionsDialog',
    props: {
      value: Boolean
    },
    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('osv-instructions', value)
        }
      },
    },
    methods: {
      closeDialog() {
        this.$emit('close-dialog');
      }
    }
  }
</script>

<style scoped>
  .left-0-5 {
    margin-left: 0.5rem;
  }
  .top-0-5 {
    margin-top: 0.5rem;
  }
  .left-1-0 {
    margin-left: 1.0rem;
  }
  .top-1-0 {
    margin-top: 1.0rem;
  }
</style>
