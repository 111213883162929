<template>
  <v-row :style="cssVariables">
		<v-col>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalElements"
        :loading="loading"
        :no-data-text = "$t('common.noDataAvailable')"
        :footer-props="{
            disablePagination: loading,
            itemsPerPageOptions: [5, 10, 20],
            showFirstLastPage: true,
            firstIcon: 'first_page',
            prevIcon: 'chevron_left',
            nextIcon: 'chevron_right',
            lastIcon: 'last_page',
            itemsPerPageText: $t('common.rowsPerPage')
        }"
        class="data-table full-width">
          <template v-slot:[`header.name`]="{ header }">
            <v-text-field
              :value="name"
              @change="$event => name = $event"
              @click:clear="$event => name = ''"
              :label="$t(header.text)"
              append-icon="search"
              clear-icon="close"
              single-line hide-details
              flat solo clearable
              :placeholder="$t(header.text)"
            ></v-text-field>
           </template>
          <template v-slot:[`header.source`]="{ header }">
            <v-text-field
              :value="source"
              @change="$event => source = $event"
              @click:clear="$event => source = ''"
              :label="$t(header.text)"
              append-icon="search"
              clear-icon="close"
              single-line hide-details
              flat solo clearable
              :placeholder="$t(header.text)"
            ></v-text-field>
          </template>
          <template v-slot:[`header.target`]="{ header }">
            <v-combobox
              v-model="target"
              :items="!target ? filterTypes : undefined"
              item-text="filterLabel"
              item-value="filterValue"
              :label="$t(header.text)"
              hide-no-data hide-details hide-selected
              append-icon="search"
              clear-icon="close"
              solo single-line flat clearable
              @change="$event => target = $event"
              @click:clear="$event => target = ''"
              :placeholder="$t(header.text)"
            ></v-combobox>
          </template>
          <template v-slot:[`header.removal`]="{ header }">
            <v-combobox
              v-model="removal"
              :items="!removal ? filterTypes : undefined"
              item-text="filterLabel"
              item-value="filterValue"
              :label="$t(header.text)"
              hide-no-data hide-details hide-selected
              append-icon="search"
              clear-icon="close"
              solo single-line flat clearable
              @change="$event => removal = $event"
              @click:clear="$event => removal = ''"
              :placeholder="$t(header.text)"
            ></v-combobox>
          </template>
          <template v-slot:[`header.mainNumber`]="{ header }">
            <v-combobox
              v-model="mainNumber"
              :items="!mainNumber ? filterTypes : undefined"
              item-text="filterLabel"
              item-value="filterValue"
              :label="$t(header.text)"
              hide-no-data hide-details hide-selected
              append-icon="search"
              clear-icon="close"
              solo single-line flat clearable
              @change="$event => mainNumber = $event"
              @click:clear="$event => mainNumber = ''"
              :placeholder="$t(header.text)"
            ></v-combobox>
          </template>
      </v-data-table>
		</v-col>
  </v-row>
</template>

<script>
  import { mapState } from 'vuex';
  import store from '../store';
  import Utils from '../common/utils';

  export default {
    name: 'CustomerBgs',
    data() {
      return {
        loading: false,
         /**
         * The width of the headers has been calculated based on their label and potential content
         */
        headers:[
          { 
						text: 'common.name', value: 'name', align: 'start', sortable: false, width:"120",
						class: "data-table-header customer-bgs-headers", cellClass: "data-table-cell"
					},
          { 
						text: 'configureMigrate.migrateFrom', value: 'source', align: 'start', sortable: false, width:"120",
						class: "data-table-header customer-bgs-headers", cellClass: "data-table-cell"
					},
          { 
						text: 'configureMigrate.migrateTo', value: 'target', align: 'start', sortable: false, width:"120",
						class: "data-table-header customer-bgs-headers", cellClass: "data-table-cell"
					},
          { 
						text: 'configureMigrate.removeFrom', value: 'removal', align: 'start', sortable: false, width:"120",
						class: "data-table-header customer-bgs-headers", cellClass: "data-table-cell"
					},
          { 
						text: 'configureMigrate.mainNumber', value: 'mainNumber', align: 'start', sortable: false, width:"100",
						class: "data-table-header customer-bgs-headers", cellClass: "data-table-cell"
					}
        ],
        filterTypes: Utils.filterTypes,
      }
    },
    computed: {
      ...mapState(['customer', 'businessGroups']),
      cssVariables () {
        return Utils.calculateHeaderWidth(this.headers.length);
      },
      totalElements() {
        return this.businessGroups && this.businessGroups.paging && this.businessGroups.paging.totalElements || 0;
      },
      items() {
        const { page , itemsPerPage } = this.options;
        // This only happens when a new pbx is imported/deleted or at logon. Records.length will be at max as dictated
        // by the server and the page will be 1.
        if (this.businessGroups && this.businessGroups.records && this.businessGroups.records.length > itemsPerPage && page === 1) {
            store.commit('reduceRecords', { type: 'businessGroups', number: itemsPerPage })
        }
        return this.businessGroups && this.businessGroups.records && this.businessGroups.records.map(item => {
          const name = item.translatableName && this.$t(item.translatableName) || item.name;
          return {
            name: name,
            code: item.code,
            source: item.sourcesText,
            target: item.targetText,
            removal: item.removalsText,
            mainNumber: item.mainNumberText
          }
        });
      },
      name: {
        get: function () {
          return this.businessGroups && this.businessGroups.search && this.businessGroups.search.name;
        },
        set: function (value) {
          this.search.name = value;
          this.pageReset();
        }
      },
      source: {
        get: function () {
          return this.businessGroups && this.businessGroups.search && this.businessGroups.search.source;
        },
        set: function (value) {
          this.search.source = value;
          this.pageReset();
        }
      },
      target: {
        get: function () {
          return this.businessGroups && this.businessGroups.search && this.businessGroups.search.target;
        },
        set: function (value) {
          this.search.target = value;
          this.pageReset();
        }
      },
      removal: {
        get: function () {
          return this.businessGroups && this.businessGroups.search && this.businessGroups.search.removal;
        },
        set: function (value) {
          this.search.removal = value;
          this.pageReset();
        }
      },
      mainNumber: {
        get: function () {
          return this.businessGroups && this.businessGroups.search && this.businessGroups.search.mainNumber;
        },
        set: function (value) {
          this.search.mainNumber = value;
          this.pageReset();
        }
      },
      search() {
        return {
          name: this.name,
          source: this.source,
          target: this.target,
          removal: this.removal,
          mainNumber: this.mainNumber
        }
      },
      options: {
        get: function () {
          return this.businessGroups.paging;
        },
        set: function (value) {
          this.fetchData(value, this.search);
        }
      }
    },
    methods: {
      pageReset() {
        this.options.page = 1;
        this.fetchData(this.options, this.search);
      },
      detectQueryChange(options, search) {
         const { page, itemsPerPage } = options
         const { name, source, target, removal, mainNumber } = search
         const noChange = page && itemsPerPage && this.businessGroups && this.businessGroups.paging
                          && this.businessGroups.paging.page === page && this.businessGroups.paging.itemsPerPage === itemsPerPage
                          && this.businessGroups && this.businessGroups.search
                          && this.businessGroups.search.name === name && this.businessGroups.search.source === source
                          && this.businessGroups.search.target === target && this.businessGroups.search.removal === removal
                          && this.businessGroups.search.mainNumber === mainNumber;
         return !noChange
      },
      getQuery(options, search) {
         const { page, itemsPerPage } = options
         const pagingParameters = { page, itemsPerPage }
         return Utils.encodeBase64Url(JSON.stringify({ options: pagingParameters, search }));
      },
      fetchData: async function(options, search) {
        if (this.loading) { return; }
        try {
          this.loading = true;
          if (this.detectQueryChange(options,search)) {
            await store.dispatch('getBusinessGroups', {customerId: this.customer.uuid, query: this.getQuery(options, search) });
          }
        } finally {
           this.loading = false;
        }
      }
    }
  }
</script>
<style>
  .customer-bgs-headers {
      width: var(--width) !important;
  }
</style>
