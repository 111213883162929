<template>
  <v-container fluid class="migration-container">
    <v-row no-gutter class="migration-header" justify="space-between">
      <v-col sm="auto" class="text-bold-24 pa-0">{{ $t("configureMigrate.configureMigrate") }}</v-col>
    </v-row>
    <v-row>
      <v-tabs background-color="transparent" class="tabs-wrapper" slider-size="4">
          <v-tab>{{ $t("configureMigrate.businessGroups") }}</v-tab>
          <v-tab>{{ $t("configureMigrate.sites") }}</v-tab>
          <v-tab>{{ $t("configureMigrate.users") }}</v-tab>
          <v-tab v-if="isUnifyVideoAccount() || isStandalone()">{{ $t("configureMigrate.trunks") }}</v-tab>
          <div v-click-outside="resetMessage" class='md-layout-item md-subheading status-message'>
             <span id="statusMessage"> {{ this.saveConfigurationStatus }} </span>
         </div>
          <v-btn class="button-text-16 no-text-transform ml-auto"
            v-if='!isStandalone()'
            color="primary" width="150"
            rounded outlined
            :disabled="!enableConfigReset()"
            @click="onReset()"
          >
            <v-icon left small>replay</v-icon>
            {{ $t("configureMigrate.reset") }}
          </v-btn>
          <v-btn class="button-text-16 no-text-transform left-margin"
            color="primary" width="150"
            rounded outlined
            :disabled="!isPbxAvailable()"
            @click="openStepper()"
          >
            <v-icon left small>edit</v-icon>
            {{ $t("configureMigrate.configure") }}
          </v-btn>
          <ResetConfigurationDialog class="lvl2-elevation"
            :customerId='customer.uuid'
            v-model="showResetDialog"
            @close-dialog="closeResetDialog"
            @confirm-dialog="resetConfig"/>
          <v-tab-item class="pt-6">
            <customer-bgs/>
          </v-tab-item>
          <v-tab-item class="pt-6">
            <customer-sites/>
          </v-tab-item>
          <v-tab-item class="pt-6">
            <customer-users/>
          </v-tab-item>
          <v-tab-item class="pt-6">
            <customer-trunks/>
          </v-tab-item>
      </v-tabs>
    </v-row>
  </v-container>
</template>

<script>
  import CustomerBgs from '@/components/customerBgs';
  import CustomerSites from '@/components/customerSites';
  import CustomerUsers from '@/components/customerUsers';
  import CustomerTrunks from '@/components/customerTrunks';
  import ResetConfigurationDialog from "../components/resetConfigDialog";
  import * as RestApiHandler from '../common/restApiHandler';
  import { mapState } from 'vuex';
  import router from '../router';
  import store from '../store';
  import { logger } from 'vue-logger';

  export default {
    name: 'CustomerMigration',
    components: {
      CustomerBgs,
      CustomerSites,
      CustomerUsers,
      CustomerTrunks,
      ResetConfigurationDialog
    },
    data() {
      return {
        showResetDialog: false
      }
    },
    computed:{
      ...mapState(['customer', 'allowStepper', 'saveConfigurationStatus', 'migrationEnabled', 'loggedUser'])
    },
    methods: {
      isUnifyVideoAccount () {
        return this.loggedUser && this.loggedUser.user && this.loggedUser.user.isUnifyVideo === true;
      },
      isStandalone () {
        return this.loggedUser && this.loggedUser.user && this.loggedUser.user.isStandalone === true;
      },
      openStepper(){
        store.commit('resetReports');
        router.push('/stepper');
      },
      resetMessage() {
        store.commit('setSaveConfigurationStatus', '');
      },
      isPbxAvailable() {
        return this.allowStepper;
      },
      enableConfigReset() { 
        return this.migrationEnabled;
      },
      onReset() {
          this.showResetDialog = true;
      },
      closeResetDialog() {
          this.showResetDialog = false;
      },
      async resetConfig() {
        logger.debug(`[ResetConfig] reset configuration for customer ${this.customer.uuid}`)
        store.commit('resetReports');
        try {
          store.commit('setSaveConfigurationStatus', this.$t('configureMigrate.resetInProgress'));
          const result = await RestApiHandler.resetConfig(this.customer.uuid);
          if (result.status === 200) {
            const response = await result.json();
            if (response && response.customers) {
              store.commit('setCustomers', response.customers);
            }
            store.commit('setMigrationEnabled', false);
            store.commit('setSaveConfigurationStatus', this.$t('configureMigrate.resetFinished'));
          } else if (result.status === 401) {
            store.commit('setSaveConfigurationStatus', this.$t('configureMigrate.resetFailed'));
            this.setUnauthorizedDialog(this.$t('configureMigrate.resetConfigurationError'));
            RestApiHandler.clearRefreshToken();
          } else {
            this.statusMessage = {text: result.statusText, error: true};
          }
        } catch(err) {
          logger.error(`[ActionMenu] Reset Configuration for customer ${this.customer.uuid} error:`, err);
          this.statusMessage = {text: err, error: true};
        }
        this.showResetDialog = false;
      }
    }
  }
</script>

<style scoped lang="scss">
 .migration-container {
    margin-top: 5rem;
  }
  .migration-header {
    margin-bottom: 1.5rem;
  }
  .tabs-wrapper {
    .v-tab {
      font: normal normal normal 18px/21px Arial;
      letter-spacing: 0px;
      color: #263038;
      padding: 0;
      margin-right: 3rem;
    }
  }

  .left-margin {
    margin-left: 1.0rem;
  }
  .md-subheading {
    text-align: right;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    margin-top: 0.4rem;
  }
  .status-message {
    color: #257CAE;
  }
</style>
