export default {
    de: [
        {
            version: 'V1 R4.12.0',
            picture: '1.4.12.0.png',
            records:[
                {
                    title: 'Unify Flip jetzt mit Mitel Logo',
                    description: 'Das Flip Tool wurde an die Mitel Farben und as Mitel Logo angepasst'
                },
                {
                    title: 'Fehlerkorrekturen und Verbesserungen',
                    description: 'Auch für diese Version des Flip Tools wurden Fehler korrigiert und Verbesserungen vorgenommen.'
                }
            ]
        },
        {
            version: 'V1 R4.9.0',
            picture: '1.4.9.0.png',
            records:[
                {
                    title: 'Fehlerkorrekturen und Verbesserungen',
                    description: 'In dieser Version des Flip Tools wurden Fehler korrigiert und Verbesserungen vorgenommen.'
                }
            ]
        },
        {
            version: 'V1 R4.8.0',
            picture: '1.4.8.0.png',
            records:[
                {
                    title: 'Support für Unify Phone Standalone',
                    description: 'Sie können sich direkt mit Ihrem Unify Phone Admin Account einloggen, um Benutzerkonten für die Migration zu Unify Phone vorzubereiten.'
                },
                {
                    title: 'Fehlerkorrekturen und Verbesserungen',
                    description: 'Auch für diese Version des Flip Tools wurden Fehler korrigiert und Verbesserungen vorgenommen.'
                }
            ]
        },
        {
            version: 'V1 R4.6.0',
            picture: '1.4.6.0.png',
            records:[
                {
                    title: 'Erweiterungen für den CSV Import',
                    description: 'Es können nun zusätzliche Parameter für die SIP Authenifizierung über CSV importiert werden (nur relevant für Unify Phone).'
                },
                {
                    title: 'Fehlerkorrekturen und Verbesserungen',
                    description: 'Auch in dieser Version haben wir einige Verbesserungen eingebaut und Fehler behoben.'
                }
            ]
        },
        {
            version: 'V1 R4.5.0',
            picture: '1.4.5.0.png',
            records:[
                {
                    title: 'Verbesserungen für Unify Phone',
                    description: 'Weitere Verbesserungen und Fehlerkorrekturen in Zusammenarbeit mit Ihrer Unify Phone Lösung.'
                }
            ]
        },
        {
            version: 'V1 R4.4.0',
            picture: '1.4.4.0.png',
            records:[
                {
                    title: 'Unterstützung für Unify Phone',
                    description: 'Das Flip Tool unterstützt Sie in dem Prozess der Benutzererstellung Ihrer Unify Phone Lösung.'
                },
                {
                    title: 'Fehlerkorrekturen und Verbesserungen',
                    description: 'Auch in dieser Version haben wir einige Verbesserungen eingebaut und Fehler behoben.'
                }
            ]
        },
        {
            version: 'V1 R4.3.0',
            picture: '1.4.3.0.png',
            records:[
                {
                    title: 'Neues Layout für den Migrationsreport',
                    description: 'Der Migrationsreport ist in neuem Layout zu sehen. Er ist übersetzt und die Ergebnisse können gefiltert, durchsucht und exportiert werden.'
                },
                {
                    title: 'Vereinfachte Standort-Auswahl für die Migration',
                    description: 'Ein vorhandener Standort in Ihrem Unify Office Konto, kann nun während der Vorbereitung der Migration direkt gewählt werden.'
                },
                {
                    title: 'Fehlerkorrekturen und Verbesserungen',
                    description: 'Auch in dieser Version haben wir einige Verbesserungen eingebaut und Fehler behoben.'
                }
            ]
        },
        {
            version: 'V1 R4.2.0',
            picture: '1.4.2.0.png',
            records:[
                {
                    title: 'Anpassungen für Unify Video',
                    description: 'Die Ansicht in der Oberfläche wurde für Unify Video Konten überarbeitet. Nicht benötigte Informationen werden nun ausgeblendet.'
                },
                {
                    title: 'Rollenzuweisung',
                    description: 'Die in Ihrem Unify Office oder Unify Video Konto angelegten Rollenprofile können nun allen neuen Benutzern, während der Migration, individuell zugewiesen werden.'
                },
                {
                    title: 'Erweiterungen für CSV Import',
                    description: 'Über den CSV Import können jetzt zusätzliche Daten importiert werden. Dazu zählen: Standorte, Anrufwarteschlangen und Rollen. Außerdem sind Rufnummern bei Unify Video accounts nicht mehr zwingend erforderlich.'
                }
            ]
        },
        {
            version: 'V1 R4.1.0',
            picture: '1.4.1.0.png',
            records:[
                {
                    title: 'Fehlerkorrekturen',
                    description: 'Wir haben Fehler im Zusammenhang mit dem Import von OpenScape Voice und CSV Daten behoben.'
                }
            ]
        },
        {
            version: 'V1 R4.0.1',
            picture: '1.4.0.1.png',
            records:[
                {
                    title: 'Zusätzliche Plattform',
                    description: 'Unify X powered by Deutsche Telekom wurde als zusätzliche unterstützte Plattform hinzugefügt.'
                }
            ]
        },
        {
            version: 'V1 R4.0.0',
            picture: '1.4.0.0.png',
            records:[
                {
                    title: 'Die erste Version',
                    description: 'Die erste offiziell freigegebene Version.'
                }
            ]
        }
    ],
    en: [
        {
            version: 'V1 R4.12.0',
            picture: '1.4.12.0.png',
            records:[
                {
                    title: 'Unify Flip now with the Mitel logo',
                    description: 'The Flip Tool now uses the Mitel colors and the Mitel logo.'
                },
                {
                    title: 'Bug fixes and improvements',
                    description: 'In this release, we have implemented various improvements and fixed some bugs.'
                }
            ]
        },
        {
            version: 'V1 R4.9.0',
            picture: '1.4.9.0.png',
            records:[
                {
                    title: 'Bug fixes and improvements',
                    description: 'In this release, we have implemented various improvements and fixed some bugs.'
                }
            ]
        },
        {
            version: 'V1 R4.8.0',
            picture: '1.4.8.0.png',
            records:[
                {
                    title: 'Unify Phone standalone support',
                    description: 'You can login using your Unify Phone admin account to prepare the user accounts for migration to Unify Phone.'
                },
                {
                    title: 'Bug fixes and improvements',
                    description: 'Also for this release, we have implemented various improvements and fixed some bugs.'
                }
            ]
        },
        {
            version: 'V1 R4.6.0',
            picture: '1.4.6.0.png',
            records:[
                {
                    title: 'Improvements for CSV Import',
                    description: 'You can import additional parameters relevant for SIP digest authentication via the CSV file (only relevant for Unify Phone).'
                },
                {
                    title: 'Bug fixes and improvements',
                    description: 'Also for this release, we have implemented various improvements and fixed some bugs.'
                }
            ]
        },
        {
            version: 'V1 R4.5.0',
            picture: '1.4.5.0.png',
            records:[
                {
                    title: 'Improvements for Unify Phone',
                    description: 'Further improvements and bug fixes for use with Unify Phone.'
                }
            ]
        },
        {
            version: 'V1 R4.4.0',
            picture: '1.4.4.0.png',
            records:[
                {
                    title: 'Support for Unify Phone',
                    description: 'The Flip Tool will support in the process of user creation for your Unify Phone solution.'
                },
                {
                    title: 'Bug fixes and improvements',
                    description: 'Also for this release, we have implemented various improvements and fixed some bugs.'
                }
            ]
        },
        {
            version: 'V1 R4.3.0',
            picture: '1.4.3.0.png',
            records:[
                {
                    title: 'A new layout for the migration report',
                    description: 'The migration report has a new layout. It has been fully translated and you can filter, search or export the results.'
                },
                {
                    title: 'Simplified site selection for migration',
                    description: 'An existing site on your Unify Office account can now be selected easily during the preparation of a migration.'
                },
                {
                    title: 'Bug fixes and improvements',
                    description: 'Also for this release, we have implemented various improvements and fixed some bugs.'
                }
            ]
        },
        {
            version: 'V1 R4.2.0',
            picture: '1.4.2.0.png',
            records:[
                {
                    title: 'Adaptations for Unify Video',
                    description: 'The interface view has been revised for Unify Video accounts. Information that is not required is now hidden.'
                },
                {
                    title: 'Role assignment',
                    description: 'The roles created in your Unify Office or Unify Video account can now be individually assigned to all new users during the migration process.'
                },
                {
                    title: 'Enhancements for the CSV import',
                    description: 'Additional data can now be imported via the CSV import. These include: locations, call queues and roles. In addition, phone numbers are no longer mandatory for Unify Video accounts.'
                }
            ]
        },
        {
            version: 'V1 R4.1.0',
            picture: '1.4.1.0.png',
            records:[
                {
                    title: 'Bug fixes',
                    description: 'We have fixed bugs related to the import of OpenScape Voice and CSV data.'
                }
            ]
        },
        {
            version: 'V1 R4.0.1',
            picture: '1.4.0.1.png',
            records:[
                {
                    title: 'Additional platform',
                    description: 'Added Unify X powered by Deutsche Telekom as an additional supported platform.'
                }
            ]
        },
        {
            version: 'V1 R4.0.0',
            picture: '1.4.0.0.png',
            records:[
                {
                    title: 'The first release',
                    description: 'This is the first official release.'
                }
            ]
        }
    ]
};
