import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import { plugin as loggerPlugin, logger } from 'vue-logger'
import * as RestApiHandler from './common/restApiHandler';
import './assets/scss/app.scss';

Vue.config.productionTip = false

import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'

import VueSocketIO from 'vue-socket.io';
import io from 'socket.io-client';
import i18n from './i18n'

const _apiLocation = 'https://backend.flip.unifyoffice.com';

Vue.use(VueMaterial);

Vue.use(loggerPlugin);

const socket = io(_apiLocation, { autoConnect: false });
socket.on('connect', () => {
  logger.error('[Main] websocket connected. authenticating.');
  // Authenticate web socket in the server on connect events.
  const sessionToken = RestApiHandler.getSessionToken();
  if (sessionToken) {
    socket.emit('authenticate', `Bearer ${sessionToken}`);
  }
});

Vue.use(new VueSocketIO({
    debug: false,
    connection: socket,
    vuex: {
      store,
      actionPrefix: "socket_"
    }
  })
);

logger.startFileLogger(process.env.VUE_APP_VERSION, {
  LOG_PREFIX: 'umtwc-'
})
logger.debug('[Main]: Start UOMT application')

store.commit('setAppName', 'User Migration Tool');

let umtApp;
RestApiHandler.logon()
.then(response => {
  let loggedIn = response && response.account;
  if (loggedIn) {
    store.commit('setLoggedOnUser', response);
    store.commit('setCustomers', response.user.customers);
    if (response.user && response.user.customers.length === 1) {
      logger.debug(`[Main] Admin user manages only one customer, id: ${response.user.customers[0].uuid}, uoAccountId: ${response.user.customers[0].uoAccountId}`);
    }
  }

  umtApp = umtApp || new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
  }).$mount('#app');

  if (loggedIn) {
    umtApp.$socket.connect();
  } else {
    logger.error('[Main] logon response error: ', response);
    router.push('/logon');
  }
})
.catch(err => {
  logger.error('[Main] logon exception error: ', err);
  router.push('/logon');
});
