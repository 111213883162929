<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <div style="text-align: center">
          <v-img class="ml-md-auto mr-3 property-img" :src="srcIcon" width="26" height="26"/>
          <p class="user-property-text">{{ description }}</p>
        </div>
        
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'UoAccountProperty',
    props: ['srcIcon', 'description']
  }
</script>


<style lang="scss" scoped>
  @import "../assets/scss/_global.scss";

  .property-img {
    display: inline-block;
  }

  .user-property-text {
    font: normal normal normal 18px/21px Arial;
    text-align: left;
    letter-spacing: 0px;
    color: $white;
    opacity: 1;
    display: inline-block;
    vertical-align: middle;
  }


</style>
