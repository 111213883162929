var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"report-container",attrs:{"fluid":""}},[_c('v-row',{staticClass:"report-header",attrs:{"no-gutter":"","justify":"space-between"}},[_c('v-col',{staticClass:"text-bold-24 pa-0",attrs:{"sm":"auto"}},[_vm._v(_vm._s(_vm.$t("migrationReport.label")))]),_c('v-col',{staticClass:"pa-0",attrs:{"sm":"auto"}},[_c('v-btn',{staticClass:"button-text-16 no-text-transform",attrs:{"color":"primary","width":"150","dark":"","rounded":"","outlined":""},on:{"click":function($event){return _vm.downloadReport()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("download")]),_vm._v(" "+_vm._s(_vm.$t("migrationReport.download"))+" ")],1)],1)],1),_c('v-row',{style:(_vm.cssVariables),attrs:{"no-gutter":""}},[_c('v-data-table',{staticClass:"data-table full-width",attrs:{"headers":_vm.headers,"items":_vm.pagedReport,"options":_vm.options,"server-items-length":_vm.totalElements,"no-data-text":_vm.$t('common.noDataAvailable'),"footer-props":{
          disablePagination: false,
          itemsPerPageOptions: [5, 10, 20],
          showFirstLastPage: true,
          firstIcon: 'first_page',
          prevIcon: 'chevron_left',
          nextIcon: 'chevron_right',
          lastIcon: 'last_page',
          itemsPerPageText: _vm.$t('common.rowsPerPage')
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:`header.action`,fn:function({ header }){return [_c('v-text-field',{attrs:{"value":_vm.name,"label":_vm.$t(header.text),"append-icon":"search","clear-icon":"close","single-line":"","hide-details":"","flat":"","solo":"","clearable":"","placeholder":_vm.$t(header.text)},on:{"change":$event => _vm.name = $event,"click:clear":$event => _vm.name = ''}})]}},{key:`header.target`,fn:function({ header }){return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:`header.result`,fn:function({ header }){return [_c('v-select',{attrs:{"items":_vm.resultTypes,"item-text":"resultType","item-value":"resultValue","label":_vm.$t(header.text),"append-icon":"search","clear-icon":"close","single-line":"","hide-details":"","flat":"","solo":"","clearable":"","placeholder":_vm.$t(header.text),"menuProps":{ bottom: true, offsetY: true, maxHeight: 300 }},on:{"change":$event => _vm.result = $event,"click:clear":$event => _vm.result = ''}})]}},{key:`header.message`,fn:function({ header }){return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}}],null,true)})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }